import React, { useEffect, useRef, useState } from "react";
import {
  Outlet,
  Link,
  useOutletContext,
  useParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import imgLogo from "../../../images/props/img2.png";
import "./DashboardProducts.scss";
import Lottie from "react-lottie";
import loader from "./../../../lottie/logo2.json";
import { motion } from "framer-motion";
import {
  baseURL,
  IMAGE_PRODUCTS_URL,
  IMAGE_TEMPLETS_URL,
} from "../../api/baseURL/BaseURL";
import DashboardIndividualCatalogProducts from "./DashboardIndividualCatalogProducts";
import DashboardPdfNavbar from "./DashboardPdfNavbar";
import DashboardPreviewPdf from "./DashboardPreviewPdf";
import { RiDeleteBinLine } from "react-icons/ri";

const DashboardProducts = () => {
  const userID = useOutletContext();
  // let { pathId } = useParams();
  // console.log("userrrrrrr:::", userID);
  const [allProductsForSelectedData, setAllProductsForSelectedData] =
    useState(null);
  const [templateProductData, setTemplateProductData] = useState(null);
  const [active, setActive] = useState(0);
  const { id } = useOutletContext();
  const [catalogID, setCatalogID] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const { newCreatedCatalogID, setNewCreatedCatalogID, newCreatedCatalogName } =
    userID;
  const [catalogName, setCatalogName] = useState("");
  const productSidebarRef = useRef();
  const [templatePhpLink, setTemplatePhpLink] = useState(null);
  const [pdfPreviewModal, setPdfPreviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const scroll = (productData) => {
    // console.log("scrollllled", productData.length);
    setActive(productData.length - 1);
    const target = document.querySelector(".dashboardProducts-itemSidebar");
    target.scrollTo({
      top: 220 * productData.length,
      left: 0,
      behavior: "smooth",
    });

    setNewCreatedCatalogID(null);
    // window.scroll(0, productSidebarRef.current.offsetBottom);
  };

  const callProductForSelectedCatalogAPI = (id) => {
    // console.log("ccccccccccidddd", id);
    setIsLoading(true);
    fetch(baseURL + `get-all-products/${id}`, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const { success } = data;
        if (success) {
          setAllProductsForSelectedData(data.data.products);
        } else {
          setAllProductsForSelectedData(null);
        }
        // console.log("product", data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const callProductAPI = async (id) => {
      const res = await fetch(baseURL + `catalog/${id}`);
      const data = await res.json();
      if (data.success) {
        setTemplateProductData(data.data.catalog);
        // setTemplatePhpLink(data.data.catalog.template_link);
        if (newCreatedCatalogID !== null) {
          setCatalogID(newCreatedCatalogID);
          callProductForSelectedCatalogAPI(newCreatedCatalogID);
          scroll(data.data.catalog);
          setCatalogName(newCreatedCatalogName);
          setTemplatePhpLink(
            data.data.catalog[data.data.catalog.length - 1].template_link
          );
          return;
        } else if (trigger === 0) {
          setCatalogID(data.data.catalog[0].id);
          callProductForSelectedCatalogAPI(data.data.catalog[0].id);
          setCatalogName(data.data.catalog[0].name);
          setTemplatePhpLink(data.data.catalog[0].template_link);
        } else {
        }
      } else {
      }
      // console.log("idddddddd:::", data);
    };
    callProductAPI(id);
  }, [trigger]);

  // useEffect(() => {
  //   const callProductAPI = async (id) => {
  //     const res = await fetch(baseURL + `catalog/${id}`);
  //     const data = await res.json();
  //     if (data.success) {
  //       setTemplateProductData(data.data.catalog);
  //       setCatalogID(data.data.catalog[0].id);
  //       callProductForSelectedCatalogAPI(data.data.catalog[0].id);
  //     }
  //     console.log("iddddd:::", data);
  //   };
  //   callProductAPI(id);
  // }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // const { view } = useLottie(defaultOptions);
  return (
    <>
      <section className="dashboardProducts dashboardProducts-section flex">
        {!templateProductData ? (
          <>
            <div style={{ width: "100%" }} className="flex flex-aic flex-jss">
              <Lottie options={defaultOptions} height={100} width={100} />
              {/* {view} */}
              {/* <h1>Don't have any catalog create one.</h1> */}
            </div>
          </>
        ) : (
          <>
            <motion.div
              initial={
                {
                  // width: 10,
                }
              }
              animate={{
                width: 240,
                transition: {
                  duration: 0.5,
                },
              }}
              className="dashboardProducts-itemSidebar"
              ref={productSidebarRef}
            >
              {templateProductData === null ? (
                <></>
              ) : (
                templateProductData.map((item, index) => {
                  return (
                    <div
                      className={
                        active === index
                          ? `dashboardProducts-itemSidebar-item ${index} active`
                          : `dashboardProducts-itemSidebar-item ${index}`
                      }
                      onClick={() => {
                        setActive(index);
                        callProductForSelectedCatalogAPI(item.id);
                        setCatalogID(item.id);
                        setCatalogName(item.name);
                        setTemplatePhpLink(item.template_link);
                      }}
                    >
                      <div className="dashboardProducts-itemSidebar-item-imgbox">
                        {item.images[0] === undefined || null ? (
                          <img
                            src={imgLogo}
                            style={{
                              width: "50%",
                              height: "50%",
                              objectFit: "contain",
                              margin: "35px auto",
                              display: "block",
                            }}
                          ></img>
                        ) : (
                          <img src={IMAGE_PRODUCTS_URL + item.images[0]}></img>
                        )}
                      </div>
                      <div className="dashboardProducts-itemSidebar-item-title">
                        {item.name}
                      </div>
                      <div className="dashboardProducts-itemSidebar-item-delete">
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            fetch(baseURL + `delete-catalog/${item.id}`, {
                              method: "DELETE",
                              headers: new Headers({
                                accept: "application/json",
                                "Content-Type":
                                  "application/x-www-form-urlencoded",
                              }),
                            })
                              .then((res) => res.json())
                              .then((data) => {
                                const { success } = data;
                                if (success) {
                                  navigate(0);
                                }
                              });
                          }}
                        >
                          <RiDeleteBinLine color="red" size={20} />
                        </button>
                      </div>
                    </div>
                  );
                })
              )}
            </motion.div>
            <div style={{ flex: "1" }}>
              <DashboardPdfNavbar
                userID={userID}
                catalogID={catalogID}
                catalogName={catalogName}
                templatePhpLink={templatePhpLink}
                pdfPreviewModal={setPdfPreviewModal}
              />
              <div className="dashboardProducts-addProduct">
                {isLoading ? (
                  <Lottie options={defaultOptions} height={100} width={100} />
                ) : (
                  <DashboardIndividualCatalogProducts
                    productData={allProductsForSelectedData}
                    callPFSCAPI={callProductForSelectedCatalogAPI}
                    // callPAPI={callProductAPI}
                    // id={id}
                    catalogID={catalogID}
                    userID={userID}
                    trigger={setTrigger}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </section>
      {/*  */}
      {pdfPreviewModal && (
        <DashboardPreviewPdf
          pdfPreviewModal={setPdfPreviewModal}
          userID={userID}
          catalogID={catalogID}
          templatePhpLink={templatePhpLink}
        />
      )}
    </>
  );
};

export default DashboardProducts;
