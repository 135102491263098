import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LoginModal from "../../auth/login/LoginModal";
import { decryptData } from "../../adminpanel/Util";
const ProtectedRouteForDashboard = () => {
  let originalID;
  let id;
  let idofCM;
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const idfliu = localStorage.getItem("idfliu");
  const idfliuSession = sessionStorage.getItem("idfliu");

  if (!idfliu) {
    // serching in session
    if (!idfliuSession) window.location.pathname = "/";
    else {
      originalID = decryptData(idfliuSession, salt);
      originalID["storage"] = "session";
      id = originalID.id;
      idofCM = originalID.companyName;
    }
  } else {
    originalID = decryptData(idfliu, salt);
    originalID["storage"] = "local";
    id = originalID.id;
    idofCM = originalID.companyName;
  }
  // console.log("datatatatatatatta", originalID);
  if (!originalID && !id) {
    window.location.pathname = "/";
  }

  return id && idofCM ? (
    <Outlet context={originalID} />
  ) : (
    <Navigate to="/app/dashboard/start" replace />
  );
};

export default ProtectedRouteForDashboard;
