import React from "react";
import "./Feedback.scss";
import productHunt from "../../images/apps/producthunt.png";
import sourceforge from "../../images/apps/sourceforge.png";
import custImg from "../../images/apps/customer1.png";
import gDot from "../../images/props/gray-dots.svg";

const Feedback = () => {
  return (
    <section className="feedback feedback-section">
      <div className="container">
        <div className="feedback-header">
          <h1 className="flex flex-aic flex-jcc">
            See what our customers have to say
          </h1>
        </div>
        <div className="feedback-content flex">
          <div className="feedback-content-left flex flex-jcc">
            <div className="imgbox">
              <img
                src={custImg}
                alt="How do I make a PDF Catalogue for free?"
              ></img>
            </div>
            <div className="dotImgbox">
              <img
                src={gDot}
                alt="How do I make a PDF Catalogue for free?"
              ></img>
            </div>
          </div>
          <div className="feedback-content-right">
            <img
              src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/5fcfd42a1d06cd3a0a66a505_blog-quote.svg"
              alt="How do I make a PDF Catalogue for free?"
            ></img>
            <div className="feedback-content-right-quote">
              <h2>
                “Before 2020, we sent a few catalog blasts here and there, but
                it wasn’t a focus. Catalogmaker driving customer through their
                wide sharble catalog and that's proven great for us. They get
                what they need without us having to worry about them going off
                brand".
              </h2>
              <p>
                <span className="">Ashley Lebowitz</span> , Director of
                Marketing
              </p>
              <a href="#">Read Customer Story</a>
            </div>
          </div>
        </div>
        <div className="feedback-productApp flex">
          <div className="feedback-productApp-item">
            <a href="#" className="flex flex-fdc">
              <img
                className="starImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e99fe75f36b351061cc0e_5stars.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
              <div className="rating">
                <b>4 .5/</b>5 rating
              </div>
              <img
                className="applogoImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e9bd27de40bc8f6ec2ec7_G2-logo.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
            </a>
          </div>
          <div className="feedback-productApp-item">
            <a href="#" className="flex flex-fdc">
              <img
                className="starImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e99fe75f36b351061cc0e_5stars.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
              <div className="rating">
                <b>4.8/</b>5 rating
              </div>
              <img
                className="applogoImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e9bf9c1eb6eb06d657589_capterra-logo.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
            </a>
          </div>
          <div className="feedback-productApp-item">
            <a href="#" className="flex flex-fdc flex-aic">
              <img
                className="starImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e99fe75f36b351061cc0e_5stars.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
              <div className="rating">
                <b>4.5/</b>5 rating
              </div>
              <img
                className="applogoImg"
                src={productHunt}
                style={{ width: "30px", height: "30px" }}
                alt="How do I make a PDF Catalogue for free?"
              ></img>
            </a>
          </div>
          <div className="feedback-productApp-item">
            <a href="#" className="flex flex-fdc flex-aic">
              <img
                className="starImg"
                src="https://assets-global.website-files.com/5fcfd42a1d06cd880266a30a/600e99fe75f36b351061cc0e_5stars.svg"
                alt="How do I make a PDF Catalogue for free?"
              ></img>
              <div className="rating">
                <b>5/</b>5 rating
              </div>
              <img
                className="applogoImg"
                src={sourceforge}
                style={{ width: "30px", height: "30px" }}
                alt="how do i make a printable catalog?"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
