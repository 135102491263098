import React from "react";
import "./AdminHome.scss";
import { useOutletContext } from "react-router-dom";

const AdminHome = () => {
  const originalData = useOutletContext();

  return <div className="adminHome">AdminHome {originalData.uName} </div>;
};

export default AdminHome;
