// import "./App.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "../sass/style.scss";
import "../boxicons-2.1.2/css/boxicons.min.css";
import "../font/GTWalsheim-Regular.ttf";
import "../font/Basique/Basique.ttf";
import Header from "./header/Header.js";
import Hero from "./landingPage/Hero.js";
import Operation from "./operation/Operation.js";
import MobileApp from "./mobileApp/MobileApp";
import Feedback from "./feedback/Feedback.js";
import Footer from "./footer/Footer";
import CustomerService from "./customerService/CustomerService";
import Newsletter from "./newsletter/Newsletter";
import Templates from "./templates/Templates";
import Support from "./support/Support";
import PageNotFound from "./pageNotFound/PageNotFound";
import Login from "./adminpanel/public/login/Login";
import Dashboard from "./adminpanel/private/Dashboard";
import ProtectedRoute from "./adminpanel/ProtectedRoute";
import AdminHome from "./adminpanel/private/pages/AdminHome";
import AdminNewsLetter from "./adminpanel/private/pages/AdminNewsLetter";
import InnerContent from "./adminpanel/private/pages/InnerContent";
import Category from "./support/category/Category";
import Announcements from "./support/category/announcements/Announcements";
import TemplateModal from "./templates/TemplateModal";
import Article from "./support/article/Article";
import Resource from "./resource/Resource";
import Contact from "./contact/Contact";
import LegalInformation from "./legalInformation/LegalInformation";
import LegalInformationContent from "./legalInformation/content/LegalInformationContent";
import ProtectedRouteForDashboard from "./dashboard/ProtectedRouteForDashboard/ProtectedRouteForDashboard";
import UserDashboard from "./dashboard/UserDashboard";
import DashboardStart from "./dashboard/start/DashboardStart";
import DashboardHome from "./dashboard/home/DashboardHome";
import DashboardInnerContent from "./dashboard/innerContent/DashboardInnerContent";
import DashboardCatalogs from "./dashboard/catalogs/DashboardCatalogs";
import DashboardProducts from "./dashboard/product/DashbordProducts";
import DashboardSettings from "./dashboard/settings/DashboardSettings";
import DashboardFeedback from "./dashboard/feedback/DashboardFeedback";
import DashboardLogout from "./dashboard/logout/DashboardLogout";
import DashboardIndividualCatalogProducts from "./dashboard/product/DashboardIndividualCatalogProducts";
import DashboardPreviewPdf from "./dashboard/product/DashboardPreviewPdf";
import ProtectedRouteForGetStarted from "./dashboard/protectedRouteForGetStarted/ProtectedRouteForGetStarted";
import FallBackPage from "./fallback/FallBackPage";

function App() {
  return (
    <>
      <main>
        <Router>
          <Routes>
            {/* Route for home page */}
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Hero />
                  <Operation />
                  <Feedback />
                  <MobileApp />
                  <CustomerService />
                  <Footer />
                </>
              }
              exact
            ></Route>

            {/* Route for newsletter page */}
            {/* <Route
              path="/join/newsletter"
              element={
                <>
                  <Header />
                  <Newsletter />
                </>
              }
              exact
            ></Route> */}

            {/* Route for template page */}
            <Route
              path="/templates"
              element={
                <>
                  <Header />
                  <Templates />
                </>
              }
              exact
            >
              <Route path=":id" element={<TemplateModal />}></Route>
            </Route>

            {/* Route for resource page */}
            <Route path="/resource" element={<Resource />} exact></Route>

            {/* Route for contact  page*/}
            <Route path="/contact" element={<Contact />} exact></Route>

            {/* Route for support page*/}
            <Route path="/support" element={<Support />} exact></Route>

            {/* Route for support/category page  */}
            <Route path="/support/category" element={<Category />}>
              <Route
                path="/support/category"
                element={<Navigate replace to="100-announcements" />}
              ></Route>
              <Route path=":id" element={<Announcements />}></Route>
            </Route>

            {/* Route for legal-information page */}
            <Route
              path="/legal-information"
              exact
              element={<LegalInformation />}
            >
              <Route
                path="/legal-information"
                element={
                  <Navigate replace to="/legal-information/privacy-policy" />
                }
              ></Route>
              <Route path=":id" element={<LegalInformationContent />}></Route>
            </Route>

            {/* Route for article page */}
            <Route
              path="/article"
              element={<Navigate replace to="/support" />}
            ></Route>
            <Route path="/article/:id" element={<Article />}></Route>

            {/* Route for pagenotfound page */}
            <Route path="*" element={<PageNotFound />}></Route>

            {/* Route for admin dashboard login page */}
            <Route path="/admin/login" element={<Login />} exact></Route>

            {/* protected routes */}
            {/* Here <ProtectedRoute /> return Outlet on Dashboard page if user is authenticated othervice it will navigate to login page */}
            <Route exact path="/" element={<ProtectedRoute />}>
              <Route exact path="/" element={<Dashboard />}>
                <Route path="admin/dashboard" element={<InnerContent />}>
                  <Route
                    path="/admin/dashboard"
                    element={<Navigate replace to="home" />}
                  ></Route>
                  <Route path="home" element={<AdminHome />}></Route>
                  <Route
                    path="newsletter"
                    element={<AdminNewsLetter />}
                  ></Route>
                </Route>
              </Route>
            </Route>

            {/* Route for Dashboard from login and signup for user */}
            {/* TODO:  */}
            <Route exact path="/" element={<ProtectedRouteForDashboard />}>
              <Route exact path="/" element={<UserDashboard />}>
                <Route
                  exact
                  path="app/dashboard"
                  element={<DashboardInnerContent />}
                >
                  <Route
                    path="/app/dashboard"
                    element={<Navigate replace to="home" />}
                  ></Route>
                  <Route path="home" element={<DashboardHome />}></Route>
                  <Route
                    path="templates"
                    element={<DashboardCatalogs />}
                  ></Route>
                  <Route path="products" element={<DashboardProducts />}>
                    {/* <Route
                      path=":pathId"
                      element={<DashboardProducts />}
                    ></Route> */}
                  </Route>
                  <Route
                    path="settings"
                    element={<DashboardSettings />}
                  ></Route>
                  <Route
                    path="feedback"
                    element={<DashboardFeedback />}
                  ></Route>
                  <Route path="logout" element={<DashboardLogout />}></Route>
                  <Route
                    exact
                    path="preview"
                    element={<DashboardPreviewPdf />}
                  ></Route>
                </Route>
              </Route>
              {/* Route for fallback page */}
              <Route
                path="app/something-went-wrong"
                element={<FallBackPage />}
              ></Route>
              {/* <Route
                exact
                path="app/dashboard/start"
                element={<DashboardStart />}
              ></Route> */}
            </Route>
            <Route exact path="/" element={<ProtectedRouteForGetStarted />}>
              <Route
                exact
                path="app/dashboard/start"
                element={<DashboardStart />}
              ></Route>
            </Route>
          </Routes>
        </Router>
      </main>
    </>
  );
}

export default App;
