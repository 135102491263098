import React, { useState, useRef, useEffect } from "react";
import "./DashboardCatalogs.scss";
import { BiSearchAlt } from "react-icons/bi";
import { Temps } from "./Temps";
import { FilterButtons } from "./FilterButtons";
import { Link, useOutletContext } from "react-router-dom";
import { baseURL, IMAGE_TEMPLETS_URL } from "../../api/baseURL/BaseURL";
import SkeletonElement from "../../skeletonLoading/SkeletonElement";
import TempCategorySkeleton from "../../skeletonLoading/dashboardSkeleton/TempCategorySkeleton";
import TempTemplate from "../../skeletonLoading/dashboardSkeleton/TempTemplate";
import DashboardCatalogNameModal from "./DashboardCatalogNameModal";
import PlanModal from "../planModal/PlanModal";

const DashboardCatalogs = () => {
  const userData = useOutletContext();
  // console.log("propppppppppppppppppppppppppppp", userData);
  const filterInputRef = useRef(null);
  const templateTopRef = useRef(null);
  const [filterButtonData, setFilterButtonData] = useState(FilterButtons);
  const [tempData, setTempData] = useState(Temps);
  const [tempAPIData, setAPITempData] = useState(null);
  const userID = useOutletContext();
  const [tempTemplateData, setTempTemplateData] = useState(null);
  const [allTemplateData, setAllTemplateData] = useState(null);
  const [active, setActive] = useState(-1);
  const [catalogNameModal, setCatalogNameModal] = useState(false);
  const [templateDataForModal, setTemplateDataForModal] = useState(null);
  const [isPlanModal, setIsPlanModal] = useState(false);
  const [catalogCount, setCatalogCount] = useState(null);
  // console.log("id from catalog page", userID.id);

  const callProductCatalogCountAPI = async () => {
    const res = await fetch(
      baseURL + `get-product-catalog-count/${userData.id}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const data = await res.json();
    const { success } = data;
    if (success) {
      setCatalogCount(data?.data?.catalogsCount);
    }
  };

  const callTemplatesAPI = async () => {
    const res = await fetch(baseURL + `get-template-categories/${userID.id}`);
    const data = await res.json();
    setAPITempData(data.data.templates);

    let allarray = [];
    data.data.templates.map((t) => {
      t.template.map((d, index) => {
        // console.log(d);
        allarray.push(d);
      });
    });
    setTempTemplateData({ template: allarray });
  };

  useEffect(() => {
    callProductCatalogCountAPI();
    callTemplatesAPI();
  }, []);

  const filterALLTemplateHandler = () => {
    let allarray = [];
    tempAPIData.map((t) => {
      t.template.map((d, index) => {
        allarray.push(d);
      });
    });
    setTempTemplateData({ template: allarray });
  };

  const searchFilterTemplateHandler = (serachQuery) => {
    if (serachQuery.length >= 1) {
      // console.log("tempetempmt::", tempTemplateData);
      const searchFilterResults = tempTemplateData.template.filter(
        (template) => {
          return Object.values(template)
            .join(" ")
            .toLowerCase()
            .includes(serachQuery.toLowerCase());
        }
      );
      // console.log("search resulttt:", searchFilterResults);
      setTempTemplateData({ template: searchFilterResults });
    } else {
      filterALLTemplateHandler();
    }
  };

  const filterTemplateHandler = (index) => {
    // console.log("indexxxx::", index);
    if (tempAPIData) {
      setTempTemplateData(tempAPIData[index]);
      // console.log("prooooooofesional", tempTemplateData);
    }
  };

  // console.log(tempTemplateData);

  return (
    <>
      <section className="dashboardCatalogs dashboardCatalogs-section">
        <div className="dashboardCatalogs-header">
          <div className="dashboardCatalogs-header-searchBar flex flex-jcc">
            <input
              type="text"
              ref={filterInputRef}
              placeholder="E.g e-commerce, jewellery ..."
              onKeyDown={(event) => {
                // if (event.keyCode === "Enter" || event.key === "Enter") {
                searchFilterTemplateHandler(filterInputRef.current.value);
                // }
              }}
            ></input>
            <BiSearchAlt className="dashboardCatalogs-header-searchBar-icon" />
          </div>
        </div>

        {!tempAPIData && (
          <>
            <TempCategorySkeleton />
            <TempTemplate />
          </>
        )}

        {/* category filter */}
        <div className="dashboardCatalogs-filter flex" ref={templateTopRef}>
          {tempAPIData === null ? (
            <></>
          ) : (
            <button
              className={
                -1 === active ? "filterButtons active" : "filterButtons"
              }
              onClick={() => {
                setActive(-1);
                filterALLTemplateHandler();
              }}
            >
              All
            </button>
          )}
          {tempAPIData === null ? (
            <></>
          ) : (
            tempAPIData.map((button, index) => {
              const { template_category } = button;
              return (
                <button
                  className={
                    index === active ? "filterButtons active" : "filterButtons"
                  }
                  key={index}
                  onClick={() => {
                    setActive(index);
                    filterTemplateHandler(index);
                  }}
                >
                  {button.template_category}
                </button>
              );
            })
          )}
        </div>
        {/* category filter */}
        {/* template show */}
        <div className="dashboardCatalogs-temps flex">
          {tempTemplateData === null ? (
            <></>
          ) : (
            tempTemplateData.template.map((template, index) => {
              return (
                <div
                  className="dashboardCatalogs-temps-item"
                  key={index}
                  onClick={() => {
                    setCatalogNameModal(true);
                    setTemplateDataForModal(template);
                  }}
                >
                  {/* <Link to="/app/dashboard/products"> */}
                  <div className="dashboardCatalogs-temps-item-imgbox">
                    <img
                      src={IMAGE_TEMPLETS_URL + template.image}
                      alt={template.template_name}
                    ></img>
                  </div>
                  {/* </Link> */}
                  <div className="dashboardCatalogs-temps-item-title flex flex-aic flex-jcc">
                    <h2>{template.template_name}</h2>
                  </div>
                  <div className="dashboardCatalogs-temps-item-btns"></div>
                </div>
              );
            })
          )}
        </div>
      </section>
      {catalogNameModal && (
        <DashboardCatalogNameModal
          templateData={templateDataForModal}
          closeModal={setCatalogNameModal}
          userData={userData}
          catalogCount={catalogCount}
          setIsPlanModal={setIsPlanModal}
        />
      )}
      {isPlanModal && <PlanModal setIsPlanModal={setIsPlanModal} />}
    </>
  );
};

export default DashboardCatalogs;
