import React from "react";
import Shimmer from "../shimmer/Shimmer";
import SkeletonElement from "../SkeletonElement";
import "./TempTemplate.scss";

const TempTemplate = () => {
  return (
    <div className="tempTempalate-wrapper flex">
      <div className="tempTempalate flex flex-jcc flex-aic">
        <SkeletonElement type={"tempImg"}></SkeletonElement>
        <SkeletonElement type={"temptitle"}></SkeletonElement>
        <Shimmer />
      </div>
      <div className="tempTempalate flex flex-jcc flex-aic">
        <SkeletonElement type={"tempImg"}></SkeletonElement>
        <SkeletonElement type={"temptitle"}></SkeletonElement>
        <Shimmer />
      </div>
      <div className="tempTempalate flex flex-jcc flex-aic">
        <SkeletonElement type={"tempImg"}></SkeletonElement>
        <SkeletonElement type={"temptitle"}></SkeletonElement>
        <Shimmer />
      </div>
    </div>
  );
};

export default TempTemplate;
