import React from "react";
import "./CustomerService.scss";
import customerImg from "../../images/customer/customer7.png";
import line from "../../images/props/line.svg";
import dot from "../../images/props/dots2.svg";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import HeroButton from "../buttons/HeroButton";

const CustomerService = () => {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <section className="customerService customerService-section">
      <div className="container">
        <div className="customerService-content flex">
          <div className="customerService-content-left">
            <img src={customerImg} alt="How do I create a PDF Catalogue?"></img>
          </div>
          <div className="customerService-content-right">
            <div className="customerService-content-right-inner">
              <h1>Get helpful support, fast. Like really fast.</h1>
              <p>
                Get 24/7 rockstar support, 365 days a year.
                <br /> We are always happy to help!
              </p>
              <ul>
                <li>
                  <IoCheckmarkDoneCircleSharp className="tickIcon" /> 24 hour
                  average response time
                </li>
                <li>
                  <IoCheckmarkDoneCircleSharp className="tickIcon" /> Dallas, TX
                  based support
                </li>
                <li>
                  <IoCheckmarkDoneCircleSharp className="tickIcon" /> Choose
                  between whatsApp chat or email
                </li>
                <li>
                  <IoCheckmarkDoneCircleSharp className="tickIcon" /> Personable
                  white-glove approach
                </li>
              </ul>
              <span
                onClick={() => {
                  handleUseforFreeLink();
                }}
              >
                <HeroButton
                  url="#"
                  title="Use for Free"
                  style={{ display: "block" }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="lineImg">
        <img src={line} alt="How do I create a PDF Catalogue?"></img>
      </div>
      <div className="dotImg">
        <img src={dot} alt="How do I create a PDF Catalogue?"></img>
      </div>
    </section>
  );
};

export default CustomerService;
