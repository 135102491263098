import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import "./InnerContent.scss";

const InnerContent = () => {
  const originalData = useOutletContext();

  return (
    <>
      <div className="innerContent flex flex-fdc">
        <AdminNavbar auUserData={originalData} />
        <div className="outlet">
          <Outlet context={originalData} />
        </div>
      </div>
    </>
  );
};

export default InnerContent;
