import React, { useEffect, useState } from "react";
import "./DashboardSideBar.scss";
import cmLogo from "../../../images/logo/cm-black2.png";
import { Link, useNavigate } from "react-router-dom";
import sidebarLinks from "./DashboardSidebarLinks.json";

const DashboardSideBar = ({ setPath }) => {
  const [activeLink, setActiveLink] = useState(-1);
  const [activeNarrow, setActiveNarrow] = useState(true);
  const path = window.location.pathname.slice(1);
  setPath(`/${path}`);
  return (
    <section
      className={
        activeNarrow
          ? "dashboardSidebar dashboardSidebar-section flex flex-fdc"
          : "dashboardSidebar narrow dashboardSidebar-section flex flex-fdc"
      }
    >
      <div className="dashboardSidebar-logo flex flex-jcc">
        <img src={cmLogo}></img>
      </div>
      <div
        style={{ flexGrow: "1" }}
        className="dashboardSidebar-linkContainer flex flex-fdc"
      >
        {sidebarLinks.map((link, index) => {
          if (index === sidebarLinks.length - 1) {
            return (
              <a
                // to={link.linkTo}
                href={link.linkTo}
                target="_blank"
                key={index}
                onClick={(e) => {
                  setActiveLink(index);
                }}
                className={"dashboardSidebar-link help flex flex-aic flex-jcsb"}
              >
                <div className="flex flex-aic">
                  <i className={link.icon}></i>
                  {link.title}
                </div>
                <div style={{ color: "gray" }}>
                  <i class="bx bx-right-top-arrow-circle"></i>
                </div>
              </a>
            );
          } else {
            return (
              <Link
                to={link.linkTo}
                key={index}
                onClick={(e) => {
                  setActiveLink(index);
                }}
                className={
                  path.toLowerCase().includes(link.linkTo) ||
                  index === activeLink
                    ? "dashboardSidebar-link active flex flex-aic"
                    : "dashboardSidebar-link flex flex-aic"
                }
              >
                <i className={link.icon}></i>
                {link.title}
              </Link>
            );
          }
        })}
      </div>
      {/* <div
        className="dashboardSidebar-chevrons"
        onClick={() => {
          setActiveNarrow(!activeNarrow);
        }}
      >
        <i class="bx bx-chevrons-left"></i>
      </div> */}
    </section>
  );
};

export default DashboardSideBar;
