import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./AdminSidebar.scss";
import cmLogo from "../../../../images/logo/cm-white2.png";
import sidebarLinks from "./AdminSidebarLinks.json";

const AdminSidebar = () => {
  const [activeLink, setActiveLink] = useState(0);

  return (
    <>
      <div className="adminSidebar">
        <div className="adminSidebar-logo flex flex-jcc">
          <img src={cmLogo}></img>
        </div>
        {sidebarLinks.map((link, index) => {
          return (
            <Link
              to={link.linkTo}
              key={index}
              onClick={(e) => {
                setActiveLink(index);
              }}
              className={
                index === activeLink
                  ? "adminSidebar-link active flex flex-aic"
                  : "adminSidebar-link flex flex-aic"
              }
            >
              <i className={link.icon}></i>
              {link.title}
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default AdminSidebar;
