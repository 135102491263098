import React from "react";
import demo from "../../images/dashboards/intro11.svg";
import HTMLFlipBook from "react-pageflip";
export default function OperationS1() {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <div className="oprt-s1">
      <div className="operation-s1 flex flex-aic">
        <div className="operation-s1-left">
          {/* <span ref={lottieNum1} className="lottieNum1"></span> */}
          <lord-icon
            src="https://cdn.lordicon.com/mtdulhdc.json"
            trigger="loop"
            colors="primary:#121331,secondary:#00be82"
            stroke="80"
            scale="80"
            style={{ width: "60px", height: "60px", marginBottom: "3rem" }}
          ></lord-icon>
          <h2>
            Select Your catalog Template from
            <span className="sketch-highlight">50+ premium </span>
            prebuild Templates.
          </h2>
          <p>
            Showcase your products in a proffessional looking catalog in minutes
            with a free online Catalogmaker. With our prebuild editable premium
            templates.
          </p>
          <a
            href="#"
            onClick={() => {
              handleUseforFreeLink();
            }}
          >
            Try For Free
          </a>
        </div>
        <div className="operation-s1-right flex flex-jcfe">
          <div className="imgBox">
            {/* <HTMLFlipBook width={300} height={500}>
              <div className="demoPage" style={{ background: "red" }}>
                Page 1
              </div>
              <div className="demoPage">Page 2</div>
              <div className="demoPage">Page 3</div>
              <div className="demoPage">Page 4</div>
            </HTMLFlipBook> */}
            <img src={demo} alt="How can i create a free catalog?"></img>
          </div>
        </div>
      </div>
      <div className="operation-s1-dts flex" style={{ textAlign: "center" }}>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/dimghest.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#2ca58d,secondary:#ebe6ef"
            stroke="70"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Premium Templates</a>
          <p>
            Premium catalogs that contains your Trade-mark, TAX number/GSTIN,
            Social media links, Affiliate links, Your watermark.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/rfnfarqt.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#00be82,secondary:#ffc738"
            stroke="80"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Proffessional Templates</a>
          <p>
            Premium Template designs are for proffessional bussiness and Digital
            Marketing.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/qtqvorle.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#646e78,secondary:#ebe6ef,tertiary:#00be82"
            stroke="80"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Editable Templates</a>
          <p>
            Editable text details, image management, and design elements for
            your own custom content.
          </p>
        </div>
      </div>
    </div>
  );
}
