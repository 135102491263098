import React, { useEffect, useState } from "react";
import "./DashboardNavBar.scss";
import profileImg from "../../../images/logo/cm-white2.png";
import { Link } from "react-router-dom";
import PlanModal from "../planModal/PlanModal";
import crown from "../../../images/plan/crown.png";
import { getUserDetails } from "../../utils/getUserDetails";

const DashboardNavBar = ({ userData }) => {
  const [header, setHeader] = useState("");
  const [isPlanModal, setIsPlanModal] = useState(false);
  const userDetails = getUserDetails();
  // console.log("userDatatatataaaaaaaaaaaa", userData);
  const Header = () => {
    if (userData) {
      if (userData.path.includes("home")) setHeader("Dashboard");
      if (userData.path.includes("templates"))
        setHeader("Select Catalog Template");
      if (userData.path.includes("products")) setHeader("Add Catalog Products");
      if (userData.path.includes("feedback")) setHeader("Contact Us");
      if (userData.path.includes("settings")) setHeader("Settings");
      if (userData.path.includes("logout")) setHeader("Logout");
    }
    // if (window.location.pathname.includes("home")) setHeader("Home");
  };

  const handleProButtonClick = () => {
    // const userDetails = getUserDetails();
    if (userDetails?.plan) {
      return;
    }
    setIsPlanModal(true);
  };

  useEffect(() => {
    Header();
  });
  return (
    <>
      <section className="dashboardNavBar dashboardNavBar-section">
        <div className="dashboardNavBar-div flex flex-jcsb flex-aic">
          <div className="dashboardNavBar-header flex flex-aic">{header}</div>
          <div className="dashboardNavBar-btns flex flex-aic">
            <div className="premiumButton">
              {userDetails?.plan ? (
                <p>You have catalog maker pro subscription.</p>
              ) : (
                <button
                  onClick={handleProButtonClick}
                  className="flex flex-aic"
                >
                  <img src={crown} alt="" />
                </button>
              )}
            </div>
            {/* <div className="dashboardNavBar-btns-bell flex flex-aic">
            <i class="bx bx-bell"></i>
          </div> */}
            <div className="dashboardNavBar-btns-profile flex flex-aic">
              <div className="dashboardNavBar-btns-profile-imgbox">
                <img
                  src={
                    "https://b.zmtcdn.com/images/user_avatars/mug_2x.png?fit=around%7C100%3A100&crop=100%3A100%3B%2A%2C%2A"
                  }
                ></img>
              </div>
              {userData.name}
              <i class="bx bxs-chevron-down"></i>
              {/* {userData.id} */}
              <div className="dashboardNavBar-btns-profile-dropdown">
                <ul>
                  <Link to="settings">
                    <li>Account Settings</li>
                  </Link>
                  <Link to="logout">
                    <li>Log Out</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
          {/* {JSON.stringify(userData.path)} */}
        </div>
      </section>
      {isPlanModal && <PlanModal setIsPlanModal={setIsPlanModal} />}
    </>
  );
};

export default DashboardNavBar;
