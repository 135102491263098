import React from "react";
import HeroButton from "../buttons/HeroButton";
import "./Footer.scss";
import cm from "../../images/logo/catalogmaker2.png";
import { BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import lArrow from "../../images/props/larrow.png";
import RArrow from "../../images/props/rarrow.png";
import gDot from "../../images/props/gray-dots.svg";
const Footer = () => {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <section className="footer footer-section">
      <div className="container">
        <div className="footer-header flex flex-fdc flex-aic flex-jcc">
          <h1>See it for yourself</h1>
          <p>
            Get in touch with one of our experts and <br /> take a personal tour
            of Catalogmaker.
          </p>
          <span
            onClick={() => {
              handleUseforFreeLink();
            }}
          >
            <HeroButton url="#" title="Use for Free" />
          </span>
        </div>
        <div className="footer-links">
          <div className="footer-links-inner flex flex-jcsa">
            {/* <div className="footer-links-company ">
              <h1>COMPANY</h1>
              <ul>
                <li>
                  <a href="#">Product</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
                <li>
                  <a href="#">Print Pricing</a>
                </li>
                <li>
                  <a href="#">Education</a>
                </li>
                <li>
                  <a href="#">Use for Free</a>
                </li>
              </ul>
            </div> */}

            <div className="footer-links-resources">
              <h1>Explore</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/Templates">Templates</a>
                </li>
                <li>
                  <a href="/support">Support</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
            <div className="footer-links-company">
              <h1>MOBILE APPLICATION</h1>
              <ul>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.catalogmakerpdfs&pli=1">
                    Android Application
                  </a>{" "}
                </li>
                <li>
                  <a href="https://apps.apple.com/in/app/catalog-maker-brochure-maker/id1623605843">
                    IOS Application
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="footer-links-support">
              <h1>LEGAL</h1>
              <ul>
                <li>
                  <a href="/legal-information/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/legal-information/terms-of-service">
                    Terms of service
                  </a>
                </li>
                <li>
                  <a href="/legal-information/refund-policy">Refund Policy</a>
                </li>
                <li>
                  <a href="/legal-information/shipping-policy">
                    Shipping Policy
                  </a>
                </li>
                <li>
                  <a href="/legal-information/report-abuse">Report Abuse</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-footer flex flex-jcsa">
          <div className="footer-footer-cr">
            {/* <img src={cm}></img> */}
            {/* <p>Copyright © 2022, Catalogmaker — built in India, GT</p> */}
            <p>
              ©All rights are reserved and retained by{" "}
              <a
                href="https://www.stringify.in"
                target="_blank"
                style={{ color: "#39ff14" }}
                rel="noreferrer"
              >
                StringiFy
              </a>
              .
            </p>
          </div>
          <div className="footer-footer-sl flex flex-aic">
            <a href="https://www.linkedin.com/in/catalog-maker-268246239">
              <BsLinkedin className="slic" />
            </a>
            <a href="https://instagram.com/catalogmaker?igshid=YmMyMTA2M2Y=">
              <RiInstagramFill className="slic" />
            </a>
            <a href="https://twitter.com/catalogsmaker">
              <FaTwitterSquare className="slic" />
            </a>
            <a href="https://www.facebook.com/catalogmaker.in">
              <FaFacebookSquare className="slic" />
            </a>
            <a href="https://www.youtube.com/channel/UC_Wn5joRnCF3j7Eq4K7_k3Q">
              <IoLogoYoutube className="slic" />
            </a>
          </div>
        </div>
      </div>

      <div className="arrow1">
        <img src={lArrow}></img>
      </div>
      <div className="arrow2">
        <img src={RArrow}></img>
      </div>
      <div className="dotted">
        <img src={gDot}></img>
      </div>
      <div className="arrow2"></div>
    </section>
  );
};

export default Footer;
