import React, { useEffect, useState } from "react";
import "./DashboardPdfNavbar.scss";
// import { saveAs } from "file-saver";
// import fileDownload from 'js-file-download'
// import { PDFDownloadLink } from "@react-pdf/renderer";
const DashboardPdfNavbar = ({
  catalogID,
  userID,
  catalogName,
  templatePhpLink,
  pdfPreviewModal,
}) => {
  // console.log("nameeeeeeeeeeeeee", templatePhpLink);
  const [PDFDownloadLoader, setPDFDownloadLoader] = useState(false);

  const callDownloadPdfAPI = () => {
    setPDFDownloadLoader(true);
    fetch(
      //   "https://cors-anywhere.herokuapp.com/" +
      `${templatePhpLink}?user_id=${userID.id}&catalog_id=${catalogID}`,
      {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${catalogName}.pdf`);
        link.click();
        setPDFDownloadLoader(false);
      });
  };

  return (
    <nav className="dashboardIndividualCatalogProducts-navbar flex flex-jcsb flex-aic">
      <div className="dashboardIndividualCatalogProducts-navbar-title">
        {catalogName}
      </div>
      <div className="dashboardIndividualCatalogProducts-navbar-btns flex">
        <div
          //   href={`${templatePhpLink}?user_id=${userID.id}&catalog_id=${catalogID}#toolbar=0`}
          //   target="_blank"
          className="dashboardIndividualCatalogProducts-navbar-btns-preview"
          onClick={() => {
            pdfPreviewModal(true);
          }}
        >
          Preview
        </div>
        <div
          className="dashboardIndividualCatalogProducts-navbar-btns-download flex flex-aic flex-jcc"
          onClick={() => {
            callDownloadPdfAPI();
          }}
        >
          {PDFDownloadLoader ? (
            <lord-icon
              className="dashboardIndividualCatalogProducts-navbar-btns-download-btn-loader"
              src="https://cdn.lordicon.com/xjovhxra.json"
              trigger="loop"
              colors="primary:#ffffff,secondary:#242424"
              stroke="80"
              scale="75"
              style={{
                width: "25px",
                // height: "45px",
                position: "absolute",
                left: "35%",
              }}
            ></lord-icon>
          ) : (
            "Download"
          )}
        </div>
      </div>
    </nav>
  );
};

export default DashboardPdfNavbar;
