import React from "react";
import HeroButton from "../buttons/HeroButton.js";
import Statistics from "./Statistics.js";
import "../../sass/style.scss";
import dashboard2 from "../../images/dashboards/dashboardHome.jpg";
import heroArrow from "../../images/props/home-hero-arrows.svg";
import gDot from "../../images/props/gray-dots.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <section className="hero home-section">
      <div className="hero-bg"></div>
      <div className="hero-top flex flex-jcc flex-aic flex-fdc">
        <h1>
          Smart &nbsp; <span className="circle-sketch-highlight">Catalog</span>{" "}
          &nbsp; You Should Make Everyday: &nbsp;
          <span style={{ color: "#ffc828" }}>proffessional</span>, editable
          shareble and faster
        </h1>
        <p>
          Catalogmaker helps you to create free online catalog. Create a
          dazzling PDF Catalogues that includes complete list of items
        </p>
        <span
          onClick={() => {
            handleUseforFreeLink();
          }}
        >
          <HeroButton url="#" title="Use For Free" />
        </span>
      </div>
      <div className="hero-bottom flex flex-jcc">
        <div className="hero-dashboard ">
          <img src={dashboard2} alt="create catalogue online free"></img>
          <img
            src={heroArrow}
            className="heroArrowProp"
            alt="heroarrowprop"
          ></img>
          <img
            src={gDot}
            className="heroDotProp"
            alt="How do i make my own catalogue?"
          ></img>
        </div>
      </div>
      <div className="hero-bottom-link">
        <p>
          Credit Card Not Required!
          <a
            href="#"
            onClick={() => {
              handleUseforFreeLink();
            }}
          >
            Try For Free
          </a>
        </p>
      </div>
      <Statistics />
    </section>
  );
};

export default Hero;
