import React, { useState } from "react";
import SupportHeader from "./SupportHeader";
import "./Support.scss";
import gdot from "../../images/props/gray-dots.svg";
import garrow from "../../images/props/home-hero-arrows.svg";
import tarrow from "../../images/props/larrow.png";
import { Supp } from "./Supp";
import Footer from "../footer/Footer";
import que from "./category/announcements/announcementQue.json";

const Support = () => {
  const [supportCard, setSupportCard] = useState(Supp);
  const [searchResult, setSearchResult] = useState(0);
  const [activeResult, setActiveResult] = useState(false);

  const queArr = [];
  const queArray = () => {
    que.map((c) => {
      for (var key in c) {
        Array.prototype.push.apply(queArr, c[key]);
      }
    });
  };
  queArray();

  const searchHandler = (e) => {
    if (e !== " " && e.length !== 0) {
      const searchFilteredResult = queArr.filter((q) => {
        return Object.values(q)
          .join(" ")
          .toLowerCase()
          .includes(e.toLowerCase());
      });
      setSearchResult(searchFilteredResult);
    } else {
      setSearchResult(0);
    }
  };

  return (
    <>
      <SupportHeader />
      <section className="support support-section">
        <div className="support-header">
          <h1>catalogmaker Knowledge Base</h1>
          <div className="support-header-searchBar flex flex-aic flex-jcc">
            <input
              type="text"
              placeholder="Search Your Doubt Here..."
              onChange={(e) => {
                searchHandler(e.target.value);
              }}
              onFocus={() => {
                setActiveResult(true);
              }}
              onBlur={(e) => {
                setTimeout(() => {
                  setActiveResult(false);
                }, 1000);
              }}
            ></input>
            <button>Search</button>
            <div
              className={
                activeResult
                  ? "support-header-searchBarResults active"
                  : "support-header-searchBarResults"
              }
            >
              <ul>
                {searchResult.length > 0 ? (
                  searchResult.map((q, index) => {
                    return (
                      <a href={q.queLink} key={index}>
                        <li>{q.queTitle}</li>
                      </a>
                    );
                  })
                ) : (
                  <a>
                    <li>No Result Found</li>
                  </a>
                )}
              </ul>
            </div>
          </div>
          <div className="support-header-dot">
            <img src={gdot}></img>
          </div>
          <div className="support-header-garrow">
            <img src={garrow}></img>
          </div>
          <div className="support-header-tarrow">
            <img src={tarrow}></img>
          </div>
        </div>
        <div className="support-dashboard">
          <div className="container">
            <div className="support-category flex">
              {supportCard.map(
                ({ title, discription, numOfArtivcles, link }) => {
                  return (
                    <a href={link}>
                      <div className="support-category-item">
                        <h1>{title}</h1>
                        <p>{discription}</p>
                        <button>{numOfArtivcles}</button>
                      </div>
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Support;
