import React, { useState, useRef } from "react";
import { baseURL } from "../../api/baseURL/BaseURL";
import imgLogo from "../../../images/props/img2.png";
import { motion } from "framer-motion";
const DashboardAddProductModal = ({
  closeModal,
  catalogID,
  userID,
  callPFSCAPI,
  trigger,
  //   callPAPI,
  //   id,
}) => {
  // console.log("uuuuuuuuuuuuuu", userID);
  const [activeStockClass, setActiveStockClass] = useState("1");
  const nameRef = useRef(null);
  const priceRef = useRef(null);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [productlink, setProductLink] = useState("");
  const [testImg, setTestImg] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [cataID, setCataID] = useState(catalogID);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const ImageFileselectHandler = (event) => {
    setError(null);
    setImgData(event.target.files[0]);
    var src = URL.createObjectURL(event.target.files[0]);
    setTestImg(src);
    // console.log("Imagggggeeee::", src);
  };

  const callAddProductAPI = async () => {
    setIsLoading(true);
    const fData = new FormData();
    fData.append("user_id", userID.id);
    fData.append("catalog_id", cataID);
    fData.append("name", name);
    fData.append("amount", price);
    fData.append("images", imgData);
    fData.append("in_stock", activeStockClass);
    fData.append("product_link", productlink);
    const res = await fetch(baseURL + `add-product`, {
      method: "POST",
      headers: {
        accept: "application/json",
        // "Content-Type": "multipart/form-data",
      },
      body: fData,
    });
    const data = await res.json();
    const { success } = data;

    if (success) {
      // console.log("ressssssss", catalogID);
      trigger((c) => c + 1);
      //   callPAPI(id);
      callPFSCAPI(catalogID);
      closeModal(false);
    } else {
      setError(data?.message);
      // console.log(data);
    }
    setIsLoading(false);
  };

  // console.log("hahahahah", cataID);
  return (
    <section className="dashboardIndividualProductEdit dashboardIndividualProductEdit-section flex flex-aic flex-jcc">
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="dashboardIndividualProductEdit-item"
      >
        <div
          className="dashboardIndividualProductEdit-item-closebtn"
          onClick={() => {
            closeModal(false);
          }}
        >
          <span></span>
          <span></span>
        </div>
        {/*  */}
        {/* <img src={testImg}></img> */}
        {/*  */}
        <div className="dashboardIndividualProductEdit-item-imgbox">
          {testImg === null ? (
            <img
              src={imgLogo}
              style={{
                objectFit: "contain",
                width: "50%",
                color: "gray",
                display: "block",
                margin: "auto",
              }}
            ></img>
          ) : (
            <img src={testImg}></img>
          )}
          <div className="dashboardIndividualProductEdit-item-imgbox-selectImg">
            <label for="file-upload" className="custom-file-upload">
              <i class="bx bx-plus"></i>
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={ImageFileselectHandler}
            ></input>
          </div>
        </div>
        <div className="dashboardIndividualProductEdit-item-details">
          <form>
            <div className="dashboardIndividualProductEdit-item-details-d">
              <input
                type="text"
                placeholder="Product Name"
                ref={nameRef}
                onChange={(e) => {
                  setName(e.target.value);
                  // console.log(name);
                }}
              ></input>
              <input
                type="text"
                placeholder="Price"
                ref={priceRef}
                onChange={(e) => {
                  setPrice(e.target.value);
                  // console.log(price);
                }}
              ></input>
              <input
                type="text"
                placeholder="Product Link ( amazon, flipkart, etc )"
                ref={priceRef}
                onChange={(e) => {
                  setProductLink(e.target.value);
                  // console.log(price);
                }}
                style={{ fontSize: "16px" }}
              ></input>
            </div>
            <div className="dashboardIndividualProductEdit-item-details-c flex flex-jcc flex-aic">
              <span className="stockBtn flex">
                <span
                  className={
                    activeStockClass === "1" ? "stockin active" : "stockin"
                  }
                  onClick={() => {
                    setActiveStockClass("1");
                  }}
                >
                  Stock In
                </span>
                <span
                  className={
                    activeStockClass === "0" ? "stockout active" : "stockout"
                  }
                  onClick={() => {
                    setActiveStockClass("0");
                  }}
                >
                  Stock Out
                </span>
              </span>
              {/* <input type="checkbox" className="checkbox"></input> Stock In */}
              {/* <input type="checkbox" className="checkbox"></input> Stock Out */}
            </div>
            <div className="dashboardIndividualProductEdit-item-details-error">
              {error}
            </div>
            <div className="dashboardIndividualProductEdit-item-details-btn">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  callAddProductAPI();
                }}
                disabled={isLoading}
              >
                ADD PRODUCT
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </section>
  );
};

export default DashboardAddProductModal;
