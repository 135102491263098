import React from "react";
import cmLogo from "../../images/logo/catalogmaker2.png";
import "./SupportHeader.scss";

const SupportHeader = () => {
  return (
    <nav className="supportNav">
      <div className="container flex flex-jcsb flex-aic">
        <div className="supportNav-left">
          <img
            src={cmLogo}
            alt="create free product catalog"
            onClick={() => {
              window.location.href = "/";
            }}
          ></img>
        </div>
        <div className="supportNav-right">
          <a href="/contact">Contact</a>
        </div>
      </div>
    </nav>
  );
};

export default SupportHeader;
