import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import "./DashboardSettings.scss";
import DashboardSettingsChangePassword from "./DashboardSettingsChangePassword";
import DashboardSettingsDeleteAcc from "./DashboardSettingsDeleteAcc";
import DashboardSettingsProfile from "./DashboardSettingsProfile";
const DashboardSettings = () => {
  const OutletData = useOutletContext();
  const [active, setActive] = useState("profile");
  return (
    <section className="dashboardSettings dashboardSettings-section flex">
      <div className="dashboardSettings-sidebar">
        <p className="dashboardSettings-sidebar-header">My Account</p>
        <ul>
          <li
            onClick={() => {
              setActive("profile");
            }}
            className={
              active === "profile"
                ? "dashboardSettings-sidebar-link active"
                : "dashboardSettings-sidebar-link"
            }
          >
            Profile
          </li>
          <li
            onClick={() => {
              setActive("changePassword");
            }}
            className={
              active === "changePassword"
                ? "dashboardSettings-sidebar-link active"
                : "dashboardSettings-sidebar-link"
            }
          >
            Change Password
          </li>
          <li
            onClick={() => {
              setActive("deleteAccount");
            }}
            className={
              active === "deleteAccount"
                ? "dashboardSettings-sidebar-link active"
                : "dashboardSettings-sidebar-link"
            }
          >
            Delete Account
          </li>
        </ul>
      </div>
      <div className="dashboardSettings-sidebarOutput">
        {active === "profile" && (
          <DashboardSettingsProfile outletData={OutletData} />
        )}
        {active === "changePassword" && (
          <DashboardSettingsChangePassword outletData={OutletData} />
        )}
        {active === "deleteAccount" && (
          <DashboardSettingsDeleteAcc outletData={OutletData} />
        )}
      </div>
    </section>
  );
};

export default DashboardSettings;
