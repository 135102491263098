import React, { useEffect, useState } from "react";
import CategoryHeader from "../category/CategoryHeader";
import "./Article.scss";
import articles from "./articles.json";

const Article = () => {
  const path = window.location.pathname;
  const [ariclesData, setArticlesData] = useState(articles);
  const [currArticle, setCurrArticle] = useState(null);

  useEffect(() => {
    const checkKey = () => {
      ariclesData.map((e) => {
        for (var key in e) {
          if (key === path) {
            setCurrArticle(e[key]);
          }
        }
      });
    };
    checkKey();
  }, []);
  return (
    <section className="article article-section">
      <CategoryHeader />
      <div className="container">
        {currArticle && (
          <>
            <div className="article-content">
              {currArticle.map((artic) => {
                return (
                  <>
                    <div className="article-content-header">
                      <h1>{artic.header}</h1>
                    </div>
                    <div className="article-content-discrp">
                      <p>{artic.pargh1}</p>
                    </div>
                    <br />
                    <div className="article-content-discrp">
                      <p>{artic.pargh2}</p>
                    </div>
                    <br />
                    <div className="article-content-list">
                      <ul>
                        {artic.lists.map((li, index) => {
                          return (
                            <>
                              <li key={index}>{li.list}</li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="article-content-discrp">
                      <p>{artic.pargh3}</p>
                    </div>
                    <br />
                    <div className="article-content-discrp">
                      <p>{artic.pargh4}</p>
                    </div>
                    <br />
                    <div className="article-content-img">
                      <div className="imgbox">
                        <img
                          src={artic.img}
                          alt="make catalog online for free"
                        ></img>
                      </div>
                    </div>
                    <div className="article-content-subhead">
                      <p>{artic.subhead1}</p>
                    </div>
                    <div className="article-content-discrp">
                      <p>{artic.pargh5}</p>
                    </div>
                    <br />
                    <div className="article-content-subhead">
                      <p>{artic.subhead2}</p>
                    </div>
                    <div className="article-content-discrp">
                      <p>{artic.pargh6}</p>
                    </div>
                    <br />
                    <div className="article-content-link">
                      <ul>
                        {artic.links.map((a, index) => {
                          return (
                            <>
                              <li key={index}>
                                <a href={a.link}>{a.title}</a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="article-content-note">{artic.note}</div>
                  </>
                );
              })}
            </div>
          </>
        )}
        <p
          style={{
            textAlign: "right",
            fontSize: "12px",
            color: "gray",
            margin: "20px 0",
          }}
        >
          &#169; Catalogmaker 2022
        </p>
      </div>
    </section>
  );
};

export default Article;
