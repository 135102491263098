import React, { useEffect, useState } from "react";
import "./AdminNewsLetter.scss";
import { useOutletContext } from "react-router-dom";
import { NewsLetterUserEmailsAPI } from "../../../api/newsletter/NewsLetterUserEmailsAPI";

const AdminNewsLetter = () => {
  const originalData = useOutletContext();
  const [emailData, setEmailData] = useState([]);
  const [emailCount, setEmailCount] = useState(0);

  const callNewsLetterAPI = async () => {
    try {
      const newsLetterData = await fetch(NewsLetterUserEmailsAPI, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: "id=" + originalData.uId,
      });
      const newsLetterData_1 = await newsLetterData.json();
      setEmailData(newsLetterData_1[0].Data);
      setEmailCount(newsLetterData_1[0].count);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    callNewsLetterAPI();
  }, []);

  return (
    <div className="adminNewsletter">
      <div className="adminNewsletter-statecards flex">
        <div className="adminNewsletter-statecards-number">
          <h2>Total User</h2>
          <h3>{emailCount}</h3>
        </div>
        <div className="adminNewsletter-statecards-number"></div>
        <div className="adminNewsletter-statecards-number"></div>
      </div>
      <div className="adminNewsletter-emailList">
        <h3>Email List</h3>
        <div className="adminNewsletter-emailList-table">
          {emailData.map((e) => {
            return (
              <>
                <div className="flex">
                  <h1>{e.name}</h1>
                  <h2>{e.email}</h2>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminNewsLetter;
