export const Supp = [
  {
    title: "Status & Announcements",
    discription:
      "See updates on the latest & greatest tools, features, and capabilities in catalogmaker",
    numOfArtivcles: "03 articles",
    link: "/support/category/100-announcements",
  },
  {
    title: "Getting Started",
    discription:
      "Get your catalogmaker account up and running in no time without any delay.",
    numOfArtivcles: "07 articles",
    link: "/support/category/200-getting-started",
  },
  {
    title: "Account",
    discription:
      "Manage your catalogmaker plan, billing information, account details, settings.",
    numOfArtivcles: "03 articles",
    link: "/support/category/300-account-management",
  },
];
