import React, { useEffect, useState } from "react";
import "./Announcements.scss";
import { Link } from "react-router-dom";
import annQue from "./announcementQue.json";

const Announcements = () => {
  const path = window.location.pathname;
  const headPath = path + "/header";
  const [que, setQue] = useState(null);
  const [headDetails, setHeadDetails] = useState(null);

  useEffect(() => {
    const checkKey = () => {
      annQue.map((e) => {
        for (var key in e) {
          if (key === path) {
            setQue(e[key]);
          }
          if (key === headPath) {
            setHeadDetails(e[key]);
          }
        }
      });
    };
    checkKey();
  }, []);

  return (
    <>
      <div className="announcements announcements-section">
        <div className="announcements-header">
          <h1>{headDetails && headDetails[0].header}</h1>
          <p>{headDetails && headDetails[0].discription}</p>
        </div>
        <div className="announcements-questions">
          <ul>
            {que
              ? que.map((que, index) => {
                  return (
                    <>
                      <Link to={que.queLink} key={index}>
                        <li>
                          <i className="bx bx-file"></i>
                          {que.queTitle}
                        </li>
                      </Link>
                      <br />
                    </>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Announcements;
