import React from "react";
// import demo from "../../images/demo.png";
import demo from "../../images/dashboards/intro33.svg";

export default function OperationS3() {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <div className="oprt-s3" style={{ marginTop: "5rem" }}>
      <div className="operation-s1 flex flex-aic">
        <div className="operation-s1-left">
          {/* <span ref={lottieNum1} className="lottieNum1"></span> */}
          <lord-icon
            src="https://cdn.lordicon.com/igpsgesd.json"
            trigger="loop"
            colors="primary:#121331,secondary:#325aff"
            stroke="80"
            scale="80"
            style={{ width: "60px", height: "60px", marginBottom: "3rem" }}
          ></lord-icon>
          <h2>
            Make your free catalog on our web application and share direct from
            your mobile app.
          </h2>
          <p>
            Because of our real time data store user will able to sync their
            catalogs from web to mobile app and the other way round.
          </p>
          <a
            href="#"
            onClick={() => {
              handleUseforFreeLink();
            }}
          >
            Try For Free
          </a>
        </div>
        <div className="operation-s1-right flex flex-jcfe">
          <div className="imgBox">
            <img src={demo} alt="how do i create a pdf catalog?"></img>
          </div>
        </div>
      </div>
      <div className="operation-s1-dts flex" style={{ textAlign: "center" }}>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/ozqvdvoh.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary :#325aff,secondary :#ffc738"
            stroke="80"
            scale="50"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">
            Share, promote and <br /> distribute
          </a>
          <p>
            Share by email, social media as URLs, download or send as a PDF.
            Embed to your site quickly and cleanly to make product marketing
            promotion painless.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/dmonnrsh.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary :#3a3347,secondary:#325aff,tertiary:#92140c,quaternary:#4bb3fd"
            stroke="60"
            scale="60"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Marketing Boost</a>
          <p>
            Reinforce your marketing with SEO-friendly catalogs. Plus, you can
            analyze content with built-in stats and improve it based on those
            insights.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/hbwqfgcf.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#646e78,secondary:#ffc738,tertiary:#325aff"
            stroke="75"
            scale="60"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Brand Intigrity</a>
          <p>
            Each flipbook becomes an extension of your brand with a clickable
            logo and branded publication links that have mention on large
            e-commerce vendor.
          </p>
        </div>
      </div>
    </div>
  );
}
