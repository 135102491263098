import React from "react";
import "./HeroButton.scss";
import { ImArrowRight2 } from "react-icons/im";

const HeroButton = (props) => {
  const { url, title } = props;
  return (
    <>
      <a href={url} className="HeroButton">
        {title}
        <ImArrowRight2 className="heroButtonIcon" />
      </a>
    </>
  );
};

export default HeroButton;
