import React, { useEffect } from "react";
import "./planModal.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import catalogImg from "../../../images/plan/catalog.png";
import hdImg from "../../../images/plan/image.png";
import mobileImg from "../../../images/plan/mobile.png";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import { userLoginAPI } from "../../api/userAuth/userLogin";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { motion } from "framer-motion";
import { loadScript } from "../razorPaymentGateway/utils";
import { baseURL } from "../../api/baseURL/BaseURL";
import { getUserDetails } from "../../utils/getUserDetails";
import { setUserDetails } from "../../utils/setUserDetails";

const PlanModal = ({ setIsPlanModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [isTCChecked, setIsTCChecked] = useState(true);
  const [isRazorScriptLoaded, setIsRazorScriptLoaded] = useState(false);
  const userDetails = getUserDetails();

  const fetchPlanPrice = () => {
    setIsLoading(true);
    fetch(userLoginAPI + `get-plans`, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const { data } = res;
        const { plans } = data || {};
        setPlanDetails(plans[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRazorpayResponse = async (response) => {
    const { razorpay_payment_id = "" } = response || {};
    try {
      if (razorpay_payment_id) {
        const res = await fetch(baseURL + `purchase-plan`, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `user_id=${
            userDetails?.id
          }&plan_id=1&purchase_id=${razorpay_payment_id}&pay_mode=razorpay_web&amt=${
            userDetails?.currency === "INR"
              ? "₹" + planDetails?.price
              : "$" + planDetails?.usd_price
          }`,
        });
        const data = await res.json();
        const { success } = data;
        if (success) {
          setUserDetails("plan", true);
          setIsPlanModal(false);
        } else {
          setIsPlanModal(false);
          window.location.pathname = "app/something-went-wrong";
        }
      } else {
        setIsPlanModal(false);
        window.location.pathname = "app/something-went-wrong";
      }
    } catch (error) {
      console.log("Error", error);
      setIsPlanModal(false);
      window.location.pathname = "app/something-went-wrong";
    }
  };

  const handleContinue = async () => {
    const { name, email, phone, currency } = userDetails;
    const { price = 999, usd_price = 19.99 } = planDetails;
    const amount = (currency === "INR" ? price * 100 : usd_price * 100).toFixed(
      2
    );
    const razorCurrency = currency === "INR" ? "INR" : "USD";
    if (isRazorScriptLoaded) {
      const options = {
        key: process.env.REACT_APP_SFY_RAZORPAY_KEY,
        // key: process.env.REACT_APP_SFY_RAZORPAY_TESTING_KEY,
        // key: process.env.REACT_APP_RAZORPAY_KEY,
        // key: process.env.REACT_APP_RAZORPAY_TESTING_KEY,
        currency: razorCurrency,
        amount,
        name: "Catalog Maker",
        description: "Catalog Maker Pro Subscription - Web",
        image: "https://catalogmaker.in/logo_lightmode.png",
        handler: handleRazorpayResponse,
        prefill: {
          name,
          email,
          contact: phone,
        },
        // config:
        //   currency === "INR"
        //     ? {}
        //     : {
        //         display: {
        //           blocks: {
        //             banks: {
        //               name: "Pay via Paypal",
        //               instruments: [
        //                 {
        //                   method: "wallet",
        //                   wallets: ["paypal"],
        //                 },
        //               ],
        //             },
        //           },
        //           hide: [
        //             {
        //               method: "card",
        //             },
        //           ],
        //           sequence: ["block.banks"],
        //           preferences: {
        //             show_default_blocks: true,
        //           },
        //         },
        //       },
        modal: {
          ondismiss: function () {
            console.log("Checkout form closed");
          },
        },
        theme: {
          color: "#ff6347",
        },
      };

      let paymentObject = new window.Razorpay(options);

      // paymentObject.on("payment.failed", (response) => {
      //   window.location.pathname = "app/something-went-wrong";
      // });

      paymentObject.open();
    } else {
    }
  };

  useEffect(() => {
    fetchPlanPrice();
    if (!isRazorScriptLoaded) {
      const isScriptLoaded = loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      setIsRazorScriptLoaded(isScriptLoaded);
    }

    return () => {
      const scriptElement = document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
      );
      const razorpayContainer = document.querySelector(".razorpay-container");
      if (scriptElement && razorpayContainer) {
        document.body.removeChild(scriptElement);
        document.body.removeChild(razorpayContainer);
      }
    };
  }, []);

  return (
    <section className="planModal-bg-overlay flex flex-aic flex-jcc">
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="planModal"
      >
        <div className="">
          {isLoading ? (
            <div className="loading flex flex-aic flex-jcc">
              <p>Loading ...</p>
            </div>
          ) : (
            <>
              <div className="planModal-header flex flex-jcsb flex-aic">
                <p>Catalog Maker Pro Plan</p>
                <AiOutlineCloseCircle
                  size={25}
                  onClick={() => setIsPlanModal(false)}
                />
              </div>
              <div className="planModal-pros flex flex-jcc">
                <span className="flex flex-aic flex-jcc flex-dc">
                  <img src={mobileImg} alt="Remove Watermark"></img>
                  <p>Remove Watermark</p>
                </span>
                <span className="flex flex-aic flex-jcc flex-dc">
                  <img src={catalogImg} alt="Unlimited Catalogs"></img>
                  <p>Unlimited Catalogs</p>
                </span>
                <span className="flex flex-aic flex-jcc flex-dc">
                  <img src={hdImg} alt="product Quality"></img>
                  <p>Image Quality</p>
                </span>
              </div>
              <div className="planModal-price">
                <div className="flex flex-jcsb flex-aic flex-ais">
                  <div className="flex flex-fdc flex-jcc">
                    <div className="price-amount flex flex-aic">
                      <p>
                        {userDetails?.currency === "INR"
                          ? `INR ${planDetails?.price}.00`
                          : `USD ${planDetails?.usd_price}`}
                      </p>
                      <IoArrowBack />
                      <p>
                        {userDetails?.currency === "INR"
                          ? `${planDetails?.mrp}.00`
                          : `${planDetails?.usd_mrp}`}
                      </p>
                    </div>
                    <div className="one-time">One-Time Purchase</div>
                  </div>
                  <div className="off">
                    {Math.round(
                      100 - (planDetails?.price * 100) / planDetails?.mrp
                    )}
                    % OFF
                  </div>
                </div>
                <div className="lifeTime">Pay One Time, Enjoy LifeTime</div>
                <div className="limited-offer flex flex-aic flex-jcc">
                  <BiTimeFive />
                  Limited time Offer
                </div>
              </div>
              <div className="planModal-tandc flex flex-aic">
                <input
                  type="checkbox"
                  onChange={({ target: { checked } }) =>
                    setIsTCChecked(!checked)
                  }
                />
                <label>
                  I accept the{" "}
                  <Link to="/legal-information/terms-of-service">
                    terms and conditions
                  </Link>{" "}
                  and I have read the{" "}
                  <Link to="/legal-information/privacy-policy">
                    privacy policy
                  </Link>{" "}
                  for the payment
                </label>
              </div>
              <div className="planModal-continue">
                <button disabled={isTCChecked} onClick={handleContinue}>
                  Continue
                </button>
              </div>
            </>
          )}
        </div>
      </motion.div>
    </section>
  );
};

export default PlanModal;
