import React from "react";
import { decryptData } from "../../adminpanel/Util";
import { Navigate, Outlet } from "react-router-dom";
const ProtectedRouteForGetStarted = () => {
  let originalID;
  let idofCN;
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const idfliu = localStorage.getItem("idfliu");
  const idfliuSession = sessionStorage.getItem("idfliu");

  if (!idfliu) {
    // serching in session
    if (!idfliuSession) window.location.pathname = "/";
    else {
      originalID = decryptData(idfliuSession, salt);
      originalID["storage"] = "session";
      idofCN = originalID.companyName;
    }
  } else {
    originalID = decryptData(idfliu, salt);
    originalID["storage"] = "local";
    idofCN = originalID.companyName;
  }
  // console.log("datatatatatatatta", originalID);
  if (!originalID && !idofCN) {
    window.location.pathname = "/";
  }

  return idofCN === null ? (
    <Outlet context={originalID} />
  ) : (
    <Navigate to="/app/dashboard/home" replace />
  );
};

export default ProtectedRouteForGetStarted;
