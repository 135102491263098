export const baseURL = "https://catalogmaker.in/catalogMakerApi/public/api/";

export const IMAGE_USER_URL =
  "https://catalogmaker.in/catalogMakerApi/storage/app/public/uploads/users/";

export const IMAGE_PRODUCTS_URL =
  "https://catalogmaker.in/catalogMakerApi/storage/app/public/uploads/products/";

export const IMAGE_TEMPLETS_URL =
  "https://catalogmaker.in/catalogMakerApi/storage/app/public/uploads/templates/";
