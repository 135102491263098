import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import CategoryHeader from "./CategoryHeader";
import "./Category.scss";
import { Supp } from "../Supp";
import que from "./announcements/announcementQue.json";

const Category = () => {
  const [activeLink, setActiveLink] = useState(-1);
  const [searchResult, setSearchResult] = useState(0);
  const [activeResult, setActiveResult] = useState(false);

  const queArr = [];
  const queArray = () => {
    que.map((c) => {
      for (var key in c) {
        Array.prototype.push.apply(queArr, c[key]);
      }
    });
  };
  queArray();

  const searchHandler = (e) => {
    if (e !== " " && e.length !== 0) {
      const searchFilteredResult = queArr.filter((q) => {
        return Object.values(q)
          .join(" ")
          .toLowerCase()
          .includes(e.toLowerCase());
      });
      setSearchResult(searchFilteredResult);
    } else {
      setSearchResult(0);
    }
  };

  return (
    <>
      <CategoryHeader />
      <section className="category category-section">
        <div className="container flex">
          <div className="category-section-sidebar">
            <div className="category-section-sidebar-search">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  searchHandler(e.target.value);
                }}
                onFocus={() => {
                  setActiveResult(true);
                }}
                onBlur={(e) => {
                  setTimeout(() => {
                    setActiveResult(false);
                  }, 1000);
                }}
              ></input>
              <div
                className={
                  activeResult
                    ? "category-section-sidebar-searchResults active"
                    : "category-section-sidebar-searchResults"
                }
              >
                <ul>
                  {searchResult.length > 0 ? (
                    searchResult.map((q, index) => {
                      return (
                        <a href={q.queLink} key={index}>
                          <li>{q.queTitle}</li>
                        </a>
                      );
                    })
                  ) : (
                    <a>
                      <li>No Result Found</li>
                    </a>
                  )}
                </ul>
              </div>
            </div>
            <div className="category-section-sidebar-category">
              <h1>Categories</h1>
              <ul>
                {Supp.map((categoryLink, index) => {
                  return (
                    <a
                      href={categoryLink.link}
                      // to={categoryLink.link}
                      key={index}
                      onClick={() => setActiveLink(index)}
                      className={
                        index === activeLink ||
                        window.location.pathname === categoryLink.link
                          ? "active"
                          : ""
                      }
                    >
                      <li className="flex flex-aic">
                        {categoryLink.title}
                        <i className="bx bx-chevrons-right"></i>
                      </li>
                    </a>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="category-section-contentSection">
            <Outlet />
          </div>
        </div>
        <div className="container">
          <p
            style={{
              textAlign: "right",
              fontSize: "12px",
              color: "gray",
              margin: "20px 0",
            }}
          >
            &#169; Catalogmaker 2022
          </p>
        </div>
      </section>
    </>
  );
};

export default Category;
