import React, { useState, useEffect, useRef } from "react";
import "./SignupModal.scss";
import { motion } from "framer-motion";
import { userSignupAPI } from "../../api/userAuth/userSignup";
import { signInWithFacebbok, signInWithGoogle } from "../firebase/Firebase";
import { encryptData } from "../../adminpanel/Util";
import { baseURL } from "../../api/baseURL/BaseURL";
import OtpInput from "react-otp-input";

const SignupModal = ({ closeModal, openLoginmodal }) => {
  const initialData = {};
  const [signupFormValue, setSignupFormValue] = useState({});
  const [signupFormErrors, setSignupFormErrors] = useState(initialData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [signupLoader, setSignupLoader] = useState(false);
  const [signupCursor, setSignupCursor] = useState(false);
  const [signupGoogleLoader, setSignupGoogleLoader] = useState(false);
  const [signupFacebookLoader, setSignupFacebookLoader] = useState(false);
  const [signupAppleLoader, setSignupAppleLoader] = useState(false);
  const [signupResponse, setSignupResponse] = useState(null);
  const [emailVerifyModal, setEmailVerifyModal] = useState(true);
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpFromAPI, setOTPFromApi] = useState({});
  const passRef = useRef();
  const cPassRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const [otpMsg, setOTPMsg] = useState("");
  const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
  useEffect(() => {
    if (isSubmit) {
      callSignupAPI(userSignupAPI);
    }
  }, [isSubmit]);

  useEffect(() => {
    document.body.classList.add("noscroll");

    return () => {
      document.body.classList.remove("noscroll");
    };
  }, []);

  const signupMessage = (code) => {
    if (code === 400) {
      setSignupResponse(
        "Email already registered.Please Login with your Email"
      );
    }
    if (code === 401) {
      setSignupResponse(
        "Email already registered.Please Login with your Email"
      );
    }
  };

  const handleSignupFormChange = (e) => {
    if (e.target.name === "sName") {
      setSignupFormValue({ ...signupFormValue, name: e.target.value });
      setSignupFormErrors({ ...signupFormErrors, name: "" });
      setSignupResponse("");
    }
    if (e.target.name === "sEmail") {
      setSignupFormValue({ ...signupFormValue, email: e.target.value });
      setSignupFormErrors({ ...signupFormErrors, email: "" });
      setSignupResponse("");
      setOtpVerified(false);
    }
    // if (e.target.name === "sNumber") {
    //   setSignupFormValue({ ...signupFormValue, mNumber: e.target.value });
    //   setSignupFormErrors({ ...signupFormErrors, mNumber: "" });
    //   setSignupResponse("");
    // }
    if (e.target.name === "sPassword") {
      setSignupFormValue({ ...signupFormValue, password: e.target.value });
      setSignupFormErrors({ ...signupFormErrors, password: "" });
      setSignupResponse("");
    }
    if (e.target.name === "sCPassword") {
      setSignupFormValue({ ...signupFormValue, cPassword: e.target.value });
      setSignupFormErrors({
        ...signupFormErrors,
        cPassword: "",
        passMatch: "",
      });
      setSignupResponse("");
    }
  };

  const signupFormValidate = (values) => {
    const errors = {};

    const regexMNumber = /^\+(?:[0-9] ?){6,14}[0-9]$/i;
    if (!values.name) {
      errors.name = "Please enter your name";
      setIsSubmit(false);
    } else if (!values.email) {
      errors.email = "Please enter an email";
      setIsSubmit(false);
    } else if (!regexEmail.test(values.email)) {
      errors.email = "Invalid Email id";
      setIsSubmit(false);
    }
    // else if (!values.mNumber) {
    //   errors.mNumber = "Please enter your mobile number";
    // } else if (!regexMNumber.test(values.mNumber)) {
    //   errors.mNumber = "Invalid Number. try with country code +917878787878";
    //   setIsSubmit(false);
    // }
    else if (!values.password) {
      errors.password = "Please enter Password";
      setIsSubmit(false);
    } else if (!values.cPassword) {
      errors.cPassword = "Please enter Comfirm Password";
      setIsSubmit(false);
    } else if (values.password !== values.cPassword) {
      errors.passMatch = "Confirm Password is not matched";
      setIsSubmit(false);
    } else if (!otpVerified) {
      errors.email = "Verify Your Email";
    } else {
      errors.name = "";
      errors.email = "";
      // errors.mNumber = "";
      errors.password = "";
      errors.cPassword = "";
      errors.passMatch = "";
      errors.otpVerified = "";
      if (!isError) {
        setIsSubmit(true);
      }
    }
    return errors;
  };

  const callSignupAPI = (api) => {
    setSignupLoader(true);
    fetch(
      api +
        `add-user?name=${signupFormValue.name}&email=${signupFormValue.email}&phone_no=${signupFormValue.mNumber}&password=${signupFormValue.password}&login_type=regular&status=1`,
      {
        method: "POST",
        headers: new Headers({
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const { code, message, success } = res;
        const { user } = res.data;
        if (success === true) {
          // loginMessage(code);
          const originalID = {
            id: user[0].id,
            name: user[0].name,
            companyName: user[0].company_name,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          window.location.href = "/app/dashboard/start";
          console.clear();
        } else {
          signupMessage(code);
          setTimeout(() => {
            setSignupFormErrors(initialData);
            setIsSubmit(false);
          }, 2000);
          setSignupLoader(false);
          console.clear();
        }
        console.clear();
      })
      .catch((error) => {
        console.log(error);
        console.clear();
      });
  };

  const callSigninWithGoogle = () => {
    // setGoogleLoginMsg("");
    setSignupGoogleLoader(true);
    setSignupLoader(true);
    signInWithGoogle()
      .then((result) => result)
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        const googleResult = res.user;
        // console.log(googleResult);
        // console.log(res);
        if (googleResult) {
          // console.log("222:::", googleResult);
          return fetch(
            baseURL +
              `get-user?email=${googleResult.email}&name=${googleResult.displayName}&login_type=google&status=1&login_from=web`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
        }
      })
      .then((res) => res.json())
      .then((result) => {
        const { code } = result;
        if (!result.success) {
          signupMessage(code);
        }
        const { user } = result.data;
        if (result.success === true) {
          const originalID = {
            id: user[0].id,
            name: user[0].name,
            companyName: user[0].company_name,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          if (user[0].company_name) {
            window.location.pathname = "/app/dashboard/home";
          } else {
            window.location.pathname = "/app/dashboard/start";
          }
          // window.location.pathname = "/app/dashboard/start";
          // console.clear();
        } else {
          setSignupGoogleLoader(false);
          setSignupLoader(false);
          // console.clear();
        }
        setSignupGoogleLoader(false);
        setSignupLoader(false);
      })
      .catch((error) => {
        setSignupGoogleLoader(false);
        setSignupLoader(false);
        console.log(error);
        // console.clear();
      });
  };

  const callSigninWithFacebook = () => {
    // setGoogleLoginMsg("");
    setSignupFacebookLoader(true);
    setSignupLoader(true);
    signInWithFacebbok()
      .then((result) => result)
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        const FacebookResult = res.user;
        if (FacebookResult) {
          return fetch(
            baseURL +
              `get-user?email=${FacebookResult.email}&name=${FacebookResult.displayName}&login_type=facebook&status=1`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
        }
      })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result:::", result.data.user[0].id);
        const { code, success } = result;
        if (!success) {
          signupMessage(code);
        }
        const { user } = result.data;
        if (success === true) {
          // console.log(user[0].id);
          const originalID = {
            id: user[0].id,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          window.location.pathname = "/app/dashboard/start";
          // console.clear();
        } else {
          // setGoogleLoginMsg(
          //   "Email is resgistered by email. Please continue with Email"
          // );
          setSignupFacebookLoader(false);
          setSignupLoader(false);
          // console.clear();
        }
        setSignupFacebookLoader(false);
        setSignupLoader(false);
      })
      .catch((error) => {
        setSignupFacebookLoader(false);
        setSignupLoader(false);
        console.log(error);
        // console.clear();
      });
  };

  const handleSignupFormSubmit = (e) => {
    e.preventDefault();
    setSignupFormErrors(signupFormValidate(signupFormValue));
    e.target.classList.add("btnAnimation");
    setTimeout(() => {
      e.target.classList.remove("btnAnimation");
    }, 1000);
  };

  const handleEmailVerify = (identifier) => {
    if (identifier) {
      setEmailVerifyModal(true);
      setTimeout(() => {
        const { name, email, password, cPassword } = signupFormValue;
        if (name !== null && name !== undefined) nameRef.current.value = name;
        else nameRef.current.value = "";
        if (email !== null && email !== undefined)
          emailRef.current.value = email;
        if (password !== null && password !== undefined)
          passRef.current.value = password;
        if (cPassword !== null && cPassword !== undefined)
          cPassRef.current.value = cPassword;
      }, 0);
    } else {
      setEmailVerifyModal(false);
    }
    // console.log("lomde data ", signupFormValue);
  };

  const handleOTPVerify = async (e) => {
    // console.log(signupFormValue.email);
    const res = await fetch(baseURL + `signup-otp`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${signupFormValue.email}`,
    });
    const data = await res.json();
    const { success } = data;
    if (success) {
      // console.log(data);
      setOTPFromApi({ otp: data.data.otp });
    }
  };

  const handleOTPMatch = (otp) => {
    if (otp.length == 4) {
      // console.log(typeof otp);
      if (Number(otp) === otpFromAPI.otp) {
        setOtpVerified(true);
        handleEmailVerify(true);
        setSignupFormErrors({ ...signupFormErrors, email: "" });
      } else {
        setOTPMsg("OTP is incorrect!");
      }
    } else {
      setOTPMsg("OTP is incorrect!");
    }
  };

  const handleChangeOTP = (element, index) => {
    if (isNaN(element.value)) return false;

    setOTP([
      ...otp.map((d, idx) => {
        return idx === index ? element.value : d;
      }),
    ]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <section
      className="signupModal signupModal-section flex flex-jcc flex-aic"
      onClick={() => {
        closeModal(false);
      }}
    >
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="signupModalDiv"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* start */}

        {emailVerifyModal ? (
          <>
            <div className="signupModalDiv-header flex flex-jcsb flex-aic">
              <p>Sign up</p>
              <div
                className="signupModalDiv-header-closebtn"
                onClick={() => {
                  closeModal(false);
                }}
              >
                <span></span>
                <span></span>
              </div>
            </div>
            <div style={{ marginTop: "3.5rem" }}>
              <form>
                <div className="signupModalDiv-adiv-name signupModalDiv-adiv flex flex-aic">
                  <input
                    type="text"
                    name="sName"
                    placeholder="Full Name*"
                    onChange={handleSignupFormChange}
                    ref={nameRef}
                  ></input>
                </div>
                <p className="signupModalDiv-adiv-name-errorMsg">
                  {signupFormErrors.name}
                </p>
                <div className="signupModalDiv-adiv-email signupModalDiv-adiv flex flex-aic">
                  <input
                    type="email"
                    name="sEmail"
                    placeholder="Email*"
                    onChange={handleSignupFormChange}
                    ref={emailRef}
                  ></input>
                  <button
                    className={
                      otpVerified
                        ? "signupModalDiv-adiv-email-verifyBtn active"
                        : "signupModalDiv-adiv-email-verifyBtn"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (signupFormValue.email) {
                        if (regexEmail.test(signupFormValue.email)) {
                          handleOTPVerify();
                          handleEmailVerify(false);
                        }
                      }
                    }}
                    disabled={otpVerified ? true : false}
                  >
                    {otpVerified ? (
                      <>
                        <i
                          className="bx bxs-badge-check"
                          style={{ color: "green", fontSize: "20px" }}
                        ></i>
                      </>
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
                <p className="signupModalDiv-adiv-mail-errorMsg">
                  {signupFormErrors.email}
                </p>
                {/* <p className="signupModalDiv-adiv-mail-errorMsg">
                  {signupFormErrors.otpVerified}
                </p> */}
                {/* <div className="signupModalDiv-adiv-number signupModalDiv-adiv flex flex-aic">
                  <input
                    type="text"
                    name="sNumber"
                    placeholder="Phone Number*"
                    onChange={handleSignupFormChange}
                  ></input>
                </div>
                <p className="signupModalDiv-adiv-number-errorMsg">
                  {signupFormErrors.mNumber}
                </p> */}
                <div className="signupModalDiv-adiv-password signupModalDiv-adiv flex flex-aic">
                  <input
                    ref={passRef}
                    type="password"
                    name="sPassword"
                    placeholder="Password*"
                    onChange={handleSignupFormChange}
                  ></input>
                </div>
                <p className="signupModalDiv-adiv-password-errorMsg">
                  {signupFormErrors.password}
                </p>
                <div className="signupModalDiv-adiv-cPassword signupModalDiv-adiv flex flex-aic">
                  <input
                    ref={cPassRef}
                    type="password"
                    name="sCPassword"
                    placeholder="Confirm Password*"
                    onChange={handleSignupFormChange}
                  ></input>
                </div>
                <p className="signupModalDiv-adiv-cPassword-errorMsg">
                  {signupFormErrors.cPassword}
                </p>
                <p className="signupModalDiv-adiv-passMatch-errorMsg">
                  {signupFormErrors.passMatch}
                </p>
                <p className="signupModalDiv-adiv-signupRes">
                  {signupResponse}
                </p>
                <div className={"signupModalDiv-adiv-lbtn"}>
                  <button
                    onClick={handleSignupFormSubmit}
                    disabled={signupLoader && true}
                    className={signupLoader ? "not-allowed" : ""}
                  >
                    {signupLoader && (
                      <lord-icon
                        className="btn-loader inactive"
                        src="https://cdn.lordicon.com/xjovhxra.json"
                        trigger="loop"
                        colors="primary:#ffffff,secondary:#242424"
                        stroke="80"
                        scale="75"
                        style={{
                          width: "25px",
                          height: "25px",
                          position: "absolute",
                          left: "20%",
                        }}
                      ></lord-icon>
                    )}
                    Create Account
                  </button>
                </div>
              </form>
            </div>
            <div
              className="signupModalDiv-or flex flex-jcc flex-aic"
              style={{ marginTop: "1rem" }}
            >
              or
            </div>
            <div className="flex flex-aic">
              <div
                className={
                  signupLoader
                    ? "signupModalDiv-google signupModalDiv-ldiv not-allowed flex flex-jcc"
                    : "signupModalDiv-google signupModalDiv-ldiv flex flex-aic flex-jcc"
                }
                onClick={() => (signupLoader ? "" : callSigninWithGoogle())}
              >
                {signupGoogleLoader ? (
                  <lord-icon
                    className="btn-loader inactive"
                    src="https://cdn.lordicon.com/xjovhxra.json"
                    trigger="loop"
                    colors="primary:#ff6347,secondary:#242424"
                    stroke="80"
                    scale="75"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  ></lord-icon>
                ) : (
                  <>
                    <img src="https://img.icons8.com/color/30/000000/google-logo.png" />{" "}
                    Continue with Google
                  </>
                )}
              </div>
              {/* <div
                className={
                  signupLoader
                    ? "signupModalDiv-facebook signupModalDiv-ldiv not-allowed"
                    : "signupModalDiv-facebook signupModalDiv-ldiv"
                }
                style={{ cursor: "not-allowed" }}
                // onClick={() => (signupLoader ? "" : callSigninWithFacebook())}
              >
                {signupFacebookLoader ? (
                  <lord-icon
                    className="btn-loader inactive"
                    src="https://cdn.lordicon.com/xjovhxra.json"
                    trigger="loop"
                    colors="primary:#ff6347,secondary:#242424"
                    stroke="80"
                    scale="75"
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  ></lord-icon>
                ) : (
                  <img src="https://img.icons8.com/fluency/30/000000/facebook-new.png" />
                )}
              </div> */}
              {/* <div
                className={
                  signupLoader
                    ? "signupModalDiv-ios signupModalDiv-ldiv not-allowed"
                    : "signupModalDiv-ios signupModalDiv-ldiv"
                }
                style={{ cursor: "not-allowed" }}
                // onClick={() => (signupLoader ? "" : callSigninWithGoogle())}
              >
                <img src="https://img.icons8.com/ios-filled/30/000000/mac-os.png" />
              </div> */}
            </div>
            <hr
              style={{
                border: "0.2px solid rgb(207, 207, 207)",
                marginTop: "1.5rem",
              }}
            />
            <div className="signupModalDiv-signupRedirect">
              Already have an account?{" "}
              <span
                onClick={() => {
                  closeModal(false);
                  openLoginmodal(true);
                }}
              >
                Log in
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="emailVerifyModal">
              <p className="emailVerifyModal-header">
                Enter OTP to verify your
              </p>
              <div className="emailVerifyModal-adiv flex flex-jcsb">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-input"
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => {
                        handleChangeOTP(e.target, index);
                        setOTPMsg("");
                      }}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                    ></input>
                  );
                })}
              </div>
              <div className="emailVerifyModal-msg">
                <p>{otpMsg}</p>
              </div>
              <div className="emailVerifyModal-btns flex flex-jcsb">
                <button
                  className="emailVerifyModal-btns-cancel"
                  onClick={(e) => {
                    // console.log("lomde data on close ", signupFormValue);
                    setOTP([...otp.map((v) => "")]);
                    setOTPMsg("");
                    handleEmailVerify(true);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="emailVerifyModal-btns-verify"
                  onClick={() => {
                    // otp.join("");
                    handleOTPMatch(otp.join(""));
                    setOTP([...otp.map((v) => "")]);
                    // console.log(otp);
                  }}
                >
                  Verify
                </button>
              </div>
            </div>
          </>
        )}
        {/* end */}
      </motion.div>
    </section>
  );
};

export default SignupModal;
