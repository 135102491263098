import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./LegalInformationContent.scss";
import legalData from "./legalInformationContent.json";

const LegalInformationContent = () => {
  const path = window.location.pathname;
  const headPath = path + "/header";
  const [que, setQue] = useState(null);
  const [headDetails, setHeadDetails] = useState(null);

  useEffect(() => {
    const checkKey = () => {
      legalData.map((e) => {
        for (var key in e) {
          if (key === path) {
            setQue(e[key]);
          }
          if (key === headPath) {
            setHeadDetails(e[key]);
          }
        }
      });
    };
    checkKey();
  }, []);

  return (
    <>
      <div className="LegalInformationContent LegalInformationContent-section">
        <div className="LegalInformationContent-header">
          <h1>{headDetails && headDetails[0].header}</h1>
          <p>{headDetails && headDetails[0].discription}</p>
        </div>
        <div className="LegalInformationContent-details">
          <ul>
            {que
              ? que.map((que, index) => {
                  return (
                    <>
                      <div className="LegalInformationContent-details-title">
                        <h3>{que.title}</h3>
                      </div>
                      <div className="LegalInformationContent-details-subtitle">
                        <h3>{que.subTitle}</h3>
                      </div>
                      <div className="LegalInformationContent-details-discription">
                        <p>{que.discription}</p>
                      </div>
                    </>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LegalInformationContent;
