import { decryptData } from "../adminpanel/Util";

export const getUserDetails = () => {
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const idfliu = localStorage.getItem("idfliu");
  const idfliuSession = sessionStorage.getItem("idfliu");

  if (idfliu) {
    const userDetails = decryptData(idfliu, salt);
    return userDetails;
  } else if (idfliuSession) {
    const userDetails = decryptData(idfliuSession, salt);
    return userDetails;
  } else {
    window.location.pathname = "/";
  }
};
