import React from "react";
import "./AdminNavbar.scss";

const AdminNavbar = ({ auUserData }) => {
  const logoutHandler = () => {
    if (localStorage.getItem("auKey")) {
      localStorage.removeItem("auKey");
    } else {
      sessionStorage.removeItem("auKey");
    }
    window.location.pathname = "/admin/login";
  };

  return (
    <>
      <div className="adminNavbar flex flex-jcsb">
        <div className="adminNavbar-auName flex flex-aic">
          <h1>Welcome back, {auUserData.uName}</h1>
        </div>
        <div className="adminNavbar-profile flex flex-aic flex-jcc">
          <i className="bx bx-user"></i>
          <div className="adminNavbar-profile-dropdown">
            <ul>
              <li onClick={logoutHandler}>Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;
