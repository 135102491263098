import { encryptData } from "../adminpanel/Util";
import { getUserDetails } from "./getUserDetails";

export const setUserDetails = (key, value) => {
  const userDetails = getUserDetails();
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  userDetails[key] = value;

  const encryptedData = encryptData(userDetails, salt);

  const idfliu = localStorage.getItem("idfliu");
  const idfliuSession = sessionStorage.getItem("idfliu");

  if (idfliu) {
    localStorage.setItem("idfliu", encryptedData);
  } else if (idfliuSession) {
    sessionStorage.setItem("idfliu", encryptedData);
  } else {
    window.location.pathname = "/";
  }
};

export const setAllUserDetails = (details) => {
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const encryptedData = encryptData({ ...details }, salt);

  const idfliu = localStorage.getItem("idfliu");
  const idfliuSession = sessionStorage.getItem("idfliu");

  if (idfliu) {
    localStorage.setItem("idfliu", encryptedData);
  } else if (idfliuSession) {
    sessionStorage.setItem("idfliu", encryptedData);
  } else {
    window.location.pathname = "/";
  }
};
