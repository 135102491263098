import React, { useEffect, useState, useRef } from "react";
import "./LoginModal.scss";
import { motion } from "framer-motion";
import { userLoginAPI } from "../../api/userAuth/userLogin";
import { encryptData } from "../../adminpanel/Util";
import { signInWithGoogle, signInWithFacebbok } from "../firebase/Firebase";
import { baseURL } from "../../api/baseURL/BaseURL";

const LoginModal = ({ closeModal, openSignupModal, openForgotPasswordModal }) => {
  const initialData = {};
  const [loginType, setLoginType] = useState(null);
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const [loginFormValue, setLoginFormValue] = useState({});
  const [loginFormErrors, setLoginFormErrors] = useState(initialData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [loginLoader, setLoginLoader] = useState(false);
  const [loginGoogleLoader, setLoginGoogleLoader] = useState(false);
  const [loginFacebookLoader, setLoginFacebookLoader] = useState(false);
  const [googleLoginMsg, setGoogleLoginMsg] = useState("");
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const rememberMeInputRef = useRef(null);

  useEffect(() => {
    if (isSubmit) {
      callLoginAPI(userLoginAPI);
    }
  }, [isSubmit]);

  useEffect(() => {
    document.body.classList.add("noscroll");

    return () => {
      document.body.classList.remove("noscroll");
    };
  }, []);

  const loginMessage = (code) => {
    if (code === 400) {
      setLoginResponse("Password is incorrect");
    } else if (code === 401) {
      setLoginResponse("Email is not registered");
    }
  };

  const handleLoginFormChange = (e) => {
    if (e.target.name === "lEmail") {
      setLoginFormValue({ ...loginFormValue, email: e.target.value });
      setLoginFormErrors({ ...loginFormErrors, email: "" });
      setLoginResponse("");
    }
    if (e.target.name === "lPassword") {
      setLoginFormValue({ ...loginFormValue, password: e.target.value });
      setLoginFormErrors({ ...loginFormErrors, password: "" });
      setLoginResponse("");
    }
  };

  const loginFormValidate = (values) => {
    const errors = {};
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    if (!values.email) {
      errors.email = "Please enter an email";
      setIsSubmit(false);
    } else if (!regexEmail.test(values.email)) {
      errors.email = "Invalid Email id";
      setIsSubmit(false);
    } else if (!values.password) {
      errors.password = "Please enter Password";
      setIsSubmit(false);
    } else {
      errors.password = "";
      errors.email = "";
      if (!isError) {
        setIsSubmit(true);
      }
    }
    return errors;
  };

  const callLoginAPI = (api) => {
    setLoginLoader(true);
    fetch(
      api +
        `get-user?email=${loginFormValue.email}&password=${loginFormValue.password}&login_type=regular&login_from=web`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        const { code, message, success, data } = res;

        if (success === true) {
          const { user } = data;
          const originalID = {
            id: user[0].id,
            name: user[0].name,
            companyName: user[0].company_name,
            currency: user[0].currency,
            plan: user[0].plan,
            email: user[0].email,
            phone: user[0].phone_no,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          if (rememberMeInputRef.current.checked) {
            localStorage.setItem("idfliu", encryptedData);
          } else {
            sessionStorage.setItem("idfliu", encryptedData);
          }
          if (user[0].company_name) {
            window.location.pathname = "/app/dashboard/templates";
          } else {
            window.location.pathname = "/app/dashboard/start";
          }
          console.clear();
        } else {
          loginMessage(code);
          setTimeout(() => {
            setLoginFormErrors(initialData);
            setIsSubmit(false);
          }, 2000);
          setLoginLoader(false);
          console.clear();
        }
        console.clear();
      })
      .catch((error) => {
        console.log(error);
        console.clear();
      });
  };

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();
    setLoginFormErrors(loginFormValidate(loginFormValue));
    e.target.classList.add("btnAnimation");
    setTimeout(() => {
      e.target.classList.remove("btnAnimation");
    }, 800);
  };

  const callSigninWithGoogle = () => {
    setGoogleLoginMsg("");
    setLoginLoader(true);
    setLoginGoogleLoader(true);
    signInWithGoogle()
      .then((result) => result)
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        const googleResult = res.user;
        if (googleResult) {
          return fetch(
            baseURL +
              `get-user?email=${googleResult.email}&name=${googleResult.displayName}&login_type=google&status=1&login_from=web`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
        }
      })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result:::", result.data.user[0].id);
        if (result.success === true) {
          const { user } = result.data;
          const originalID = {
            id: user[0].id,
            name: user[0].name,
            companyName: user[0].company_name,
            currency: user[0].currency,
            plan: user[0].plan,
            email: user[0].email,
            phone: user[0].phone_no,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          if (user[0].company_name) {
            window.location.pathname = "/app/dashboard/home";
          } else {
            window.location.pathname = "/app/dashboard/start";
          }
          // window.location.pathname = "/app/dashboard/home";
          console.clear();
        } else {
          setGoogleLoginMsg(
            "Email is resgistered by email. Please continue with Email"
          );
          setLoginLoader(false);
          setLoginGoogleLoader(false);
          console.clear();
        }
        setLoginLoader(false);
        setLoginGoogleLoader(false);
      })
      .catch((error) => {
        setLoginLoader(false);
        setLoginGoogleLoader(false);
        console.log(error);
        console.clear();
      });
  };

  const callSigninWithFacebook = () => {
    setLoginLoader(true);
    setLoginFacebookLoader(true);
    signInWithFacebbok()
      .then((result) => result)
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        const facebookResult = res.user;
        if (facebookResult) {
          return fetch(
            baseURL +
              `get-user?email=${facebookResult.email}&name=${facebookResult.displayName}&login_type=facebook&status=1`,
            {
              method: "GET",
              headers: {
                accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result:::", result.data.user[0].id);
        if (result.success === true) {
          const { user } = result.data;
          const originalID = {
            id: user[0].id,
          };
          const salt =
            process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          window.location.pathname = "/app/dashboard/home";
          console.clear();
        } else {
          setGoogleLoginMsg(
            "Email is resgistered by email. Please continue with Email"
          );
          setLoginLoader(false);
          setLoginFacebookLoader(false);
          console.clear();
        }
        setLoginLoader(false);
        setLoginFacebookLoader(false);
      })
      .catch((error) => {
        setLoginLoader(false);
        setLoginFacebookLoader(false);
        console.log(error);
        console.clear();
      });
  };

  return (
    <section
      className="loginModal loginModal-section flex flex-jcc flex-aic"
      onClick={() => {
        closeModal(false);
      }}
    >
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="loginModalDiv"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="loginModalDiv-header flex flex-jcsb flex-aic">
          {continueWithEmail && (
            <img
              src="https://img.icons8.com/windows/30/000000/left.png"
              onClick={() => {
                setContinueWithEmail(false);
              }}
              style={{ cursor: "pointer" }}
            />
          )}
          <p>Log in</p>
          <div
            className="loginModalDiv-header-closebtn"
            onClick={() => {
              closeModal(false);
            }}
          >
            <span></span>
            <span></span>
          </div>
        </div>
        {continueWithEmail ? (
          <div style={{ marginTop: "1.5rem" }}>
            <div className="loginModalDiv-adiv-loginIcon flex flex-jcc flex-aic">
              <img src="https://img.icons8.com/clouds/120/ff6347/sent.png" />
              {/* <img src="https://img.icons8.com/bubbles/100/000000/sent.png" /> */}
            </div>
            <form>
              <div className="loginModalDiv-adiv-mail loginModalDiv-adiv flex flex-aic">
                <input
                  type="email"
                  name="lEmail"
                  placeholder="Email"
                  onChange={handleLoginFormChange}
                  ref={emailRef}
                ></input>
              </div>
              <p className="loginModalDiv-adiv-mail-errorMsg">
                {loginFormErrors.email}
              </p>
              <div className="loginModalDiv-adiv-password loginModalDiv-adiv flex flex-aic">
                <input
                  type="password"
                  name="lPassword"
                  placeholder="Password"
                  onChange={handleLoginFormChange}
                  ref={passwordRef}
                ></input>
              </div>
              <p className="loginModalDiv-adiv-password-errorMsg">
                {loginFormErrors.password}
              </p>
              <p className="loginModalDiv-adiv-loginMsg">{loginResponse}</p>
              <div className="loginModalDiv-adiv-rememberMe flex flex-aic flex-jcsb">
                <span>
                  <input type="checkbox" ref={rememberMeInputRef}></input>Remember
                  Me
                </span>
                <span className="loginModalDiv-adiv-forgotPassword" onClick={()=>{closeModal(false); openForgotPasswordModal(true)}}>Forgot Password?</span>
              </div>
              <div
                className="loginModalDiv-adiv-lbtn"
                // onClick={handleBtnAnimation}
              >
                <button
                  onClick={handleLoginFormSubmit}
                  disabled={loginLoader && true}
                >
                  {loginLoader && (
                    <lord-icon
                      className="btn-loader inactive"
                      src="https://cdn.lordicon.com/xjovhxra.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#242424"
                      stroke="80"
                      scale="75"
                      style={{
                        width: "25px",
                        height: "25px",
                        position: "absolute",
                        left: "30%",
                      }}
                    ></lord-icon>
                  )}
                  Login
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div style={{ marginTop: "3rem" }}>
            <div
              className={
                loginLoader
                  ? "loginModalDiv-email loginModalDiv-ldiv not-allowed flex flex-aic"
                  : "loginModalDiv-email loginModalDiv-ldiv flex flex-aic"
              }
              onClick={() => (loginLoader ? "" : setContinueWithEmail(true))}
            >
              <img src="https://img.icons8.com/fluency-systems-filled/30/ff6347/new-post.png" />
              <span className="loginModalDiv-ldiv-title">
                Continue with Email
              </span>
            </div>
            <p className="loginModalDiv-adiv-gloginMsg">{googleLoginMsg}</p>
            <div className="loginModalDiv-or flex flex-jcc flex-aic">or</div>
            <div
              className={
                loginLoader
                  ? "loginModalDiv-google loginModalDiv-ldiv not-allowed flex flex-aic"
                  : "loginModalDiv-google loginModalDiv-ldiv flex flex-aic"
              }
              onClick={() => (loginLoader ? "" : callSigninWithGoogle())}
            >
              {loginGoogleLoader ? (
                <lord-icon
                  className="btn-loader inactive"
                  src="https://cdn.lordicon.com/xjovhxra.json"
                  trigger="loop"
                  colors="primary:#ff6347,secondary:#242424"
                  stroke="80"
                  scale="75"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "15px",
                    // position: "absolute",
                    // left: "20%",
                  }}
                ></lord-icon>
              ) : (
                <img src="https://img.icons8.com/color/30/000000/google-logo.png" />
              )}
              <span className="loginModalDiv-ldiv-title">
                Continue with Google
              </span>
            </div>
            {/* <div
              className={
                loginLoader
                  ? "loginModalDiv-facebook loginModalDiv-ldiv not-allowed flex flex-aic"
                  : "loginModalDiv-facebook loginModalDiv-ldiv flex flex-aic"
              }
              style={{ cursor: "not-allowed" }}
              // onClick={() => (loginLoader ? "" : callSigninWithFacebook())}
            >
              {loginFacebookLoader ? (
                <lord-icon
                  className="btn-loader inactive"
                  src="https://cdn.lordicon.com/xjovhxra.json"
                  trigger="loop"
                  colors="primary:#ff6347,secondary:#242424"
                  stroke="80"
                  scale="75"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "15px",
                    // position: "absolute",
                    // left: "20%",
                  }}
                ></lord-icon>
              ) : (
                <img src="https://img.icons8.com/fluency/30/000000/facebook-new.png" />
              )}
              <span className="loginModalDiv-ldiv-title">
                Continue with Facebook
              </span>
            </div>
            <div
              className="loginModalDiv-apple loginModalDiv-ldiv flex flex-aic"
              style={{ cursor: "not-allowed" }}
            >
              <img src="https://img.icons8.com/ios-filled/30/000000/mac-os.png" />
              <span className="loginModalDiv-ldiv-title">
                Continue with Apple
              </span>
            </div> */}
            <hr style={{ border: "0.2px solid rgb(207, 207, 207)" }} />
            <div className="loginModalDiv-signupRedirect">
              New to Catalogmaker?{" "}
              <span
                onClick={() => {
                  closeModal(false);
                  openSignupModal(true);
                }}
              >
                Create account
              </span>
            </div>
          </div>
        )}
      </motion.div>
    </section>
  );
};

export default LoginModal;
