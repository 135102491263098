import React, { useEffect, useRef, useState } from "react";
import "./DashboardIndividualProductEdit.scss";
import w4 from "../../../images/prducts/w4.jpg";
import { baseURL, IMAGE_PRODUCTS_URL } from "../../api/baseURL/BaseURL";
import { motion } from "framer-motion";
const DashboardIndividualProductEditModal = ({
  closeModal,
  indiProductData,
  callPFSCAPI,
  catalogID,
  trigger,
}) => {
  const [inProductData, setInProductData] = useState(indiProductData);
  const nameRef = useRef(null);
  const priceRef = useRef(null);
  const productLinkRef = useRef(null);
  const [activeStockClass, setActiveStockClass] = useState(
    inProductData.in_stock
  );
  const [name, setName] = useState(inProductData.name);
  const [price, setPrice] = useState(inProductData.amount);
  const [productLink, setProductLink] = useState(inProductData.product_link);
  const [testImg, setTestImg] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editProductData, setEditProductData] = useState({
    name: inProductData.name,
    price: inProductData.amount,
    stock: activeStockClass,
  });
  // const [fileImage, setFileImage] = useState < File > null;
  // console.log("datatatatat dekhhhh", inProductData);
  useEffect(() => {
    if (inProductData.name) nameRef.current.value = inProductData.name;
    else nameRef.current.value = "";

    if (indiProductData.amount) priceRef.current.value = inProductData.amount;
    else {
      priceRef.current.value = "";
      setPrice("");
    }

    if (indiProductData.product_link)
      productLinkRef.current.value = inProductData.product_link;
    else {
      productLinkRef.current.value = "";
      setProductLink("");
    }
  }, []);

  const ImageFileselectHandler = (event) => {
    setError(null);
    setImgData(event.target.files[0]);
    var src = URL.createObjectURL(event.target.files[0]);
    setTestImg(src);

    // console.log("Imagggggeeee::", src);

    // FileReader api
    // const reader = new FileReader();
    // reader.readAsDataURL(event.target.files[0]);
  };

  const callEditProductAPI = async (id) => {
    // console.log("dddddddd:::", imgData);
    setIsLoading(true);
    if (imgData) {
      const fData = new FormData();
      fData.append("name", name);
      fData.append("amount", price);
      fData.append("in_stock", activeStockClass);
      fData.append("images", imgData);
      fData.append("product_link", productLink);
      // console.log("name it is:::", imgData.name);
      const res = await fetch(baseURL + `update-product/${id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        body: fData,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log("resssss::", data);
        closeModal(false);
        trigger((c) => c + 1);
        callPFSCAPI(catalogID);
      } else {
        setError(data?.message);
      }
    } else {
      const res = await fetch(baseURL + `update-product/${id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body:
          "name=" +
          name +
          "&amount=" +
          price +
          "&in_stock=" +
          activeStockClass +
          "&product_link=" +
          productLink,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log(typeof activeStockClass);
        // console.log(data);
        closeModal(false);
        callPFSCAPI(catalogID);
      } else {
        setError(data?.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <section className="dashboardIndividualProductEdit dashboardIndividualProductEdit-section flex flex-aic flex-jcc">
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="dashboardIndividualProductEdit-item"
      >
        <div
          className="dashboardIndividualProductEdit-item-closebtn"
          onClick={() => {
            closeModal(false);
          }}
        >
          <span></span>
          <span></span>
        </div>
        {/*  */}
        {/* <img src={testImg}></img> */}
        {/*  */}
        <div className="dashboardIndividualProductEdit-item-imgbox">
          <img
            src={testImg || IMAGE_PRODUCTS_URL + `/${inProductData.images}`}
          ></img>
          <div className="dashboardIndividualProductEdit-item-imgbox-selectImg">
            <label for="file-upload" className="custom-file-upload">
              <i class="bx bx-plus"></i>
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={ImageFileselectHandler}
            ></input>
          </div>
        </div>
        <div className="dashboardIndividualProductEdit-item-details">
          <form>
            <div className="dashboardIndividualProductEdit-item-details-d">
              <input
                type="text"
                placeholder="Product Name"
                ref={nameRef}
                onChange={(e) => {
                  setName(e.target.value);
                  // console.log(name);
                }}
              ></input>
              <input
                type="text"
                placeholder="Price"
                ref={priceRef}
                onChange={(e) => {
                  setPrice(e.target.value);
                  // console.log(price);
                }}
              ></input>
              <input
                type="text"
                placeholder="Product Link ( amazon, flipkart, etc )"
                ref={productLinkRef}
                onChange={(e) => {
                  setProductLink(e.target.value);
                  // console.log(price);
                }}
              ></input>
            </div>
            <div className="dashboardIndividualProductEdit-item-details-c flex flex-jcc flex-aic">
              <span className="stockBtn flex">
                <span
                  className={
                    activeStockClass === "1" ? "stockin active" : "stockin"
                  }
                  onClick={() => {
                    setActiveStockClass("1");
                  }}
                >
                  Stock In
                </span>
                <span
                  className={
                    activeStockClass === "0" ? "stockout active" : "stockout"
                  }
                  onClick={() => {
                    setActiveStockClass("0");
                  }}
                >
                  Stock Out
                </span>
              </span>
              {/* <input type="checkbox" className="checkbox"></input> Stock In */}
              {/* <input type="checkbox" className="checkbox"></input> Stock Out */}
            </div>
            <div className="dashboardIndividualProductEdit-item-details-error">
              {error}
            </div>
            <div className="dashboardIndividualProductEdit-item-details-btn">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  callEditProductAPI(inProductData.id);
                }}
                disabled={isLoading}
              >
                UPDATE PRODUCT
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </section>
  );
};

export default DashboardIndividualProductEditModal;
