import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import "./DashboardLogout.scss";
import { motion } from "framer-motion";

const DashboardLogout = (props) => {
  const id = useOutletContext();
  // console.log(id);
  const callCancleBtn = () => {
    window.location.pathname = "/app/dashboard/home";
  };
  const callLogoutBtn = () => {
    if (id.storage === "local") {
      localStorage.removeItem("idfliu");
      window.location.pathname = "/";
    }
    if (id.storage === "session") {
      sessionStorage.removeItem("idfliu");
      window.location.pathname = "/";
    }
  };
  return (
    <section className="dashboardLogout dashboardLogout-section">
      <div className="dashboardLogout-Wrapper flex flex-aic flex-jcc">
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          className="dashboardLogout-modal"
        >
          <p className="dashboardLogout-modal-header">Come back soon!</p>
          <p className="dashboardLogout-modal-subheader">
            Are you sure you want to Log out?
          </p>
          <div className="dashboardLogout-modal-btns flex flex-jcsb">
            <button
              className="dashboardLogout-modal-btns-cancel"
              onClick={callCancleBtn}
            >
              {/* <Link reloadDocument={true} to="/app/dashboard/home"> */}
              Cancel
              {/* </Link> */}
            </button>
            <button
              className="dashboardLogout-modal-btns-yes"
              onClick={callLogoutBtn}
            >
              Yes
            </button>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default DashboardLogout;
