import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../api/baseURL/BaseURL";
import { motion } from "framer-motion";
import "./DashboardCatalogNameModal.scss";
import { getUserDetails } from "../../utils/getUserDetails";

const DashboardCatalogNameModal = ({
  templateData,
  closeModal,
  userData,
  catalogCount,
  setIsPlanModal,
}) => {
  const [indiTemplateData, setIndiTemplateData] = useState(templateData);
  const [catalogName, setCatalogName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const catalogNameRef = useRef(null);
  const navigate = useNavigate();
  const { setNewCreatedCatalogID, setNewCreatedCatalogName } = userData;
  // console.log("ddddddddddddddddddd", templateData);

  const callCreateCatalogAPI = async () => {
    setIsLoading(true);
    if (catalogName !== null) {
      const res = await fetch(baseURL + `add-catalog`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `user_id=${userData.id}&name=${catalogName}&status=1&template_id=${indiTemplateData.id}`,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log(data);
        setIsLoading(false);
        closeModal(false);
        setNewCreatedCatalogID(data.data.catalog.id);
        setNewCreatedCatalogName(data.data.catalog.name);
        // window.location.pathname = "/app/dashboard/products";
        navigate("/app/dashboard/products");
      }
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    const userDetails = getUserDetails();
    if (userDetails?.plan) {
      callCreateCatalogAPI();
      return;
    }else{
      if (catalogCount >= 2) {
        closeModal(false);
        setIsPlanModal(true);
        return;
      }else{
        callCreateCatalogAPI();
      }
    }
  };

  return (
    <section className="dashboardCatalogNameModal dashboardCatalogNameModal-section">
      <div className="dashboardCatalogNameModal-wrapper flex flex-aic flex-jcc">
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          className="dashboardCatalogNameModal-modal"
        >
          <p className="dashboardCatalogNameModal-modal-title">
            {indiTemplateData.template_name}
          </p>
          <form>
            <div className="dashboardCatalogNameModal-modal-inputbox">
              <input
                type="text"
                placeholder="Catalog Name"
                ref={catalogNameRef}
                onChange={(e) => {
                  setCatalogName(e.target.value);
                  // console.log(catalogName);
                }}
              ></input>
            </div>
            <div className="dashboardCatalogNameModal-modal-btns flex flex-jcsb">
              <button
                className="dashboardCatalogNameModal-modal-btns-cancel"
                onClick={() => closeModal(false)}
              >
                CANCEL
              </button>
              <button
                className="dashboardCatalogNameModal-modal-btns-create"
                onClick={(e) => handleCreate(e)}
              >
                CREATE
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default DashboardCatalogNameModal;
