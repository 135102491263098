import React from "react";
import "./Operation.scss";
import OperationS1 from "./OperationS1";
import OperationS2 from "./OperationS2";
import OperationS3 from "./OperationS3";
const Operation = () => {
  return (
    <>
      <section className="operation operation-section">
        <div className="container">
          <div className="operation-header">
            <h1 className="flex flex-aic flex-jcc">
              Create your catalog in NO Time
              <lord-icon
                src="https://cdn.lordicon.com/osvvqecf.json"
                trigger="loop"
                colors="outline:#121331,primary:#f24c00,secondary:#ebe6ef,tertiary:#4bb3fd"
                stroke="80"
                scale="75"
                style={{
                  width: "60px",
                  height: "60px",
                  margin: "0 1.2rem",
                }}
              ></lord-icon>
            </h1>
            <p>
              Create and get your PDF catalog, It's as easy as 1-2-3. Catalog
              contains your product pictures, product details, brand name,
              social media links
            </p>
          </div>
          <OperationS1 />
        </div>
      </section>
      <section
        className="operation2 operation2-section"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <OperationS2 />
        </div>
      </section>
      <section
        className="operation3 operation2-section"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <div className="container">
          <OperationS3 />
        </div>
      </section>
    </>
  );
};

export default Operation;
