import React, { useState } from "react";
import "./DashboardPreviewPdf.scss";
import { motion } from "framer-motion";
import pdfloader from "./../../../lottie/loadingPdf.json";
import Lottie from "react-lottie";
const DashboardPreviewPdf = ({
  pdfPreviewModal,
  templatePhpLink,
  catalogID,
  userID,
}) => {
  const [loader, setLoader] = useState(true);
  const defaultOptions = {
    animationData: pdfloader,
    loop: true,
    autoplay: true,
    height: 100,
    width: 300,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  //   const { pdfLoad } = useLottie(defaultOptions);
  return (
    <section
      className="dashboardPreviewPdf dashboardPreviewPdf-section flex flex-jcfe"
      onClick={() => {
        pdfPreviewModal(false);
      }}
    >
      {/* hello */}
      <motion.div
        className="dashboardPreviewPdf-modal"
        initial={
          {
            //   scale: 0,
          }
        }
        animate={{
          //   scale: 1,
          width: 800,
          transition: {
            duration: 0.4,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {loader ? (
          <>
            <motion.div
              animate={{
                //   scale: 1,
                width: 400,
                transition: {
                  duration: 0.6,
                },
              }}
              className="dashboardPreviewPdf-modal-loader"
            >
              <Lottie options={defaultOptions}></Lottie>
              {/* {pdfLoad} */}
            </motion.div>
          </>
        ) : (
          <>
            <div
              onClick={() => {
                pdfPreviewModal(false);
              }}
              className="dashboardPreviewPdf-modal-closebtn"
            >
              Close
            </div>
          </>
        )}

        <iframe
          src={`${templatePhpLink}?user_id=${userID.id}&catalog_id=${catalogID}#zoom=73%`}
          //   type="application/pdf"
          width="100%"
          frameBorder="0"
          onLoad={() => setLoader(false)}
          //   height="920px"
        ></iframe>
      </motion.div>
    </section>
  );
};

export default DashboardPreviewPdf;
