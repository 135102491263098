import React, { useState } from "react";
import "../../sass/style.scss";
import cmlogo from "../../images/logo/catalogmaker2.png";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { ImArrowRight2 } from "react-icons/im";
import { BsLinkedin } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import LoginModal from "../auth/login/LoginModal";
import SignupModal from "../auth/signup/SignupModal";
import ForgotPasswordModal from "../auth/forgot/ForgotPasswordModal";

const Header = () => {
  const [clicked, setClicked] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openSignupModal, setOpenSignupModal] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  const ModalHandler = (i) => {
    if (i === 0) setOpenLoginModal(true);
    if (i === 1) setOpenSignupModal(true);
  };

  const menuItems = [
    // { title: "Why catalogmaker", url: "/join/newsletter" },
    { title: "Templates", url: "/templates" },
    // { title: "Pricing", url: "/join/newsletter" },
    // { title: "Resource", url: "/resource" },
    { title: "Support", url: "/support" },
  ];
  const authLinks = [
    { title: "Log in", url: "/auth/login" },
    { title: "Signup for Free", url: "/auth/signup" },
  ];
  const handleClick = () => {
    setClicked(!clicked);
  };
  return (
    <>
      <nav className="header flex">
        <div className="container flex flex-aic flex-jcsb">
          <div className="header-logolinks flex">
            <div className="header-logo">
              <a href="/">
                <img src={cmlogo} alt="create catalog free"></img>
              </a>
            </div>
            {/* nav menu for desktop */}
            <div className="header-links flex flex-aic hide-for-mobile">
              <ul className="header-links-ul flex flex-aic">
                {menuItems.map((item, index) => {
                  if (item.title === "Why catalogmaker") {
                    return (
                      <li
                        className="header-links-ul-li"
                        key={index}
                        onClick={() => setDropdown(!dropdown)}
                      >
                        <a style={{ cursor: "pointer" }}>
                          {item.title}
                          <IoIosArrowDown className="icon" />
                        </a>
                        {dropdown && <Dropdown />}
                      </li>
                    );
                  }
                  return (
                    <li key={index} className="header-links-ul-li">
                      <a href={item.url}>{item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* nav menu for desktop for auth */}
          <div className="header-auth hide-for-mobile">
            <ul className="flex">
              {authLinks.map((link, index) => {
                return (
                  <li
                    key={index}
                    onClick={(e) => {
                      ModalHandler(index);
                    }}
                    className={index === 1 ? "signup-modal" : ""}
                  >
                    <p>
                      {link.title}
                      {index === 1 ? (
                        <ImArrowRight2 className="heroButtonIcon" />
                      ) : (
                        ""
                      )}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* hamburger for mobile */}
          <p
            id="btnHamburger"
            className={
              clicked
                ? "header-hamburger open hide-for-desktop"
                : "header-hamburger hide-for-desktop"
            }
            onClick={handleClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </p>

          {/* nav menu for mobile */}
          <div
            className={
              clicked ? "header-mobile-links open" : "header-mobile-links"
            }
          >
            <ul className="header-mobile-links-ul flex">
              {menuItems.map((item, index) => {
                if (item.title === "Why catalogmaker") {
                  return (
                    <>
                      <li
                        key={index}
                        className="header-mobile-links-ul-li"
                        onClick={() => setDropdown(!dropdown)}
                      >
                        <a
                          className="header-mobile-links-ul-li-a"
                          style={{ cursor: "pointer" }}
                        >
                          {item.title}
                          {dropdown ? (
                            <IoIosArrowUp className="icon" />
                          ) : (
                            <IoIosArrowDown className="icon" />
                          )}
                        </a>
                      </li>
                      {dropdown && <DropdownMobile />}
                    </>
                  );
                }
                return (
                  <li key={index} className="header-mobile-links-ul-li">
                    <a href={item.url} className="header-mobile-links-ul-li-a">
                      {item.title}
                    </a>
                  </li>
                );
              })}
              {/* mobile auth links  */}
              {authLinks.map((link, index) => {
                return (
                  <li
                    key={index}
                    className="header-mobile-links-ul-li"
                    onClick={(e) => {
                      ModalHandler(index);
                    }}
                  >
                    <p className="header-mobile-links-ul-li-a">
                      {link.title}
                      {index === 1 ? <ImArrowRight2 /> : ""}
                    </p>
                  </li>
                );
              })}
            </ul>
            {/* mobile social links */}
            <div className="header-mobile-links-social flex flex-jcc">
              <a href="https://www.linkedin.com/in/catalog-maker-268246239">
                <BsLinkedin className="social-icon" />
              </a>
              <a href="https://twitter.com/catalogsmaker">
                <FaTwitterSquare className="social-icon" />
              </a>
              <a href="https://instagram.com/catalogmaker?igshid=YmMyMTA2M2Y=">
                <RiInstagramFill className="social-icon" />
              </a>
              <a href="https://www.facebook.com/catalogmaker.in">
                <FaFacebookSquare className="social-icon" />
              </a>
              <a href="https://www.youtube.com/channel/UC_Wn5joRnCF3j7Eq4K7_k3Q">
                <IoLogoYoutube className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </nav>
      {openLoginModal && (
        <LoginModal
          closeModal={setOpenLoginModal}
          openSignupModal={setOpenSignupModal}
          openForgotPasswordModal={setOpenForgotPasswordModal}
        />
      )}
      {openSignupModal && (
        <SignupModal
          closeModal={setOpenSignupModal}
          openLoginmodal={setOpenLoginModal}
        />
      )}
      {openForgotPasswordModal && (
        <ForgotPasswordModal
          closeModal={setOpenForgotPasswordModal}
          openLoginModal={setOpenLoginModal}
        />
      )}
    </>
  );
};

const Dropdown = () => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <>
      <div
        className={
          dropdown
            ? "submenu-whyCatalog clicked flex flex-jcsb"
            : "submenu-whyCatalog flex flex-jcsb"
        }
        onClick={() => {
          setDropdown(!dropdown);
        }}
      >
        <div className="submenu-whyCatalog-diff col-1">
          <p>The Difference</p>
          <ul>
            <CustomLi path="#" title="World Class Support" />
            <CustomLi path="#" title="Top Notch Deliverability" />
            <CustomLi path="#" title="Ease of Use" />
          </ul>
        </div>
        <div className="submenu-whyCatalog-solu col-2">
          <p>Solution</p>
          <ul>
            <CustomLi path="#" title="Custom Catalog" />
            <CustomLi path="#" title="Affilite Catalog" />
            <CustomLi path="#" title="Marketing Catalog" />
            <CustomLi path="#" title="FlipBook Catalog" />
          </ul>
        </div>
        <div className="submenu-whyCatalog-solu col-3">
          <ul>
            <CustomLi path="#" title="Price Catalog" />
            <CustomLi path="#" title="E-commerse Catalog" />
            <CustomLi path="#" title="Brand Catalog" />
          </ul>
        </div>
      </div>
    </>
  );
};

const DropdownMobile = () => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <div
        className={
          dropdown
            ? "submenuMobile-whyCatalog clicked"
            : "submenuMobile-whyCatalog"
        }
        onClick={() => {
          setDropdown(!dropdown);
        }}
      >
        <div className="submenuMobile-whyCatalog-diff col-1">
          <p>The Difference</p>
          <ul>
            <li>
              <a href="/join/newsletter">World Class Support</a>
            </li>
            <li>
              <a href="/join/newsletter">Top Notch Deliverability</a>
            </li>
            <li>
              <a href="/join/newsletter">Ease of Use</a>
            </li>
          </ul>
        </div>
        <div className="submenuMobile-whyCatalog-solu col-2">
          <p>Solution</p>
          <ul>
            <li>
              <a href="/join/newsletter">Custom Catalog</a>
            </li>
            <li>
              <a href="/join/newsletter">Affilite Catalog</a>
            </li>
            <li>
              <a href="/join/newsletter">FlipBook Catalog</a>
            </li>
            <li>
              <a href="/join/newsletter">Graphic Design</a>
            </li>
            <li>
              <a href="/join/newsletter">Price Catalog</a>
            </li>
            <li>
              <a href="/join/newsletter">E-commerse Catalog</a>
            </li>
            <li>
              <a href="/join/newsletter">Brand Catalog</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const CustomLi = (props) => {
  return (
    <li>
      <Link to={props.path}>{props.title}</Link>
    </li>
  );
};

export default Header;
