import React, { useEffect, useRef, useState } from "react";
import { AdminLoginAPI } from "../../../api/admin/AdminLoginAPI";
import "./Login.scss";
import { encryptData } from "../../Util";

export let data;

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const rememberMeInputRef = useRef(null);
  // handling login form and calling login api
  const handleSubmit = (e) => {
    e.preventDefault();
    callLoginAPI();
  };

  const callLoginAPI = async () => {
    const data = await fetch(AdminLoginAPI, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "email=" + username + "&password=" + password,
    });
    const data_1 = await data.json();
    const [{ id, name, email, message, status }] = data_1;

    // validating data came from API
    if (id && name && email && message && status) {
      //  again validating
      if (status === "success" && message === "user Found") {
        // encrypt localStorage data start
        const originalData = {
          uId: id,
          uName: name,
          uEmail: email,
          auth: "true",
        };
        const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";
        const encryptedData = encryptData(originalData, salt);
        // encrypt localStorage data end
        // checking if user wants to stay login even after closing browser
        if (rememberMeInputRef.current.checked) {
          // storing encrypted data into localStorage
          localStorage.setItem("auKey", encryptedData);
        } else {
          // storing encrypted data into sessionStorage
          sessionStorage.setItem("auKey", encryptedData);
        }

        // redirecting to dashboard if all good
        window.location.pathname = "/admin/dashboard";
      }
    } else {
      // console.log("not an admin");
    }
  };

  return (
    <section className="adminLogin adminLogin-section">
      <div className="container">
        <div className="adminLogin-loginForm">
          <form className="flex flex-fdc" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Username"
              autoComplete="true"
              required
              ref={usernameInputRef}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            ></input>
            <input
              type="password"
              placeholder="Password"
              autoComplete="true"
              required
              ref={passwordInputRef}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
            <div className="adminLogin-loginForm-remember flex flex-aic">
              <input type="checkbox" ref={rememberMeInputRef}></input>Remember
              Me
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </section>
  );
}
