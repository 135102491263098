import React, { useState } from "react";
import "./DashboardStart.scss";
import imgLogo from "../../../images/props/img2.png";
import cmD1 from "../../../images/dashboards/cmD1.jpg";
import currCountry from "./countries.json";
import mobCountry from "../settings/countries.json";
import { baseURL } from "../../api/baseURL/BaseURL";
import { useOutletContext } from "react-router-dom";
import { decryptData, encryptData } from "../../adminpanel/Util";

const DashboardStart = () => {
  const userData = useOutletContext();
  const initialData = {};
  const [testImg, setTestImg] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [coverTestImg, setCoverTestImg] = useState(null);
  const [coverImgData, setCoverImgData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [companyProfileFormErrors, setCompanyProfileFormErrors] =
    useState(initialData);
  const [companyProfileDetails, setCompanyProfileDetails] = useState({
    companyWebsite: "",
    instagramURL: "",
    telegramURL: "",
    gstin: "",
  });

  const ImageFileselectHandler = (event) => {
    setImgData(event.target.files[0]);
    var src = URL.createObjectURL(event.target.files[0]);
    setTestImg(src);
    // console.log("Imagggggeeee::", src);
    setCompanyProfileDetails({
      ...companyProfileDetails,
      bussinessLogo: event.target.files[0],
    });
    setCompanyProfileFormErrors({
      ...companyProfileFormErrors,
      bussinessLogo: "",
    });
  };
  const coverImageFileselectHandler = (event) => {
    setCoverImgData(event.target.files[0]);
    var coverSrc = URL.createObjectURL(event.target.files[0]);
    setCoverTestImg(coverSrc);
    // console.log("CoverImagggggeeee::", coverSrc);
    setCompanyProfileDetails({
      ...companyProfileDetails,
      bussinessCover: event.target.files[0],
    });
  };

  const companyProfileFormValidate = (values) => {
    const errors = {};
    if (!values.bussinessLogo) {
      errors.bussinessLogo = "Please select company Logo";
      setIsSubmit(false);
    } else if (!values.name) {
      errors.name = "Please enter Your Name";
      setIsSubmit(false);
    } else if (!values.companyName) {
      errors.companyName = "Please enter company name";
      setIsSubmit(false);
    } else if (!values.calling_number || !values.calling_number === undefined) {
      errors.phoneNuber = "Please select your country iso code";
      setIsSubmit(false);
    } else if (!values.phoneNuber) {
      errors.phoneNuber = "Please enter your phone number";
      setIsSubmit(false);
    } else if (!values.countryCode && values.countryCode === undefined) {
      errors.countryCode = "Please select your country";
    } else {
      errors.currPassword = "";
      errors.newPassword = "";
      errors.confirmnewPassword = "";
      errors.matchPass = "";
      if (!isError) {
        setIsSubmit(true);
        callCompanyProfileAPI();
      }
    }
    return errors;
  };

  const handleChangeCountry = (e) => {
    // console.log("looogoogog", e.target.value);
    // console.log("datatatatat", currCountry[e.target.value]);
    if (currCountry[e.target.value] === undefined) {
      // console.log("lomde kya kar raha he");
      // delete setCompanyProfileDetails["countryCode"];
      setCompanyProfileDetails({
        ...companyProfileDetails,
        countryCode: currCountry[e.target.value],
      });
    } else {
      setCompanyProfileDetails({
        ...companyProfileDetails,
        countryCode: currCountry[e.target.value].countryCode,
        currencyCode: currCountry[e.target.value].currencyCode,
        countryName: currCountry[e.target.value].countryName,
      });
      // console.log("lomde data", companyProfileDetails);
    }
  };

  const handleChangeMobCountry = (e) => {
    // console.log("targettetetet", e.target.value);
    if (mobCountry[e.target.value] === undefined) {
      setCompanyProfileDetails({
        ...companyProfileDetails,
        calling_number: mobCountry[e.target.value],
      });
    } else {
      setCompanyProfileDetails({
        ...companyProfileDetails,
        calling_number: mobCountry[e.target.value].dialCode,
        calling_code: mobCountry[e.target.value].isoCode,
      });
    }
    // console.log("lomde data dekh le", companyProfileDetails);
    // e.target[e.target.value].parenNode.text = "Hello";
  };

  const handleSubmit = () => {
    setCompanyProfileFormErrors(
      companyProfileFormValidate(companyProfileDetails)
    );
  };

  const callCompanyProfileAPI = async () => {
    // if (isSubmit) {
    // console.log("calleddddddd.....", userData);
    // }
    const fData = new FormData();
    if (companyProfileDetails.bussinessCover) {
      fData.append("business_card_img", companyProfileDetails.bussinessCover);
    }
    fData.append("company_logo_img", companyProfileDetails.bussinessLogo);
    fData.append("name", companyProfileDetails.name);
    fData.append("company_name", companyProfileDetails.companyName);
    fData.append("country", companyProfileDetails.countryName);
    fData.append("country_code", companyProfileDetails.countryCode);
    fData.append("currency", companyProfileDetails.currencyCode);
    fData.append("calling_code", companyProfileDetails.calling_code);
    fData.append("calling_number", companyProfileDetails.calling_number);
    fData.append("company_url", companyProfileDetails.companyWebsite);
    fData.append("phone_no", companyProfileDetails.phoneNuber);
    fData.append(
      "social_media_links",
      `${companyProfileDetails.instagramURL},${companyProfileDetails.telegramURL}`
    );
    fData.append("gstin", companyProfileDetails.gstin);

    const res = await fetch(baseURL + `update-user/${userData.id}`, {
      method: "POST",
      headers: {
        accept: "application/json",
      },
      body: fData,
    });
    const data = await res.json();
    const { success } = data;

    if (success) {
      // console.log("success", data);
      let originalID;
      const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

      const idfliu = localStorage.getItem("idfliu");
      const idfliuSession = sessionStorage.getItem("idfliu");

      if (!idfliu) {
        // serching in session
        if (!idfliuSession) window.location.pathname = "/";
        else {
          originalID = decryptData(idfliuSession, salt);
          originalID["companyName"] = data.data.user.company_name;
          const encryptedData = encryptData(originalID, salt);
          sessionStorage.setItem("idfliu", encryptedData);
          window.location.pathname = "app/dashboard/home";
        }
      } else {
        originalID = decryptData(idfliu, salt);
        originalID["companyName"] = data.data.user.company_name;
        const encryptedData = encryptData(originalID, salt);
        localStorage.setItem("idfliu", encryptedData);
        window.location.pathname = "app/dashboard/home";
      }
    }
    // console.log("callledddddd", data);
  };

  return (
    <section className="dashboardGetStarted">
      <div className="dashboardGetStarted-overlay flex flex-jcc">
        <div className="dashboardGetStarted-modal">
          <div className="dashboardGetStarted-modal-header">
            Fill your bussiness profile details which help us to create your
            product catalog.
          </div>
          <div className="dashboardGetStarted-modal-form">
            <form>
              <div
                className="dashboardGetStarted-modal-form-images"
                style={{ position: "relative" }}
              >
                <div
                  className="dashboardSettingsProfile-account-bussinessProfile-imgbox"
                  style={{
                    margin: "6rem auto 15rem",
                    width: "400px",
                    height: "228.5px",
                    padding: "5px",
                  }}
                >
                  {coverTestImg == null ? (
                    <>
                      <img
                        src={imgLogo}
                        style={{
                          objectFit: "contain",
                          width: "50%",
                          height: "50%",
                          color: "gray",
                          display: "block",
                          margin: "20px auto ",
                        }}
                      ></img>
                      <p
                        style={{
                          font: "inherit",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        Bussiness Card (optional)
                      </p>
                    </>
                  ) : (
                    <img src={coverTestImg}></img>
                  )}
                  <div className="dashboardSettingsProfile-account-bussinessProfile-imgbox-selectImg">
                    <label
                      for="coverfile-upload"
                      className="custom-file-upload"
                    >
                      <i className="bx bxs-pencil"></i>
                    </label>
                    <input
                      id="coverfile-upload"
                      type="file"
                      onChange={coverImageFileselectHandler}
                    ></input>
                  </div>
                </div>
                <div
                  className="dashboardSettingsProfile-account-profile-imgbox"
                  style={{
                    position: "absolute",
                    top: "85%",
                    left: "50%",
                    transform: "translate(-50%)",
                    background: "white",
                  }}
                >
                  {testImg === null ? (
                    <>
                      <img
                        src={imgLogo}
                        style={{
                          objectFit: "contain",
                          width: "50%",
                          height: "50%",
                          color: "gray",
                          display: "block",
                          margin: "2rem auto 0.7rem",
                        }}
                      ></img>
                      <p
                        style={{
                          font: "inherit",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Company Logo
                      </p>
                    </>
                  ) : (
                    <img src={testImg}></img>
                  )}
                  <div className="dashboardSettingsProfile-account-profile-imgbox-selectImg">
                    <label for="file-upload" className="custom-file-upload">
                      {/* <i class="bx bx-plus"></i> */}
                      <i className="bx bxs-pencil"></i>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={ImageFileselectHandler}
                    ></input>
                  </div>
                </div>
              </div>
              <p
                className="dashboardGetStarted-modal-form-errorMsg"
                style={{ textAlign: "center" }}
              >
                {companyProfileFormErrors.bussinessLogo}
              </p>
              <div className="dashboardGetStarted-modal-form-details">
                <input
                  type="text"
                  placeholder="Full Name"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      name: e.target.value,
                    });
                    setCompanyProfileFormErrors({
                      ...companyProfileFormErrors,
                      name: "",
                    });
                  }}
                ></input>
                <p className="dashboardGetStarted-modal-form-errorMsg">
                  {companyProfileFormErrors.name}
                </p>
                <input
                  type="text"
                  placeholder="Company Name"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      companyName: e.target.value,
                    });
                    setCompanyProfileFormErrors({
                      ...companyProfileFormErrors,
                      companyName: "",
                    });
                  }}
                ></input>
                <p className="dashboardGetStarted-modal-form-errorMsg">
                  {companyProfileFormErrors.companyName}
                </p>
                <div
                  style={{
                    position: "relative",
                    width: "420px",
                    margin: "auto",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Phone Number"
                    style={{ padding: "12px 12px 12px 135px" }}
                    onChange={(e) => {
                      setCompanyProfileDetails({
                        ...companyProfileDetails,
                        phoneNuber: e.target.value,
                      });
                      setCompanyProfileFormErrors({
                        ...companyProfileFormErrors,
                        phoneNuber: "",
                      });
                    }}
                  ></input>
                  <select
                    style={{
                      // all: "unset",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      margin: "0",
                      width: "110px",
                      appearance: "button",
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                      // fontSize: "12px",
                    }}
                    onChange={(e) => {
                      handleChangeMobCountry(e);
                    }}
                  >
                    <option value="null">( +XX )</option>
                    {mobCountry.map((c, index) => {
                      return (
                        <option value={index}>
                          {/* {c.name} */}
                          {c.dialCode}
                        </option>
                      );
                    })}
                    <option></option>
                  </select>
                </div>
                <p className="dashboardGetStarted-modal-form-errorMsg">
                  {companyProfileFormErrors.phoneNuber}
                </p>
                {/* <input
                  type="text"
                  placeholder="Country"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      countryCode: e.target.value,
                    });
                    setCompanyProfileFormErrors({
                      ...companyProfileFormErrors,
                      countryCode: "",
                    });
                  }}
                ></input> */}
                <select
                  onChange={(e) => {
                    handleChangeCountry(e);
                    setCompanyProfileFormErrors({
                      ...companyProfileFormErrors,
                      countryCode: "",
                    });
                  }}
                >
                  <option value="null">--Select Country--</option>
                  {currCountry.map((c, index) => {
                    return (
                      <option value={index}>
                        {c.countryName} {` (${c.currencyCode})`}
                      </option>
                    );
                  })}
                </select>
                <p className="dashboardGetStarted-modal-form-errorMsg">
                  {companyProfileFormErrors.countryCode}
                </p>
                <input
                  type="text"
                  placeholder="Company Website (optional)"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      companyWebsite: e.target.value,
                    });
                  }}
                ></input>
                <input
                  type="text"
                  placeholder="Instagram Profile URL (optional)"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      instagramURL: e.target.value,
                    });
                  }}
                ></input>
                <input
                  type="text"
                  placeholder="Telegram Channel URL (optional)"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      telegramURL: e.target.value,
                    });
                  }}
                ></input>
                <input
                  type="text"
                  placeholder="GSTIN (optional)"
                  onChange={(e) => {
                    setCompanyProfileDetails({
                      ...companyProfileDetails,
                      gstin: e.target.value,
                    });
                  }}
                ></input>
              </div>
              <div className="dashboardGetStarted-modal-form-btn">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardStart;
