import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import "./DashboardHome.scss";
import dashboardIsometric from "../../../images/dashboards/templates.png";
import ipopular from "./../../../images/dashboards/ipopular.svg";
import irecent from "./../../../images/dashboards/irecent.svg";
import istarred from "./../../../images/dashboards/istarred.svg";
import { motion } from "framer-motion";
import { baseURL } from "../../api/baseURL/BaseURL";
import { useOutletContext } from "react-router-dom";

const DashboardHome = () => {
  const [recent, setRecent] = useState(true);
  const [popular, setPopular] = useState(false);
  const [starred, setStarred] = useState(false);
  const [pcCount, setPCCount] = useState(null);
  const [recentCatalog, setRecentCatalog] = useState(null);
  const userData = useOutletContext();

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  useEffect(() => {
    const callProductCatalogCountAPI = async () => {
      const res = await fetch(
        baseURL + `get-product-catalog-count/${userData.id}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const data = await res.json();
      const { success } = data;
      if (success) {
        setPCCount(data.data);
      }
    };

    const callRecentDocAPI = async () => {
      const res = await fetch(baseURL + `catalog/${userData.id}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const data = await res.json();
      // if (data.success) {
      //   console.log(data);
      // }
    };
    callProductCatalogCountAPI();
    callRecentDocAPI();
  }, []);

  return (
    <section className="dashboardHome dashboardHome-section">
      <div className="dashboardHome-banner">
        {/* <Slider {...settings}> */}
        <div>
          <div className="dashboardHome-banner-item flex">
            <div className="dashboardHome-banner-item-left">
              <p className="dashboardHome-banner-item-left-tag">Tutorial</p>
              <p className="dashboardHome-banner-item-left-header">
                Get Started with a <span>template</span>! <br></br>
                <span>Quickly create</span> and share your product catalog.
              </p>
              <p className="dashboardHome-banner-item-left-details">
                Get started by choosing from 7+ professionally-designed,
                editable templates. Or, if you want to start from scratch, then
                go and watch our Youtube video for quick start.{" "}
              </p>
              <button>
                <a className="dashboardHome-banner-item-left-link">
                  Let's start
                </a>
              </button>
            </div>
            <div className="dashboardHome-banner-item-right">
              <div className="dashboardHome-banner-item-right-imgbox flex flex-jcc">
                <img src={dashboardIsometric}></img>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
            <div className="dashboardHome-banner-item flex">
              <div className="dashboardHome-banner-item-left">
                <p className="dashboardHome-banner-item-left-tag">Tutorial</p>
                <p className="dashboardHome-banner-item-left-header">
                  Get Started with a <span>template</span>! <br></br>
                  <span>Quickly customize</span> and share your product catalog.
                </p>
                <p className="dashboardHome-banner-item-left-details">
                  Get started by choosing from hundreds of
                  professionally-designed, customizable templates. Or, if you
                  want to start from scratch, use the intuitive drag-and-drop
                  editor and brand asset hub to keep content looking polished.{" "}
                </p>
                <a className="dashboardHome-banner-item-left-link">
                  Let's start
                </a>
              </div>
              <div className="dashboardHome-banner-item-right">
                <div className="dashboardHome-banner-item-right-imgbox flex flex-jcc">
                  <img src={dashboardIsometric}></img>
                </div>
              </div>
            </div>
          </div> */}
        {/* <div>
            <div className="dashboardHome-banner-item flex">
              <div className="dashboardHome-banner-item-left">
                <p className="dashboardHome-banner-item-left-tag">Tutorial</p>
                <p className="dashboardHome-banner-item-left-header">
                  Get Started with a <span>template</span>! <br></br>
                  <span>Quickly customize</span> and share your product catalog.
                </p>
                <p className="dashboardHome-banner-item-left-details">
                  Get started by choosing from hundreds of
                  professionally-designed, customizable templates. Or, if you
                  want to start from scratch, use the intuitive drag-and-drop
                  editor and brand asset hub to keep content looking polished.{" "}
                </p>
                <a className="dashboardHome-banner-item-left-link">
                  Let's start
                </a>
              </div>
              <div className="dashboardHome-banner-item-right">
                <div className="dashboardHome-banner-item-right-imgbox flex flex-jcc">
                  <img src={dashboardIsometric}></img>
                </div>
              </div>
            </div>
          </div> */}
        {/* </Slider> */}
      </div>

      <div className="dashboardHome-state flex">
        {pcCount === null ? (
          <></>
        ) : (
          <>
            <div className="dashboardHome-state-individual">
              <p className="dashboardHome-state-individual-header">Catalogs</p>
              <p className="dashboardHome-state-individual-count">
                {pcCount.catalogsCount}
              </p>
            </div>
            <div className="dashboardHome-state-individual">
              <p className="dashboardHome-state-individual-header">Products</p>
              <p className="dashboardHome-state-individual-count">
                {pcCount.productsCount}
              </p>
            </div>

            {/* <div className="dashboardHome-state-individual">
          <p className="dashboardHome-state-individual-header">
          Impression on catalog
          </p>
          <p className="dashboardHome-state-individual-count">NA</p>
        </div> */}
          </>
        )}
      </div>

      <div className="dashboardHome-rsp">
        <div className="dashboardHome-rsp-menu">
          <span
            className={recent ? "active" : ""}
            onClick={() => {
              setRecent(true);
              setStarred(false);
              setPopular(false);
            }}
          >
            Recent
          </span>
          {/* <span
            className={popular ? "active" : ""}
            onClick={() => {
              setRecent(false);
              setStarred(false);
              setPopular(true);
            }}
          >
            Popular
          </span>
          <span
            className={starred ? "active" : ""}
            onClick={() => {
              setRecent(false);
              setStarred(true);
              setPopular(false);
            }}
          >
            Starred
          </span> */}
        </div>
        <div className="dashboardHome-rsp-content">
          {recent ? (
            <>
              <motion.div
                className="dashboardHome-rsp-r"
                initial={{
                  y: -40,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                  },
                }}
              >
                <div className="dashboardHome-rsp-r-item flex flex-aic">
                  <div className="dashboardHome-rsp-r-item-imgbox">
                    <img src={irecent}></img>
                  </div>
                  <div className="dashboardHome-rsp-r-item-details">
                    <p className="dashboardHome-rsp-r-item-details-title">
                      Recent Documents
                    </p>
                    <br />
                    <p className="dashboardHome-rsp-r-item-details-subtitle">
                      This area will display recently edited documents and
                      templates.
                      <br />
                      Get started by selecting a template or creating a blank
                      document.
                    </p>
                  </div>
                  <div className="dashboardHome-rsp-r-item-title"></div>
                </div>
              </motion.div>
            </>
          ) : null}
          {popular ? (
            <>
              <motion.div
                className="dashboardHome-rsp-r"
                initial={{
                  y: -40,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                  },
                }}
              >
                <div className="dashboardHome-rsp-r-item flex flex-aic">
                  <div className="dashboardHome-rsp-r-item-imgbox">
                    <img src={ipopular}></img>
                  </div>
                  <div className="dashboardHome-rsp-r-item-details">
                    <p className="dashboardHome-rsp-r-item-details-title">
                      Popular Templates
                    </p>
                    <br />
                    <p className="dashboardHome-rsp-r-item-details-subtitle">
                      This area is reserved for your team's most popular
                      templates.
                      <br />
                      Begin creating Brand Templates for them to display here!
                    </p>
                  </div>
                  <div className="dashboardHome-rsp-r-item-title"></div>
                </div>
              </motion.div>
            </>
          ) : null}
          {starred ? (
            <>
              <motion.div
                className="dashboardHome-rsp-r"
                initial={{
                  y: -40,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                  },
                }}
              >
                <div className="dashboardHome-rsp-r-item flex flex-aic">
                  <div className="dashboardHome-rsp-r-item-imgbox">
                    <img src={istarred}></img>
                  </div>
                  <div className="dashboardHome-rsp-r-item-details">
                    <p className="dashboardHome-rsp-r-item-details-title">
                      Starred Templates
                    </p>
                    <br />
                    <p className="dashboardHome-rsp-r-item-details-subtitle">
                      You haven't starred any documents or templates yet.
                      <br />
                      When you star a document or template it displays here so
                      it's easier to find.
                    </p>
                  </div>
                  <div className="dashboardHome-rsp-r-item-title"></div>
                </div>
              </motion.div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default DashboardHome;
