import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import "./forgotPasswordModal.scss";
import { baseURL } from "../../api/baseURL/BaseURL";

const ForgotPasswordModal = ({ closeModal, openLoginModal }) => {
  const emailRef = useRef();
  const [forgotEmailError, setForgotEmailError] = useState(null);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi;

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailAddress = emailRef.current.value;
    if (!emailAddress) {
      return setForgotEmailError("Please enter registered email address.");
    }

    if (emailRegex.test(emailAddress)) {
      setForgotEmailError(null);
      sendTemporaryPassword();
    } else {
      return setForgotEmailError("Please enter valid email address");
    }
  };

  const sendTemporaryPassword = async () => {
    try {
      setIsLoading(true);
      const emailAddress = emailRef.current.value;
      const response = await fetch(baseURL + `get-otp?email=${emailAddress}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const data = await response.json();
      const { success, code } = data;
      if (success) {
        setShowLoginButton(true);
      }
      if (code === 401) {
        setForgotEmailError("Email is not registered!");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section
      className="forgotPasswordModal forgotPasswordModal-section flex flex-jcc flex-aic"
      onClick={() => {
        closeModal(false);
      }}
    >
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="forgotPasswordModalDiv"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="forgotPasswordModalDiv-header flex flex-jcsb flex-aic">
          <p>Password Reset</p>
          <div
            className="forgotPasswordModalDiv-header-closebtn"
            onClick={() => {
              closeModal(false);
            }}
          >
            <span></span>
            <span></span>
          </div>
        </div>

        {showLoginButton ? (
          <div className="forgotPasswordModalDiv-successMsg">
            <p>Email successfully sent. Please check your inbox.</p>
            <button
              onClick={() => {
                closeModal(false);
                openLoginModal(true);
              }}
            >
              Login
            </button>
          </div>
        ) : (
          <>
            <div className="forgotPasswordModalDiv-subHeader">
              <p>
                Enter your <b>email address</b> that you used to register. We'll
                send you an email with your temporary password.
              </p>
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              <form>
                <div className="forgotPasswordModalDiv-adiv-mail forgotPasswordModalDiv-adiv flex flex-aic">
                  <input
                    type="email"
                    name="lEmail"
                    placeholder="Email"
                    ref={emailRef}
                    onChange={() => setForgotEmailError(null)}
                  ></input>
                </div>
                <p className="forgotPasswordModalDiv-adiv-password-errorMsg">
                  {forgotEmailError}
                </p>
                <div
                  className="forgotPasswordModalDiv-adiv-lbtn"
                  //     onClick={handleBtnAnimation}
                >
                  <button onClick={(e) => handleSubmit(e)} disabled={isLoading}>
                    {isLoading ? (
                      "Sending..."
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </motion.div>
    </section>
  );
};

export default ForgotPasswordModal;
