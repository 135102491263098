import React, { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import DashboardInnerContent from "./innerContent/DashboardInnerContent";
import DashboardSideBar from "./sidebar/DashboardSideBar";
import "./UserDashboard.scss";

const UserDashboard = () => {
  const userID = useOutletContext();
  // console.log("userserserser", userID);
  const [path, setPath] = useState(window.location.pathname);
  const [newCreatedCatalogID, setNewCreatedCatalogID] = useState(null);
  const [newCreatedCatalogName, setNewCreatedCatalogName] = useState("");
  // const [templatePhpLink, setTemplatePhpLink] = useState(null);
  const pathHandler = (URL) => {
    setPath(URL);
  };
  return (
    <section
      className="userDashboard userDashboard-section flex"
      style={{ flexWrap: "nowrap" }}
    >
      <DashboardSideBar setPath={pathHandler} />
      <Outlet
        context={{
          ...userID,
          path: path,
          newCreatedCatalogID,
          setNewCreatedCatalogID,
          newCreatedCatalogName,
          setNewCreatedCatalogName,
        }}
      />
    </section>
  );
};

export default UserDashboard;
