import React from "react";
import { MdError } from "react-icons/md";
import "./fallBackPage.scss";
import { Link } from "react-router-dom";

const FallBackPage = ({ key }) => {
  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     if (!key) {
  //       navigate(-1);
  //     }
  //   }, []);

  return (
    <div className="fallback flex flex-fdc flex-aic flex-jcc">
      <div className="fallback_icon">
        <MdError size={70} />
      </div>
      <div className="fallback_details">
        <h1>Something went wrong</h1>
        <h2>
          There was a problem processing the request. Our Team has been
          notified. <br /> To report an issue,{" "}
          <Link to="/app/dashboard/feedback">click here</Link>
        </h2>
      </div>
      <div className="fallback_button">
        <button
          onClick={() =>
            (window.location.pathname = "/app/dashboard/templates")
          }
        >
          Return to last page
        </button>
      </div>
    </div>
  );
};

export default FallBackPage;
