import React, { useState } from "react";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import "./DashboardSettingsDeleteAcc.scss";

const DashboardSettingsDeleteAcc = ({ outletData }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  return (
    <>
      <section className="dashboardSettingsDeleteAcc">
        <div className="dashboardSettingsDeleteAcc-daDiv">
          <p className="dashboardSettingsDeleteAcc-daDiv-header">
            Delete your Account
          </p>
          <p className="dashboardSettingsDeleteAcc-daDiv-subheader">
            If you delete your account than all the catalogs and products will
            be deleted and we can not able to retrive it in future.
          </p>
          <button
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            Delete account
          </button>
        </div>
      </section>
      {deleteModal && (
        <ConfirmDeleteModal userID={outletData} closeModal={setDeleteModal} />
      )}
    </>
  );
};

export default DashboardSettingsDeleteAcc;
