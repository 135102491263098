import React, { useState, useRef } from "react";
import { BiSearchAlt } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import { Temps } from "./Temps";
import { FilterButtons } from "./FilterButtons";
import "./Templates.scss";
import Footer from "../footer/Footer.js";
import { Link, Outlet } from "react-router-dom";
import img from "../../images/dashboards/pdfss.jpg";
const Templates = () => {
  const [tempData, setTempData] = useState(Temps);
  const [filterButtonData, setFilterButtonData] = useState(FilterButtons);
  const filterInputRef = useRef(null);
  const templateTopRef = useRef(null);

  const [pageNumber, setPageNumber] = useState(0);
  const templatesPerPage = 8;
  const pagesVisited = pageNumber * templatesPerPage;
  const pageCount = Math.ceil(tempData.length / templatesPerPage);

  const [currModalTemp, setCurrModalTemp] = useState({});

  const displayTemplates = tempData
    .slice(pagesVisited, pagesVisited + templatesPerPage)
    .map((template, index) => {
      return (
        <div className="templates-temps-item" key={index}>
          <Link
            to={{
              pathname: `/templates/${template.link}`,
            }}
            onClick={() => {
              setCurrModalTemp(template);
            }}
          >
            <div className="templates-temps-item-imgbox">
              <img
                src={require("../../images/dashboards/pdfss.jpg")}
                alt={template.title}
              ></img>
            </div>
            <div className="templates-temps-item-title flex flex-aic flex-jcc">
              <h2>{template.title}</h2>
            </div>
          </Link>
        </div>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const filterTemplateHandler = (category) => {
    if (category === "all" || category === "") {
      setTempData(Temps);
    } else {
      const filteredTemplates = Temps.filter((temp) => {
        return temp.category === category;
      });
      setTempData(filteredTemplates);
    }
  };

  const searchFilterTemplateHandler = (serachQuery) => {
    if (serachQuery !== "") {
      const searchFilterResults = Temps.filter((template) => {
        return Object.values(template)
          .join(" ")
          .toLowerCase()
          .includes(serachQuery.toLowerCase());
      });
      setTempData(searchFilterResults);
    } else {
      setTempData(Temps);
    }
  };

  return (
    <>
      <section className="templates templates-section">
        <div className="templates-header">
          <h1>Discover Interactive Templates</h1>
          <h2>
            Find your Catalog Template and getting start with making catalog for
            free. Personalize any catalog template with your branding,
            interactions, and share it online!
          </h2>
          <div className="templates-header-searchBar flex flex-jcc">
            <input
              type="text"
              ref={filterInputRef}
              placeholder="E.g e-commerce, jewellery ..."
              onKeyDown={(event) => {
                // if (event.keyCode === "Enter" || event.key === "Enter") {
                searchFilterTemplateHandler(filterInputRef.current.value);
                // }
              }}
            ></input>
            <BiSearchAlt className="templates-header-searchBar-icon" />
          </div>
        </div>
        <div className="container">
          <div className="templates-filter flex flex-jcc" ref={templateTopRef}>
            <button
              className="filterButtons"
              onClick={() => filterTemplateHandler("all")}
            >
              All
            </button>
            {filterButtonData.map((button, index) => {
              const { category } = button;
              return (
                <button
                  className={button.cName}
                  key={index}
                  onClick={() => filterTemplateHandler(category)}
                >
                  {button.title}
                </button>
              );
            })}
          </div>
          <div className="templates-temps flex">
            {/* {tempData.map((template, index) => {
              return (
                <div className="templates-temps-item" key={index}>
                  <a href={template.link}>
                    <div className="templates-temps-item-imgbox">
                      <img src={template.image} alt={template.title}></img>
                    </div>
                    <div className="templates-temps-item-title flex flex-aic flex-jcc">
                      <h2>{template.title}</h2>
                    </div>
                  </a>
                </div>
              );
            })} */}
            {displayTemplates}
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousClassName={"previousBttn"}
            nextClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            onClick={() => {
              window.scrollTo(0, templateTopRef.current.offsetTop);
            }}
          />
        </div>
        <Footer />
        <Outlet context={currModalTemp} />
      </section>
    </>
  );
};

export default Templates;
