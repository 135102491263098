import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./TemplateModal.scss";

const TemplateModal = () => {
  const currModalTemp = useOutletContext();
  const [data, setData] = useState(currModalTemp);

  useEffect(() => {
    document.body.classList.add("noscroll");

    return () => {
      document.body.classList.remove("noscroll");
    };
  }, []);

  let history = useNavigate();

  let back = (e) => {
    e.stopPropagation();
    history(-1);
  };

  return (
    <section className="templateModal-bg-overlay" onClick={back}>
      <div
        className="templateModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="templateModal-navbar flex flex-jcsb flex-aic">
          <div className="templateModal-navbar-header">
            <div className="templateModal-navbar-header-title">
              {data.fullTitle}
            </div>
            <div className="templateModal-navbar-header-details">
              {data.free ? "Free" : "Premium"}
            </div>
          </div>
          <div className="templateModal-navbar-useBtn">
            {/* <button>
              <a href="/login" target="_blank">
                Use this template
              </a>
            </button> */}
          </div>
        </div>
        <div className="templateModal-tempPhoto">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {data.allImage.map((img) => {
              return (
                <SwiperSlide>
                  <div className="templateModal-tempPhoto-imgbox">
                    <img
                      src={img.image}
                      // src={img.image}
                    ></img>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className="templateModal-tempDetails">
          Celebrate on a company level by using this Easter dinner invitation
          flyer template. It’s festive, colorful, and perfect for any business.
          Take it to our Design Studio to really make it special. Add
          interactivity by inserting videos, GIFs, and more. Once it’s done and
          looking perfect, easily share it with everyone in the company via
          email, or post it in the company newsletter.
        </div>
      </div>
      <button
        className="templateModal-bg-overlay-btn"
        type="button"
        onClick={back}
      >
        <span></span>
        <span></span>
      </button>
    </section>
  );
};

export default TemplateModal;
