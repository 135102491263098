import React from "react";
import SkeletonElement from "../SkeletonElement";
import "./TempCategorySkeleton.scss";

const TempCategorySkeleton = () => {
  return (
    <div className="tempCategorySkeleton flex">
      <SkeletonElement type={"tempCategory2"} />
      <SkeletonElement type={"tempCategory"} />
      <SkeletonElement type={"tempCategory3"} />
    </div>
  );
};

export default TempCategorySkeleton;
