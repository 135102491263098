import React from "react";
import { baseURL } from "../../api/baseURL/BaseURL";
import "./ConfirmDeleteModal.scss";
import { motion } from "framer-motion";

const ConfirmDeleteModal = ({ closeModal, userID }) => {
  const callDeleteAccAPI = async () => {
    const res = await fetch(baseURL + `delete-user/${userID.id}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    });
    const data = await res.json();
    const { success } = data;

    if (success) {
      if (userID.storage === "session") {
        sessionStorage.removeItem("idfliu");
        window.location.pathname = "/";
      }
      if (userID.storage === "local") {
        localStorage.removeItem("idfliu");
        window.location.pathname = "/";
      }
    }
  };
  return (
    <section className="confirmDeleteModal flex flex-aic flex-jcc">
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        className="confirmDeleteModal-cdDiv"
      >
        <div className="confirmDeleteModal-cdDiv-header flex flex-aic">
          ⚠️ Delete My Account
          {/* <i class="bx bxs-error"></i> */}
        </div>
        <div className="confirmDeleteModal-cdDiv-subHeader flex flex-aic">
          Are you sure you want to delete your account?
        </div>
        <div className="confirmDeleteModal-cdDiv-btn flex flex-jcsb">
          <button
            onClick={() => {
              closeModal(false);
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              callDeleteAccAPI();
            }}
          >
            Delete
          </button>
        </div>
      </motion.div>
    </section>
  );
};

export default ConfirmDeleteModal;
