import React, { useState } from "react";
import SupportHeader from "../support/SupportHeader";
import "./Contact.scss";
import wappQR from "../../images/cwappp.jpg";

const Contact = () => {
  const [formActive, setFormActive] = useState(true);

  const activeHandler = () => {
    // setFormActive(!formActive);
    // console.log(formActive);
  };

  return (
    <>
      <SupportHeader />
      <section className="contact contact-section">
        <div className="container flex">
          <div className="contact-left flex flex-fdc flex-jcsb">
            <div className="contact-left-header">
              <h1>Get a help</h1>
              {/* <p>
                Fill up the form and our Team will get back <br /> to you within
                24 hours.
              </p> */}
              <p>
                <b>Address</b>: Plot No: 94, Sardar Patel Society-1, Jewel
                circle, Bhavnagar, Gujarat, India - 364003
              </p>
            </div>
            <div className="contact-left-social">
              <div className="contact-left-social-link">
                <a href="https://wa.me/917359355142" onClick={activeHandler}>
                  <i class="bx bxl-whatsapp"></i> +91 73593 55142
                </a>
              </div>
              <div className="contact-left-social-link">
                <a
                  href="mailto:managercatalogmaker@gmail.com"
                  onClick={activeHandler}
                >
                  <i class="bx bx-mail-send"></i> managercatalogmaker@gmail.com
                </a>
              </div>
              {/* <div className="contact-left-social-link">
                <a
                  href="https://www.linkedin.com/in/catalog-maker-268246239"
                  target="_blank"
                >
                  <i class="bx bxl-linkedin"></i> @CatalogMaker
                </a>
              </div> */}
            </div>
            <div className="contact-left-social-icons">
              <a
                href="https://www.facebook.com/catalogmaker.in"
                target="_blank"
              >
                <i class="bx bxl-facebook"></i>
              </a>
              <a href="https://twitter.com/catalogsmaker" target="_blank">
                <i class="bx bxl-twitter"></i>
              </a>
              <a
                href="https://instagram.com/catalogmaker?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <i class="bx bxl-instagram"></i>
              </a>
            </div>
          </div>
          <div className="contact-right">
            {/* fillup the form */}
            {formActive ? (
              <div className="contact-right-form">
                <form>
                  <div style={{ position: "relative" }}>
                    <label for="name">Your Name</label>
                    <input type="text" id="name" name="name" required></input>
                    <i className="bx bx-user"></i>
                  </div>
                  <div style={{ position: "relative" }}>
                    <label for="email">Mail</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                    ></input>
                    <i className="bx bx-envelope"></i>
                  </div>
                  <div>
                    <label for="message">Message</label>
                    <textarea
                      id="message"
                      placeholder="Message"
                      rows={8}
                      required
                    ></textarea>
                  </div>
                  <div>
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="contact-right-form">
                <div className="contact-right-form-wapp">
                  <div className="contact-right-form-wapp-qr flex flex=aic flex-jcc">
                    <img src={wappQR}></img>
                  </div>
                  {/* <div className="contact-right-form-wapp-form">
                    <form>
                      <div>
                        <textarea
                          id="message"
                          placeholder="Message"
                          rows={8}
                          required
                        ></textarea>
                      </div>
                      <div>
                        <button type="submit">Send Message</button>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
