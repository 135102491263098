export const FilterButtons = [
  {
    title: "Product 4x4",
    category: "product",
    cName: "filterButtons",
  },
  // {
  //   title: "Jewellery",
  //   category: "jewellery",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "Clothing",
  //   category: "clothing",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "Jewellery",
  //   category: "jewellery",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "Clothing",
  //   category: "clothing",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
  // {
  //   title: "E-Commerce",
  //   category: "product",
  //   cName: "filterButtons",
  // },
];
