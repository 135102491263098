import React from "react";
import pnf from "../../images/404/404.webp";
import "./PageNotFound.scss";

const PageNotFound = () => {
  return (
    <section className="pageNotFound pageNotFound-section flex flex-jcc flex-aic">
      <div className="pageNotFound-imgbox">
        <img src={pnf}></img>
      </div>
    </section>
  );
};

export default PageNotFound;
