import React, { useState } from "react";
import { baseURL } from "../../api/baseURL/BaseURL";
import "./DashboardSettingsChangePassword.scss";
import { motion } from "framer-motion";
const DashboardSettingsChangePassword = ({ outletData }) => {
  const initialData = {};
  const [allPassword, setAllPassword] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [changePasswordFormErrors, setChangePasswordFormErrors] =
    useState(initialData);

  const callChangePasswordAPI = async () => {
    setIsLoader(true);
    if (allPassword) {
      const res = await fetch(baseURL + `update-password/${outletData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `new_password=${allPassword.newPassword}&old_password=${allPassword.currPassword}`,
      });

      const data = await res.json();

      const { success } = data;
      if (success) {
        // setChangePasswordFormErrors({
        //   ...changePasswordFormErrors,
        //   passChanged: "Password Updated Successfully",
        // });
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
          window.location.pathname = "app/dashboard/home";
        }, 2000);
        setIsLoader(false);
      } else {
        setChangePasswordFormErrors({
          ...changePasswordFormErrors,
          passIncorrect: "current Password is not valid",
        });
        setIsLoader(false);
      }
      // console.log("ressssspopopopo", data);
    }
  };

  const changePasswordFormValidate = (values) => {
    const errors = {};
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    if (!values.currPassword) {
      errors.currPassword = "Please enter Current Password";
      setIsSubmit(false);
    } else if (!values.newPassword) {
      errors.newPassword = "Please enter New Password";
      setIsSubmit(false);
    } else if (!values.confirmnewPassword) {
      errors.confirmnewPassword = "Please enter confirm New Password";
      setIsSubmit(false);
    } else if (values.newPassword !== values.confirmnewPassword) {
      errors.matchPass = "Confirm password is not matched";
      setIsSubmit(false);
    } else {
      errors.currPassword = "";
      errors.newPassword = "";
      errors.confirmnewPassword = "";
      errors.matchPass = "";
      if (!isError) {
        setIsSubmit(true);
        callChangePasswordAPI();
      }
    }
    return errors;
  };

  const handleSubmit = () => {
    setChangePasswordFormErrors(changePasswordFormValidate(allPassword));
  };
  return (
    <section className="dashboardSettingsChangePassword">
      <div className="dashboardSettingsChangePassword-cpDiv">
        <p className="dashboardSettingsChangePassword-cpDiv-header">
          Change Password
        </p>
        <div className="dashboardSettingsChangePassword-cpDiv-form">
          <form>
            <p className="dashboardSettingsChangePassword-cpDiv-form-header">
              Current Password
            </p>
            <input
              type="password"
              onChange={(e) => {
                setAllPassword({
                  ...allPassword,
                  currPassword: e.target.value,
                });
                setChangePasswordFormErrors({
                  ...changePasswordFormErrors,
                  currPassword: "",
                  passIncorrect: "",
                });
              }}
            ></input>
            <p className="dashboardSettingsChangePassword-cpDiv-form-errorMsg">
              {changePasswordFormErrors.currPassword}
            </p>
            <p className="dashboardSettingsChangePassword-cpDiv-form-header">
              New Password
            </p>
            <input
              type="password"
              onChange={(e) => {
                setAllPassword({ ...allPassword, newPassword: e.target.value });
                setChangePasswordFormErrors({
                  ...changePasswordFormErrors,
                  newPassword: "",
                });
              }}
            ></input>
            <p className="dashboardSettingsChangePassword-cpDiv-form-errorMsg">
              {changePasswordFormErrors.newPassword}
            </p>
            <p className="dashboardSettingsChangePassword-cpDiv-form-header">
              Confirm Password
            </p>
            <input
              type="password"
              onChange={(e) => {
                setAllPassword({
                  ...allPassword,
                  confirmnewPassword: e.target.value,
                });
                setChangePasswordFormErrors({
                  ...changePasswordFormErrors,
                  confirmnewPassword: "",
                  matchPass: "",
                });
              }}
            ></input>
            <p className="dashboardSettingsChangePassword-cpDiv-form-errorMsg">
              {changePasswordFormErrors.confirmnewPassword}
            </p>
            <p className="dashboardSettingsChangePassword-cpDiv-form-errorMsg">
              {changePasswordFormErrors.matchPass}
            </p>
            <p className="dashboardSettingsChangePassword-cpDiv-form-errorMsg">
              {changePasswordFormErrors.passIncorrect}
            </p>
            {/* <p
              className="dashboardSettingsChangePassword-cpDiv-form-errorMsg"
              style={{ color: "green" }}
            >
              {changePasswordFormErrors.passChanged}
            </p> */}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
                // callChangePasswordAPI();
              }}
            >
              {isLoader ? (
                <lord-icon
                  className="btn-loader inactive"
                  src="https://cdn.lordicon.com/xjovhxra.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#242424"
                  stroke="80"
                  scale="75"
                  style={{
                    width: "25px",
                    height: "25px",
                    position: "absolute",
                    left: "40%",
                    top: "20%",
                  }}
                ></lord-icon>
              ) : (
                "change password"
              )}
            </button>
          </form>
        </div>
      </div>
      {successMsg && (
        <motion.div
          initial={{
            // scale: 0,
            x: "100px",
            opacity: 0,
          }}
          animate={{
            // scale: 1,
            x: "0px",
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          className="dashboardFeedback-successMsg"
        >
          Password Updated Successfully !
        </motion.div>
      )}
    </section>
  );
};

export default DashboardSettingsChangePassword;
