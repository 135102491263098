import React, { useEffect, useState, useRef } from "react";
import "./DashboardSettingsProfile.scss";
import imgLogo from "../../../images/props/img2.png";
import countryISDData from "./countries.json";
import {
  baseURL,
  IMAGE_PRODUCTS_URL,
  IMAGE_USER_URL,
} from "../../api/baseURL/BaseURL";
import loader from "./../../../lottie/logo2.json";
import mobCountry from "../settings/countries.json";
import currCountry from "../start/countries.json";
import { motion } from "framer-motion";
import { getUserDetails } from "../../utils/getUserDetails";
import { setAllUserDetails } from "../../utils/setUserDetails";

const DashboardSettingsProfile = ({ outletData }) => {
  const initialData = {};
  const [testImg, setTestImg] = useState(null);
  const [coverTestImg, setCoverTestImg] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [coverImgData, setCoverImgData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoader2, setIsLoader2] = useState(false);
  const [companyDetailsFormErrors, setCompanyDetailsFormErrors] =
    useState(initialData);
  const nameRef = useRef();
  const companyNameRef = useRef();
  const phoneNumberRef = useRef();
  const currencyRef = useRef();
  const companyWebsiteRef = useRef();
  const instagramURLRef = useRef();
  const telegramURLRef = useRef();
  const GSTINRef = useRef();
  const isoCodeRef = useRef();
  const [successMsg, setSuccessMsg] = useState(false);
  const [accDetails, setAccDetails] = useState({
    name: "",
    companyName: "",
    mobileNumber: "",
    currency: "",
    componyWebsite: "",
    instagramURL: "",
    telegramURL: "",
    GSTIN: "",
    accImage: "",
    coverImage: "",
    email: "",
  });

  //   console.log("outlettttttttttttttttttt", outletData);

  const ImageFileselectHandler = (event) => {
    setImgData(event.target.files[0]);
    var src = URL.createObjectURL(event.target.files[0]);
    setTestImg(src);
    // console.log("Imagggggeeee::", src);
    setAccDetails({ ...accDetails, accImage: event.target.files[0] });
  };

  const coverImageFileselectHandler = (event) => {
    setCoverImgData(event.target.files[0]);
    var coverSrc = URL.createObjectURL(event.target.files[0]);
    setCoverTestImg(coverSrc);
    // console.log("CoverImagggggeeee::", coverSrc);
    setAccDetails({ ...accDetails, coverImage: event.target.files[0] });
  };

  const companyFormValidate = (values) => {
    const errors = {};
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    if (!values.name) {
      errors.name = "Please enter name";
      setIsSubmit(false);
    } else if (!values.companyName) {
      errors.companyName = "Please enter company name";
      setIsSubmit(false);
    } else if (!values.isoCode) {
      errors.isoCode = "Please select country iso code";
      setIsSubmit(false);
    } else if (!values.mobileNumber) {
      errors.mobileNumber = "Please enter phone number";
      setIsSubmit(false);
    } else if (!values.currency) {
      errors.currency = "Please select country";
      setIsSubmit(false);
    } else {
      errors.name = "";
      errors.companyName = "";
      errors.isoCode = "";
      errors.mobileNumber = "";
      errors.currency = "";
      if (!isError) {
        setIsSubmit(true);
      }
    }
    return errors;
  };

  useEffect(() => {
    if (isSubmit) {
      callEditProfileDetailsAPI();
    }
  }, [isSubmit]);

  const callEditProfileDetailsAPI = async () => {
    if (isSubmit) {
      setIsLoader(true);
      const fData = new FormData();
      if (coverImgData) {
        fData.append("business_card_img", accDetails.coverImage);
      }
      if (imgData) {
        fData.append("company_logo_img", accDetails.accImage);
      }
      fData.append("name", accDetails.name);
      fData.append("company_name", accDetails.companyName);
      fData.append("country", accDetails.countryName);
      fData.append("country_code", accDetails.country_code);
      fData.append("currency", accDetails.currency);
      fData.append("calling_code", accDetails.calling_code);
      fData.append("calling_number", accDetails.isoCode);
      fData.append("phone_no", accDetails.mobileNumber);

      const res = await fetch(baseURL + `update-user/${outletData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: fData,
      });

      const data = await res.json();
      const { success } = data;

      if (success) {
        const userDetailsInLocal = getUserDetails();
        const updatedUserDetails = {
          ...userDetailsInLocal,
          companyName: accDetails?.companyName,
          currency: accDetails?.currency,
          name: accDetails?.name,
          phone: accDetails?.mobileNumber,
        };
        setAllUserDetails(updatedUserDetails)
        // console.log(data);
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
        setIsLoader(false);
        window.location.pathname = "/app/dashboard/settings";
      } else {
        setIsLoader(false);
      }
    }
    setIsSubmit(false);
  };

  const handleSocialbtn = () => {
    if (
      accDetails.componyWebsite === "null" ||
      accDetails.componyWebsite === null
    ) {
      setAccDetails({ ...accDetails, componyWebsite: "" });
    }
    if (accDetails.GSTIN === "null" || accDetails.GSTIN === null) {
      setAccDetails({ ...accDetails, GSTIN: "" });
    }
    callBussSocialDetailsAPI();
  };

  const callBussSocialDetailsAPI = async () => {
    setIsLoader2(true);
    if (accDetails.GSTIN === "null" || accDetails.GSTIN === null) {
      const res = await fetch(baseURL + `update-user/${outletData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `company_url=${
          accDetails.componyWebsite
        }&gstin=${""}&social_media_links=${accDetails.instagramURL},${
          accDetails.telegramURL
        }`,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log(data);
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
        setIsLoader2(false);
      } else {
        setIsLoader2(false);
      }
    } else if (
      accDetails.componyWebsite === "null" ||
      accDetails.componyWebsite === null
    ) {
      const res = await fetch(baseURL + `update-user/${outletData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `company_url=${""}&gstin=${accDetails.GSTIN}&social_media_links=${
          accDetails.instagramURL
        },${accDetails.telegramURL}`,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log(data);
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
        setIsLoader2(false);
      } else {
        setIsLoader2(false);
      }
    } else {
      const res = await fetch(baseURL + `update-user/${outletData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `company_url=${accDetails.componyWebsite}&gstin=${accDetails.GSTIN}&social_media_links=${accDetails.instagramURL},${accDetails.telegramURL}`,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        // console.log(data);
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
        setIsLoader2(false);
      } else {
        setIsLoader2(false);
      }
    }
  };

  const handleCompanyDetailFormSubmit = () => {
    setCompanyDetailsFormErrors(companyFormValidate(accDetails));
  };

  useEffect(() => {
    const callProfileDetailsAPI = async () => {
      const res = await fetch(baseURL + `get-profile/${outletData.id}`, {
        method: "GET",
        headers: {
          accept: "application/json",
          //   "Content-Type": "",
        },
      });
      const data = await res.json();
      const { success } = data;
      const userData = data.data.user[0];
      if (success) {
        // console.log("prooooofile Daaataaa", data);
        setAccDetails({
          name: userData.name,
          companyName: userData.company_name,
          isoCode: userData.calling_number,
          calling_code: userData.calling_code,
          mobileNumber: userData.phone_no,
          currency: userData.currency,
          country_code: userData.country_code,
          countryName: userData.country,
          email: userData.email,
          componyWebsite: userData.company_url,
          instagramURL: userData.social_media_links.split(/\s*,\s*/)[0],
          telegramURL: userData.social_media_links.split(/\s*,\s*/)[1],
          GSTIN: userData.gstin,
          accImage: userData.company_logo_img,
          coverImage: userData.business_card_img,
        });

        setTestImg(`${IMAGE_USER_URL}${userData.company_logo_img}`);
        if (userData.business_card_img) {
          setCoverTestImg(`${IMAGE_USER_URL}${userData.business_card_img}`);
        }
        nameRef.current.value = userData.name;
        companyNameRef.current.value = userData.company_name;
        const indexofIOSCode = mobCountry.findIndex(
          (c) => c.dialCode === userData.calling_number
        );
        isoCodeRef.current.value = indexofIOSCode;
        phoneNumberRef.current.value = userData.phone_no;
        const indexofCurrCountry = currCountry.findIndex(
          (c) => c.countryCode === userData.country_code
        );
        currencyRef.current.value = indexofCurrCountry;
        // if (userData.company_url === "null") {
        //   companyWebsiteRef.current.value = "";
        // } else {
        companyWebsiteRef.current.value = userData.company_url;
        // }
        // if (userData.gstin === "null") {
        //   GSTINRef.current.value = "";
        // } else {
        GSTINRef.current.value = userData.gstin;
        // }
        // console.log(userData.company_url);
        if (userData.social_media_links.split(/\s*,\s*/)[0]) {
          instagramURLRef.current.value =
            userData.social_media_links.split(/\s*,\s*/)[0];
        } else instagramURLRef.current.value = "";
        if (userData.social_media_links.split(/\s*,\s*/)[1]) {
          telegramURLRef.current.value =
            userData.social_media_links.split(/\s*,\s*/)[1];
        } else telegramURLRef.current.value = "";
      } else {
      }
    };

    callProfileDetailsAPI();
  }, []);

  // console.log(accDetails);

  const handleChangeMobCountry = (e) => {
    if (mobCountry[e.target.value] === undefined) {
      setAccDetails({ ...accDetails, isoCode: mobCountry[e.target.value] });
    } else {
      setAccDetails({
        ...accDetails,
        calling_code: mobCountry[e.target.value].isoCode,
        isoCode: mobCountry[e.target.value].dialCode,
      });
    }
  };

  const handleChangeCountry = (e) => {
    if (currCountry[e.target.value] === undefined) {
      setAccDetails({
        ...accDetails,
        country_code: currCountry[e.target.value],
      });
    } else {
      setAccDetails({
        ...accDetails,
        currency: currCountry[e.target.value].currencyCode,
        country_code: currCountry[e.target.value].countryCode,
        countryName: currCountry[e.target.value].countryName,
      });
    }
    // console.log(accDetails);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="dashboardSettingsProfile dashboardSettingsProfile-section flex">
      <div className="dashboardSettingsProfile-account">
        <div className="dashboardSettingsProfile-account-header flex flex-aic flex-jcsb">
          <p>Edit your bussiness account details</p>
          <button
            onClick={() => {
              handleCompanyDetailFormSubmit();
            }}
          >
            {isLoader ? (
              <lord-icon
                className="btn-loader inactive"
                src="https://cdn.lordicon.com/xjovhxra.json"
                trigger="loop"
                colors="primary:#ffffff,secondary:#242424"
                stroke="80"
                scale="75"
                style={{
                  width: "33.5px",
                  height: "15px",
                }}
              ></lord-icon>
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div className="dashboardSettingsProfile-account-images flex">
          <div className="dashboardSettingsProfile-account-profile">
            <p className="dashboardSettingsProfile-account-profile-header">
              Account Image
            </p>
            <div className="dashboardSettingsProfile-account-profile-imgbox">
              {testImg === null ? (
                <img
                  src={imgLogo}
                  style={{
                    objectFit: "contain",
                    width: "50%",
                    color: "gray",
                    display: "block",
                    margin: "auto",
                  }}
                ></img>
              ) : (
                <img src={testImg}></img>
              )}
              <div className="dashboardSettingsProfile-account-profile-imgbox-selectImg">
                <label for="file-upload" className="custom-file-upload">
                  {/* <i class="bx bx-plus"></i> */}
                  <i class="bx bxs-pencil"></i>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={ImageFileselectHandler}
                ></input>
              </div>
            </div>
          </div>
          <div className="dashboardSettingsProfile-account-bussinessProfile">
            <p className="dashboardSettingsProfile-account-bussinessProfile-header">
              Cover Image
            </p>
            <div className="dashboardSettingsProfile-account-bussinessProfile-imgbox">
              {coverTestImg == null ? (
                <img
                  src={imgLogo}
                  style={{
                    objectFit: "contain",
                    width: "50%",
                    color: "gray",
                    display: "block",
                    margin: "auto",
                  }}
                ></img>
              ) : (
                <img src={coverTestImg}></img>
              )}
              <div className="dashboardSettingsProfile-account-bussinessProfile-imgbox-selectImg">
                <label for="coverfile-upload" className="custom-file-upload">
                  <i class="bx bxs-pencil"></i>
                </label>
                <input
                  id="coverfile-upload"
                  type="file"
                  onChange={coverImageFileselectHandler}
                ></input>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboardSettingsProfile-account-details">
          <div className="dashboardSettingsProfile-account-details-name">
            <p>Name</p>
            <input
              type="text"
              placeholder="Full Name"
              ref={nameRef}
              onChange={(e) => {
                setAccDetails({ ...accDetails, name: e.target.value });
                setCompanyDetailsFormErrors({
                  ...companyDetailsFormErrors,
                  name: "",
                });
                // console.log(accDetails);
              }}
            ></input>
            <p className="dashboardSettingsProfile-account-details-errorMsg">
              {companyDetailsFormErrors.name}
            </p>
          </div>
          <div className="dashboardSettingsProfile-account-details-name">
            <p>Company Name</p>
            <input
              type="text"
              placeholder="Company Name"
              ref={companyNameRef}
              onChange={(e) => {
                setAccDetails({ ...accDetails, companyName: e.target.value });
                setCompanyDetailsFormErrors({
                  ...companyDetailsFormErrors,
                  companyName: "",
                });
              }}
            ></input>
            <p className="dashboardSettingsProfile-account-details-errorMsg">
              {companyDetailsFormErrors.companyName}
            </p>
          </div>
          <div
            className="dashboardSettingsProfile-account-details-name"
            style={{ position: "relative" }}
          >
            <p>Phone Number</p>
            <input
              type="text"
              placeholder="Number"
              ref={phoneNumberRef}
              style={{ paddingLeft: "110px" }}
              onChange={(e) => {
                setAccDetails({ ...accDetails, mobileNumber: e.target.value });
                setCompanyDetailsFormErrors({
                  ...companyDetailsFormErrors,
                  mobileNumber: "",
                });
              }}
            ></input>
            <p className="dashboardSettingsProfile-account-details-errorMsg">
              {companyDetailsFormErrors.mobileNumber}
              {companyDetailsFormErrors.isoCode}
            </p>
            <select
              ref={isoCodeRef}
              style={{
                // all: "unset",
                position: "absolute",
                top: "25px",
                left: "3px",
                margin: "0",
                width: "90px",
                height: "35px",
                appearance: "button",
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                outline: "none",
                cursor: "pointer",
                // fontSize: "12px",
              }}
              onChange={(e) => {
                handleChangeMobCountry(e);
                setCompanyDetailsFormErrors({
                  ...companyDetailsFormErrors,
                  isoCode: "",
                });
              }}
            >
              <option value="null">( +XX )</option>
              {mobCountry.map((c, index) => {
                return (
                  <option value={index}>
                    {/* {c.name} */}
                    {c.dialCode}
                  </option>
                );
              })}
              <option></option>
            </select>

            {/* <div className="dashboardSettingsProfile-account-details-name-phoneNumber">
              <select name="cars" id="cars">
                {countryISDData.map((country) => {
                  return (
                    <>
                      <option
                        value={country.name}
                        style={{ backgroundImage: `url(${imgLogo})` }}
                      >
                        <div>hello ji</div>
                        {country.name} {country.dialCode}
                      </option>
                    </>
                  );
                })}        
              </select>
            </div> */}
          </div>
          <div className="dashboardSettingsProfile-account-details-name">
            <p>Country & Currency</p>
            {/* <input type="text" placeholder="" ref={currencyRef}></input> */}
            <select
              ref={currencyRef}
              onChange={(e) => {
                handleChangeCountry(e);
                setCompanyDetailsFormErrors({
                  ...companyDetailsFormErrors,
                  currency: "",
                });
              }}
            >
              <option value="null">--Select Country--</option>
              {currCountry.map((c, index) => {
                return (
                  <option value={index}>
                    {c.countryName} {` (${c.currencyCode})`}
                  </option>
                );
              })}
            </select>
            <p className="dashboardSettingsProfile-account-details-errorMsg">
              {companyDetailsFormErrors.currency}
            </p>
          </div>
        </div>
      </div>
      <div style={{ flex: "1" }}>
        <div className="dashboardSettingsProfile-email">
          <div className="dashboardSettingsProfile-email-header">
            <p>Account Email</p>
          </div>
          <div className="dashboardSettingsProfile-email-userEmail flex flex-aic">
            {accDetails.email}
            <i class="bx bxs-lock"></i>
          </div>
        </div>
        <div className="dashboardSettingsProfile-social">
          <div className="dashboardSettingsProfile-social-header flex flex-jcsb flex-aic">
            <p className="">Edit your bussiness's social media account</p>
            <button
              onClick={() => {
                handleSocialbtn();
              }}
            >
              {isLoader2 ? (
                <lord-icon
                  className="btn-loader inactive"
                  src="https://cdn.lordicon.com/xjovhxra.json"
                  trigger="loop"
                  colors="primary:#ffffff,secondary:#242424"
                  stroke="80"
                  scale="75"
                  style={{
                    width: "33.5px",
                    height: "15px",
                  }}
                ></lord-icon>
              ) : (
                "Save"
              )}
            </button>
          </div>
          <div className="dashboardSettingsProfile-social-details">
            <div className="dashboardSettingsProfile-social-details-name">
              <p>Company Website</p>
              <input
                type="text"
                placeholder="https://www.catalogmaker.in"
                ref={companyWebsiteRef}
                onChange={(e) => {
                  setAccDetails({
                    ...accDetails,
                    componyWebsite: e.target.value,
                  });
                }}
              ></input>
            </div>
            <div className="dashboardSettingsProfile-social-details-name">
              <p>Instagram Profile URL</p>
              <input
                type="text"
                placeholder="Instagram Profile URL"
                ref={instagramURLRef}
                onChange={(e) => {
                  setAccDetails({
                    ...accDetails,
                    instagramURL: e.target.value,
                  });
                }}
              ></input>
            </div>
            <div className="dashboardSettingsProfile-social-details-name">
              <p>Telegram Channel URL</p>
              <input
                type="text"
                placeholder="Telegram Channel URL"
                ref={telegramURLRef}
                onChange={(e) => {
                  setAccDetails({ ...accDetails, telegramURL: e.target.value });
                }}
              ></input>
            </div>
            <div className="dashboardSettingsProfile-social-details-name">
              <p>GSTIN</p>
              <input
                type="text"
                placeholder="GSTIN"
                ref={GSTINRef}
                onChange={(e) => {
                  setAccDetails({ ...accDetails, GSTIN: e.target.value });
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
      {successMsg && (
        <motion.div
          initial={{
            // scale: 0,
            x: "100px",
            opacity: 0,
          }}
          animate={{
            // scale: 1,
            x: "0px",
            opacity: 1,
            transition: {
              duration: 0.4,
            },
          }}
          className="dashboardFeedback-successMsg"
        >
          Profile Updated Successfully !
        </motion.div>
      )}
    </section>
  );
};

export default DashboardSettingsProfile;
