import React, { useState } from "react";
import Header from "../header/Header";
import leginfo from "./legalInformationLinks.json";
import { Outlet, Link } from "react-router-dom";
import "./legalInformation.scss";

const LegalInformation = () => {
  const [activeLink, setActiveLink] = useState(-1);
  return (
    <>
      <Header />
      <section className="legalInformation legalInformation-section">
        <div className="container flex">
          <div className="legalInformation-section-sidebar">
            <div className="legalInformation-section-sidebar-category">
              <ul>
                {leginfo.map((categoryLink, index) => {
                  return (
                    <a
                      href={categoryLink.link}
                      //   to={categoryLink.link}
                      key={index}
                      onClick={() => setActiveLink(index)}
                      className={
                        index === activeLink ||
                        window.location.pathname === categoryLink.link
                          ? "active"
                          : ""
                      }
                    >
                      <li className="flex flex-aic">
                        {categoryLink.title}
                        <i className="bx bx-chevrons-right"></i>
                      </li>
                    </a>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="legalInformation-section-contentSection">
            <Outlet />
          </div>
        </div>
      </section>
    </>
  );
};

export default LegalInformation;
