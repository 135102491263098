import React from "react";
import "./MobileApp.scss";
import mock from "../../video/mock2.mp4";
import mockpng from "../../images/mobileapp/mobileMock.png";
import android from "../../images/mobileapp/playstore1.png";
import iOS from "../../images/mobileapp/appstore1.png";

const MobileApp = () => {
  return (
    <section className="mobileApp mobileApp-section">
      <div className="container flex">
        <div className="mobileApp-details flex flex-fdc flex-jcc">
          <p>iOS & ANDROID</p>
          <h1>Available now!</h1>
          <p>
            You can take your catalog with you and never
            <br /> miss the action with Rocket. Catalogmaker's <br />
            native apps for iOS and android.
          </p>
          <div className="mobileApp-details-links">
            <a href="https://play.google.com/store/apps/details?id=com.catalogmakerpdfs">
              <img src={android} alt="How do I create a PDF Catalogue?"></img>
            </a>
            <br />
            <a href="https://apps.apple.com/in/app/catalog-maker-brochure-maker/id1623605843">
              <img src={iOS} alt="How do I create a PDF Catalogue?"></img>
            </a>
          </div>
        </div>
        <div className="mobileApp-video">
          {/* <video autoPlay>
            <source src={mock} type="video/mp4"></source>
          </video> */}
          <img src={mockpng}></img>
        </div>
      </div>
    </section>
  );
};

export default MobileApp;
