import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDXnM6E_LrtjS3m2YKiKya39sG5ToX7q3w",
  authDomain: "catalogmaker-in.firebaseapp.com",
  projectId: "catalogmaker-in",
  storageBucket: "catalogmaker-in.appspot.com",
  messagingSenderId: "346872402028",
  appId: "1:346872402028:web:1e876188713fe05afbc849",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();
const providerFB = new FacebookAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, provider);

export const signInWithFacebbok = () => signInWithPopup(auth, providerFB);
