export const Temps = [
  {
    id: "1",
    title: "Clothing 2x2",
    fullTitle: "Easter Dinner Invitation Flyer Template",
    category: "clothing",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "clothing-2x2",
    keywords: "hemllo clos",
    free: true,
    allImage: [
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/fezbot2000-nahUo1GhcrA-unsplash%20(1)-f11784ff-0d64-4955-99cd-a5042df7dae6",
      },
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
      },
    ],
  },
  {
    id: "2",
    title: "jewellery-5x5",
    fullTitle: "Easter Dinner Invitation Flyer Template",
    category: "jewellery",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/fezbot2000-nahUo1GhcrA-unsplash%20(1)-f11784ff-0d64-4955-99cd-a5042df7dae6",
    link: "jewellery-2x2",
    keywords: "ankit",
    free: false,
    allImage: [
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/fezbot2000-nahUo1GhcrA-unsplash%20(1)-f11784ff-0d64-4955-99cd-a5042df7dae6",
      },
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
      },
    ],
  },
  {
    id: "3",
    title: "Product-4x4",
    fullTitle: "Easter Dinner Invitation Flyer Template",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/156846/root/three-glass-cocktail-serve-bar-table%20(1).jpg",
    link: "product-2x2",
    keywords: "hemllo clos",
    free: true,
    allImage: [
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/fezbot2000-nahUo1GhcrA-unsplash%20(1)-f11784ff-0d64-4955-99cd-a5042df7dae6",
      },
      {
        image:
          "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
      },
    ],
  },
  {
    id: "4",
    title: "Clothing 2x2",
    category: "clothing",
    image:
      "https://az800251.vo.msecnd.net/live/files/145936/root/upholstered-chair-wooden-bookcase%20(1).jpg",
    link: "templates/Clothing 2x2-2x2",
  },
  {
    id: "5",
    title: "jewellery-5x5",
    category: "jewellery",
    image:
      "https://az800251.vo.msecnd.net/live/files/156846/root/three-glass-cocktail-serve-bar-table%20(1).jpg",
    link: "templates/jewellery -2x2",
  },
  {
    id: "6",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "7",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "8",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "9",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "10",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "11",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "12",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "13",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "14",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
  {
    id: "15",
    title: "Product-4x4",
    category: "product",
    image:
      "https://az800251.vo.msecnd.net/live/files/142876/root/analise-benevides-KiREt1F_Zhk-unsplash%20(1)-559da3fb-768d-4063-8bad-23bc08360c7b",
    link: "templates/clothing-2x2",
  },
];
