import React from "react";
import "./Dashboard.scss";
import AdminSidebar from "./pages/AdminSidebar";
import {
  BrowserRouter as Router,
  Outlet,
  useOutletContext,
} from "react-router-dom";

const Dashboard = () => {
  const data = useOutletContext();
  return (
    <section className="dashboard dashboard-section flex">
      {/* sidebar */}
      <AdminSidebar />
      {/* Inner Container flows here */}
      {/* child */}
      <Outlet context={data} />
    </section>
  );
};

export default Dashboard;
