import React, { Component } from "react";
import { Route, Navigate, Outlet } from "react-router-dom";
import { decryptData } from "./Util";

const ProtectedRoute = () => {
  // decrypt localStorage data start
  let originalData;
  let auth;
  const salt = process.env.SALT || "6d090796-ecdf-11ea-adc1-0242ac112345";

  const auKey = localStorage.getItem("auKey");
  const auKeySession = sessionStorage.getItem("auKey");
  // serching in local
  if (!auKey) {
    // serching in session
    if (!auKeySession) window.location.pathname = "/admin/login";
    else {
      originalData = decryptData(auKeySession, salt);
      originalData["storage"] = "session";
      auth = originalData.auth;
    }
  } else {
    originalData = decryptData(auKey, salt);
    originalData["storage"] = "local";
    auth = originalData.auth;
  }

  if (!originalData && !auth) {
    window.location.pathname = "/admin/login";
  }
  // decrypt localStorage data start end

  // returning Outlet to Dashboard page if auth is true othervice redirecting to login page
  return auth === "true" ? (
    <Outlet context={originalData} />
  ) : (
    <Navigate to="/admin/login" replace />
  );
};

export default ProtectedRoute;
