import React from "react";
// import demo from "../../images/demo.png";
import demo from "../../images/dashboards/intro22.svg";

export default function OperationS2() {
  const handleUseforFreeLink = () => {
    const signupPopup = document.querySelector(".signup-modal");
    signupPopup.click();
  };
  return (
    <div className="oprt-s2" style={{ marginTop: "5rem" }}>
      <div className="operation-s1 flex flex-aic">
        <div className="operation-s1-right flex flex-jcfs">
          <div className="imgBox">
            <img
              src={demo}
              alt="What is the best program to create a catalog"
            ></img>
          </div>
        </div>
        <div className="operation-s2-left" style={{ marginLeft: "140px" }}>
          {/* <span ref={lottieNum1} className="lottieNum1"></span> */}
          <lord-icon
            src="https://cdn.lordicon.com/vcoyflbj.json"
            trigger="loop"
            colors="primary:#121331,secondary:#ff6347"
            stroke="80"
            scale="80"
            style={{ width: "60px", height: "60px", marginBottom: "3rem" }}
          ></lord-icon>
          <h2 style={{ maxWidth: "100%" }}>
            Add / import your product{" "}
            <span className="sketch-highlight-o2">information</span> and images.
          </h2>
          <p style={{ maxWidth: "100%" }}>
            Use Catalogmaker to create, edit or import your products and images.
            Quickly add them to your catalog template. Extend with custom
            fields/variants/options including images, prices, barcodes, market
            description, specifications, and more.
          </p>
          <a
            href="#"
            onClick={() => {
              handleUseforFreeLink();
            }}
          >
            Try For Free
          </a>
        </div>
      </div>
      <div className="operation-s1-dts flex" style={{ textAlign: "center" }}>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/meqqbzid.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#ff6347"
            stroke="80"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Upload Data with Ease</a>
          <p>
            Individual can upload data in one click. User can upload images and
            details in bulk within minutes.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/jaotthus.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#3a3347,secondary:#646e78,tertiary:#ff6347,quinary:#ffc738"
            stroke="80"
            scale="60"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Personal Storage</a>
          <p>
            Because of Personal Storage, Re-import or change your products in
            the database with automatic update in your catalogs.
          </p>
        </div>
        <div className="s1-dts-item">
          <lord-icon
            target="div.s1-dts-item"
            src="https://cdn.lordicon.com/doxxiooo.json"
            trigger="loop-on-hover"
            colors="outline:#121331,primary:#ff6347,secondary:#ebe6ef"
            scale="60"
            style={{ width: "80px", height: "80px" }}
          ></lord-icon>
          <a href="#">Faster Then Ever</a>
          <p>
            Create and get your PDF catalog, It's as easy as 1-2-3. select
            template, upload data, download catalog PDF and here you go.
          </p>
        </div>
      </div>
    </div>
  );
}
