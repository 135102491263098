import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import DashboardNavBar from "../navbar/DashboardNavBar";
import "./DashboardInnerContent.scss";
const DashboardInnerContent = () => {
  const userID = useOutletContext();
  // console.log("inerContext userid:", userID);
  return (
    <section className="dashboardInnerContent dashboardInnerContent-section">
      <DashboardNavBar userData={userID} />
      <Outlet context={userID} />
    </section>
  );
};

export default DashboardInnerContent;
