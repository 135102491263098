import { async } from "@firebase/util";
import React, { useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { baseURL } from "../../api/baseURL/BaseURL";
import { motion } from "framer-motion";
import { BsWhatsapp } from "react-icons/bs";
import "./DashboardFeedback.scss";

const DashboardFeedback = () => {
  const userData = useOutletContext();
  const [formData, setFormData] = useState(null);
  const [successMsg, setSuccessMsg] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const titleRef = useRef();
  const messageRef = useRef();
  const callFeedbackAPI = async () => {
    if (formData) {
      setIsLoader(true);
      const res = await fetch(baseURL + `contact-us/${userData.id}`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `title=${formData.title}&message=${formData.message}`,
      });
      const data = await res.json();
      const { success } = data;
      if (success) {
        titleRef.current.value = "";
        messageRef.current.value = "";
        setFormData(null);
        setSuccessMsg(true);
        setTimeout(() => {
          setSuccessMsg(false);
        }, 3000);
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    }
  };

  return (
    <section className="dashboardFeedback">
      <div className="dashboardFeedback-dfDiv">
        <p className="dashboardFeedback-dfDiv-header">Contact Us</p>
        <p className="dashboardFeedback-dfDiv-subHeader">
          If you have any problem or issue reach out us by filling this form.
        </p>
        <form>
          <input
            type="text"
            placeholder="Subject"
            onChange={(e) => {
              setFormData({ ...formData, title: e.target.value });
            }}
            ref={titleRef}
          ></input>
          <textarea
            type="text"
            placeholder="Message"
            rows="8"
            cols="50"
            onChange={(e) => {
              setFormData({ ...formData, message: e.target.value });
            }}
            ref={messageRef}
          ></textarea>
          <button
            onClick={(e) => {
              e.preventDefault();
              callFeedbackAPI();
            }}
          >
            {isLoader ? (
              <lord-icon
                className="btn-loader inactive"
                src="https://cdn.lordicon.com/xjovhxra.json"
                trigger="loop"
                colors="primary:#ffffff,secondary:#242424"
                stroke="80"
                scale="75"
                style={{
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  left: "40%",
                  top: "20%",
                }}
              ></lord-icon>
            ) : (
              "Send"
            )}
          </button>
        </form>
        <div className="dashboardFeedback-whatsapp">
          {/* <h1>Direct Chat with us on WhatsApp for quick resolution</h1> */}
          <button>
            <a
              href="https://wa.me/+917359355142"
              target="_blank"
              className="flex flex-aic flex-jcc"
            >
              <BsWhatsapp size={20} />
              Live Chat Support
            </a>
          </button>
        </div>
      </div>
      {successMsg && (
        <motion.div
          initial={{
            // scale: 0,
            x: "100px",
            opacity: 0,
          }}
          animate={{
            // scale: 1,
            x: "0px",
            opacity: 1,
            transition: {
              duration: 0.4,
            },
          }}
          className="dashboardFeedback-successMsg"
        >
          Message Sent Successfully !
        </motion.div>
      )}
    </section>
  );
};

export default DashboardFeedback;
