import React, { useEffect, useState } from "react";
import w4 from "./../../../images/prducts/w4.jpg";
import { useOutletContext } from "react-router-dom";
import { baseURL, IMAGE_PRODUCTS_URL } from "../../api/baseURL/BaseURL";
// import Lottie from "react-lottie";
import loader from "./../../../lottie/logo2.json";
import { motion } from "framer-motion";
import "./DashboardIndividualCatalogProducts.scss";
import DashboardIndividualProductEditModal from "./DashboardIndividualProductEditModal";
import DashboardAddProductModal from "./DashboardAddProductModal";
import PlanModal from "../planModal/PlanModal";
import { getUserDetails } from "../../utils/getUserDetails";
const DashboardIndividualCatalogProducts = ({
  productData,
  callPFSCAPI,
  catalogID,
  userID,
  trigger,
  //   callPAPI,
  //   id,
}) => {
  // console.log("datatatatat", productData);
  const [editProductModal, setEditProductModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [isPlanModal, setIsPlanModal] = useState(false);
  const [indiProductData, setIndiProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(
    Array(productData?.length + 1 || 0).fill(false)
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const item = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const callDeleteIndividualProductAPI = async (id, index) => {
    const updatedIsDeleting = [...isLoading];
    updatedIsDeleting[index] = true;
    setIsLoading(updatedIsDeleting);
    const res = await fetch(baseURL + `delete-product/${id}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        // "Content-Type": "multipart/form-data",
      },
    });
    const data = await res.json();
    const { success } = data;
    if (success) {
      trigger((c) => c + 1);
      callPFSCAPI(catalogID);
    }
    updatedIsDeleting[index] = false;
    setIsLoading(updatedIsDeleting);
  };

  const ModalHandler = (i) => {
    if (i === 0) setEditProductModal(true);
    if (i === 1) setDeleteProductModal(true);
  };

  const handleAddProduct = () => {
    const userDetails = getUserDetails();
    if (productData?.length >= 12) {
      // setIsPlanModal(true);
      // return;
      if (userDetails?.plan) {
        setAddProductModal(true);
        return;
      } else {
        setIsPlanModal(true);
      }
    } else {
      setAddProductModal(true);
    }
  };

  return (
    <>
      <section className="dashboardIndividualCatalogProducts dashboardIndividualCatalogProducts-section">
        <div className="dashboardIndividualCatalogProducts-allproducts flex">
          <div
            className="dashboardIndividualCatalogProducts-allproducts-item add"
            onClick={handleAddProduct}
          >
            <i class="bx bx-image-add"></i>
            <div className="text">Add Product</div>
          </div>
          {productData === null ? (
            <>
              {/* <div style={{ width: "100%" }} className="flex flex-aic flex-jss">
              <Lottie options={defaultOptions} height={100} width={100} />
            </div> */}
            </>
          ) : (
            productData.map((product, index) => {
              return (
                <motion.div
                  variants={item}
                  className={
                    product.in_stock === "1"
                      ? "dashboardIndividualCatalogProducts-allproducts-item  in"
                      : "dashboardIndividualCatalogProducts-allproducts-item  out"
                  }
                  key={index}
                >
                  <div className="dashboardIndividualCatalogProducts-allproducts-item-imgbox">
                    <img src={IMAGE_PRODUCTS_URL + `/${product.images}`}></img>
                  </div>
                  <div className="dashboardIndividualCatalogProducts-allproducts-item-details">
                    <div className="dashboardIndividualCatalogProducts-allproducts-item-details-title">
                      {product.name}
                    </div>
                    <div className="dashboardIndividualCatalogProducts-allproducts-item-details-price">
                      {product.amount} {userID.currency}
                    </div>
                  </div>
                  <div className="dashboardIndividualCatalogProducts-allproducts-item-btns flex">
                    <div
                      className="dashboardIndividualCatalogProducts-allproducts-item-btns-edit"
                      onClick={() => {
                        setIndiProductData(product);
                        setEditProductModal(true);
                      }}
                    >
                      Edit
                    </div>
                    <div
                      className={
                        isLoading[index]
                          ? "dashboardIndividualCatalogProducts-allproducts-item-btns-delete loading"
                          : "dashboardIndividualCatalogProducts-allproducts-item-btns-delete"
                      }
                      onClick={() => {
                        // setDeleteProductModal(true);
                        callDeleteIndividualProductAPI(product.id, index);
                      }}
                    >
                      {isLoading[index] ? (
                        <lord-icon
                          // className="btn-loader inactive"
                          src="https://cdn.lordicon.com/xjovhxra.json"
                          trigger="loop"
                          colors="primary:#ffffff,secondary:#242424"
                          stroke="80"
                          scale="75"
                          style={{
                            width: "17px",
                            height: "17px",
                          }}
                        ></lord-icon>
                      ) : (
                        "Delete"
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      product.in_stock === "1"
                        ? "stockBadge in"
                        : "stockBadge out"
                    }
                  >
                    {product.in_stock === "1" ? "Stock In" : "Stock Out"}
                  </div>
                </motion.div>
              );
            })
          )}
        </div>
      </section>
      {editProductModal && (
        <DashboardIndividualProductEditModal
          closeModal={setEditProductModal}
          indiProductData={indiProductData}
          callPFSCAPI={callPFSCAPI}
          catalogID={catalogID}
          trigger={trigger}
          //   callPAPI={callPAPI}
          //   id={productData.id}
        />
      )}
      {addProductModal && (
        <DashboardAddProductModal
          closeModal={setAddProductModal}
          indiProductData={indiProductData}
          callPFSCAPI={callPFSCAPI}
          catalogID={catalogID}
          userID={userID}
          trigger={trigger}
          //   callPAPI={callPAPI}
          //   id={productData.id}
        />
      )}
      {isPlanModal && <PlanModal setIsPlanModal={setIsPlanModal} />}
    </>
  );
};

export default DashboardIndividualCatalogProducts;
