import React from "react";
import "./Statistics.scss";
const Statistics = () => {
  const statisticsNum = [
    {
      num: "500+",
      title: "Companies Using Catalogmaker",
      cName: "statistics-user su",
    },
    {
      num: "548+",
      title: "Catalog Generated",
      cName: "statistics-catalog su",
    },
    {
      num: "4814+",
      title: "Products Added",
      cName: "statistics-products su",
    },
    {
      num: "15800+",
      title: "Times Catalog Shared",
      cName: "statistics-share su",
    },
  ];
  return (
    <div className="statistics flex flex-jcc flex-aic">
      {statisticsNum.map((item, index) => {
        return (
          <div className={item.cName} key={index}>
            <div className="statistics-num">{item.num}</div>
            <div className="statistics-title">{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Statistics;
